import { Descriptions, IConsolidatedDescriptions } from "./Descriptions";
import { Dimensions, IConsolidatedDimensions } from "./Dimensions";
import { DimensionSpecs, IConsolidatedDimensionSpecs } from "./DimensionSpecs";
import { Feature, IConsolidatedFeature } from "./Feature";
import { ItemClassification, IConsolidatedItemClassification } from "./ItemClassification";
import { ItemNames, IConsolidatedItemNames } from "./ItemNames";
import { ItemReportTax, IConsolidatedItemReportTax } from "./ItemReportTax";
import { PricingReportItemAvailability, IConsolidatedPricingReportItemAvailability } from "./PricingReportItemAvailability";
import { PricingReportItemCalculationSteps, IConsolidatedPricingReportItemCalculationSteps } from "./PricingReportItemCalculationSteps";
import { ReferenceCodes, IConsolidatedReferenceCodes } from "./ReferenceCodes";

export class PricingReportItemFactory {
    static create(pricingReportItem: IPricingReportItem): IConsolidatedPricingReportItem {
        return new PricingReportItem(pricingReportItem).serialize();
    }
}

export class PricingReportItem {
    protected _catalogVersionId: string;
    protected _clientItemInstanceIds?: Array<string>;
    protected _names?: ItemNames;
    protected _classification?: ItemClassification;
    protected _descriptions?: Descriptions;
    protected _dimensions?: Dimensions;
    protected _dimensionSpecs?: DimensionSpecs;
    protected _isMandatory?: boolean;
    protected _quantity: number;
    protected _quantityPerPack: number;
    protected _price?: number;
    protected _taxes?: Array<ItemReportTax>;
    protected _refCode?: string;
    protected _refCodes?: ReferenceCodes;
    protected _image?: string;
    protected _attributes?: Array<IAttribute>;
    protected _subReportItems?: Array<PricingReportItem>;
    protected _isValid: boolean;
    protected _calculationSteps?: Array<PricingReportItemCalculationSteps>;
    protected _note?: string;
    protected _features?: Array<Feature>;
    protected _availability?: PricingReportItemAvailability;

    constructor(pricingReportItem: IPricingReportItem) {
        this._catalogVersionId = pricingReportItem.catalogVersionId;
        this._clientItemInstanceIds = pricingReportItem.clientItemInstanceIds;
        this._names = pricingReportItem.names ? new ItemNames(pricingReportItem.names) : undefined;
        this._classification = pricingReportItem.classification ? new ItemClassification(pricingReportItem.classification) : undefined;
        this._descriptions = pricingReportItem.descriptions ? new Descriptions(pricingReportItem.descriptions) : undefined;
        this._dimensions = pricingReportItem.dimensions ? new Dimensions(pricingReportItem.dimensions) : undefined;
        this._dimensionSpecs = pricingReportItem.dimensionSpecs ? new DimensionSpecs(pricingReportItem.dimensionSpecs) : undefined;
        this._isMandatory = pricingReportItem.isMandatory;
        this._quantity = pricingReportItem.quantity;
        this._quantityPerPack = pricingReportItem.quantityPerPack;
        this._price = pricingReportItem.price;
        this._taxes = pricingReportItem.taxes?.map((tax: IItemReportTax) => new ItemReportTax(tax));
        this._refCode = pricingReportItem.refCode;
        this._refCodes = pricingReportItem.refCodes ? new ReferenceCodes(pricingReportItem.refCodes) : undefined;
        this._image = pricingReportItem.image;
        this._attributes = pricingReportItem.attributes;
        this._subReportItems = pricingReportItem.subReportItems ? pricingReportItem.subReportItems.map((subReportItem: IPricingReportItem) => new PricingReportItem(subReportItem)) : undefined;
        this._isValid = pricingReportItem.isValid;
        this._calculationSteps = pricingReportItem.calculationSteps?.map((calculationStep: IPricingReportItemCalculationSteps) => new PricingReportItemCalculationSteps(calculationStep));
        this._note = pricingReportItem.note;
        this._features = pricingReportItem.features?.map((feature: IFeature) => new Feature(feature));
        this._availability = pricingReportItem.availability ? new PricingReportItemAvailability(pricingReportItem.availability) : undefined;
    }

    serialize(): IConsolidatedPricingReportItem {
        const pricingReportItem: IConsolidatedPricingReportItem = {
            catalogVersionId: this._catalogVersionId,
            clientItemInstanceIds: this._clientItemInstanceIds,
            isValid: this._isValid,
            quantity: this._quantity,
            quantityPerPack: this._quantityPerPack
        };

        if (this._names !== undefined) {
            pricingReportItem.names = this._names.serialize();
        }

        if (this._classification !== undefined) {
            pricingReportItem.classification = this._classification.serialize();
        }

        if (this._descriptions !== undefined) {
            pricingReportItem.descriptions = this._descriptions.serialize();
        }

        if (this._dimensions !== undefined) {
            pricingReportItem.dimensions = this._dimensions.serialize();
        }

        if (this._dimensionSpecs !== undefined) {
            pricingReportItem.dimensionSpecs = this._dimensionSpecs.serialize();
        }

        if (this._isMandatory !== undefined) {
            pricingReportItem.isMandatory = this._isMandatory;
        }

        if (this._price !== undefined) {
            pricingReportItem.price = this._price;
        }

        if (this._taxes !== undefined) {
            pricingReportItem.taxes = this._taxes.map((tax: ItemReportTax) => tax.serialize());
        }

        if (this._refCode !== undefined) {
            pricingReportItem.refCode = this._refCode;
        }

        if (this._refCodes !== undefined) {
            pricingReportItem.refCodes = this._refCodes.serialize();
        }

        if (this._image !== undefined) {
            pricingReportItem.image = this._image;
        }

        if (this._attributes !== undefined) {
            pricingReportItem.attributes = this._attributes;
        }

        if (this._subReportItems !== undefined) {
            pricingReportItem.subReportItems = this._subReportItems.map((subReportItem: PricingReportItem) => subReportItem.serialize());
        }

        if (this._calculationSteps !== undefined) {
            pricingReportItem.calculationSteps = this._calculationSteps.map((calculationStep: PricingReportItemCalculationSteps) => calculationStep.serialize());
        }

        if (this._note !== undefined) {
            pricingReportItem.note = this._note;
        }

        if (this._features !== undefined) {
            pricingReportItem.features = this._features.map((feature: Feature) => feature.serialize());
        }

        if (this._availability !== undefined) {
            pricingReportItem.availability = this._availability.serialize();
        }

        return pricingReportItem;
    }
}

export interface IConsolidatedPricingReportItem {
    catalogVersionId: string;
    clientItemInstanceIds?: Array<string>;
    names?: IConsolidatedItemNames;
    classification?: IConsolidatedItemClassification;
    descriptions?: IConsolidatedDescriptions;
    dimensions?: IConsolidatedDimensions;
    dimensionSpecs?: IConsolidatedDimensionSpecs;
    isMandatory?: boolean;
    quantity: number;
    quantityPerPack: number;
    price?: number;
    taxes?: Array<IConsolidatedItemReportTax>;
    refCode?: string;
    refCodes?: IConsolidatedReferenceCodes;
    image?: string;
    attributes?: Array<IAttribute>;
    subReportItems?: Array<IConsolidatedPricingReportItem>;
    isValid: boolean;
    calculationSteps?: Array<IConsolidatedPricingReportItemCalculationSteps>;
    note?: string;
    features?: Array<IConsolidatedFeature>;
    availability?: IConsolidatedPricingReportItemAvailability;
}