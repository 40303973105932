import { ItemVariant, IConsolidatedItemVariant } from "./ItemVariant";

export class ItemUpdateReportEntry {
    protected _instanceId: string;
    protected _updateType: ItemUpdateType;
    protected _itemVariants?: Array<ItemVariant>;    
    
    constructor(itemUpdateReportEntry: IItemUpdateReportEntry) {
        this._instanceId = itemUpdateReportEntry.instanceId;
        this._updateType = itemUpdateReportEntry.updateType;
        this._itemVariants = itemUpdateReportEntry.itemVariants?.map((itemVariant: IItemVariant) => new ItemVariant(itemVariant));
    }
    
    serialize(): IConsolidatedItemUpdateReportEntry {
        const itemUpdateReportEntry: IConsolidatedItemUpdateReportEntry = {
            instanceId: this._instanceId,
            updateType: this._updateType
        };
        
        if (this._itemVariants !== undefined) {
            itemUpdateReportEntry.itemVariants = this._itemVariants.map((itemVariant: ItemVariant) => itemVariant.serialize());
        }
        
        return itemUpdateReportEntry;
    }
}

export interface IConsolidatedItemUpdateReportEntry {
    instanceId: string;
    updateType: ItemUpdateType;
    itemVariants?: Array<IConsolidatedItemVariant>;
}