import { IConsolidatedFeatureState, IConsolidatedConfigurationState } from "@cic/ui-toolkit/src/models";
import DesignStateSource from "./DesignStateSource";
import JSONStringifier from "./JSONStringifier";

export default class DesignStateInfo extends JSONStringifier<IDesignMetaData> implements IDesignMetaData {
    private readonly _formatVersion: string;
    private _name: string | undefined;    
    private _source: DesignStateSource | undefined;
    private _coordinateConventions?: ICoordinateConventions;
    private _globalConfigurationState?: IConsolidatedConfigurationState;    
    
    constructor() {
        super();
        this._formatVersion = "0.3.0";
    }

    get name(): string | undefined {
        return this._name;
    }
    
    get formatVersion(): string | undefined {
        return this._formatVersion;
    }

    get source(): DesignStateSource | undefined {
        return this._source;
    }

    get coordinateConventions(): ICoordinateConventions | undefined {
        return this._coordinateConventions;
    }

    get globalConfigurationState(): IConsolidatedConfigurationState | undefined {
        return this._globalConfigurationState;
    }

    load(designMetaData: IDesignMetaData): void {
        this._name = designMetaData?.name;
        this._globalConfigurationState = designMetaData.globalConfigurationState;
        this._coordinateConventions = {
            baseMeasurementUnit: designMetaData.coordinateConventions?.baseMeasurementUnit || "mm",
            axisOrientation: "rightHanded",
            axisElevation: "zAxisUp"
        };

        if (designMetaData.source) {
            this._source = new DesignStateSource();
            this._source.load(designMetaData.source);
        }
    }

    setGlobalConfigurationState(configurationState?: IConsolidatedConfigurationState): void {
        this._globalConfigurationState = configurationState;
    }

    addToGlobalConfigurationState(featureId: string, optionId: string, value?: number): void {
        this._globalConfigurationState = this._globalConfigurationState ?? [];
        
        const featureState: IConsolidatedFeatureState | undefined = this._globalConfigurationState.find((featureState: IConsolidatedFeatureState) => featureState.featureId === featureId);
        if (featureState) {
            featureState.optionId = optionId;
        } else {
            this._globalConfigurationState.push({
                featureId,
                optionId,
                value
            });
        }        
    }

    removeFromGlobalConfigurationState(featureId: string): void {
        let index: number | undefined = this._globalConfigurationState?.findIndex((featureState: IConsolidatedFeatureState) => featureState.featureId === featureId);
        if (index !== undefined && index >= 0) {
            this._globalConfigurationState!.splice(index, 1);
        }
    }
}