import React, { useState, useEffect } from 'react';
import Joyride, { LIFECYCLE, ACTIONS, Step } from 'react-joyride';
import { ContextualHelpSteps } from './ContextualHelpSteps';
import { Tooltip } from 'react-tooltip';
import { strings } from 'strings'
import './contextualHelp.scss';

type ContextualHelpProps = {    
    ctxlHelpIndex?: number,
    ctxlHelpStepIndex: number | undefined,
    tooltipId: string,
    hideContextualHelp(): void,
    handleContextualHelpToggled(isSelected: boolean): void;
}

export function ContextualHelp(props: ContextualHelpProps) {    
    const { ctxlHelpStepIndex, ctxlHelpIndex, tooltipId, hideContextualHelp, handleContextualHelpToggled } = props;
    const [run, setRun] = useState(true);
    const step = getContextualHelpStep(ctxlHelpStepIndex, ctxlHelpIndex);

    const handleCallback = (data: any) => {
        const { step, action, lifecycle } = data;

        if ([ACTIONS.CLOSE].includes(action)) {
            const updatedTarget = ctxlHelpIndex ? step.target.replace(/_\d+$/, "") : step.target;
            const localStorageSteps = JSON.parse(localStorage.getItem('contextualHelpSteps') || '[]');

            const updatedSteps = localStorageSteps.map((element: { id: string, shownOverlay: boolean }) => {
                if (element.id === updatedTarget) {
                    element.shownOverlay = true;
                }
                return element;
            });

            localStorage.setItem('contextualHelpSteps', JSON.stringify(updatedSteps));
            hideContextualHelp();
        }

        if ([ACTIONS.NEXT].includes(action) && [LIFECYCLE.COMPLETE].includes(lifecycle)) {
            handleContextualHelpToggled(false);
        }
    };

    useEffect(() => {
        const localStorageSteps = JSON.parse(localStorage.getItem('contextualHelpSteps') || '[]');

        if (localStorageSteps.length === 0) {
            const extractedCtxlHelpSteps = ContextualHelpSteps.map((step) => ({
                id: step.target,
                shownOverlay: false,
            }));

            localStorage.setItem('contextualHelpSteps', JSON.stringify(extractedCtxlHelpSteps));
        } else if (typeof ctxlHelpStepIndex !== 'undefined') {
            const targetId = ContextualHelpSteps[ctxlHelpStepIndex]?.target;
            const ctxlHelpStep = localStorageSteps.find((element: { id: string, shownOverlay: boolean }) => element.id === targetId);

            if (!ctxlHelpStep) {
                //seems this is a new Step not yet define in storage. Add it to storage
                localStorageSteps.push({id: targetId, shownOverlay: false});
                localStorage.setItem('contextualHelpSteps', JSON.stringify(localStorageSteps));
            }
            
            setRun(ctxlHelpStep ? !ctxlHelpStep.shownOverlay : true);
        }
    }, [ctxlHelpStepIndex]);

    return (
        <>
        {run ?
            <Joyride
                callback={handleCallback}
                run={run}
                steps={step}
                disableOverlay={true}
                disableScrolling={true}
                showSkipButton={false}
                disableScrollParentFix={true}
                stepIndex={ctxlHelpIndex ?? ctxlHelpStepIndex}
                spotlightPadding={0}
                tooltipOffset={0}
                hideBackButton={true}
                continuous={true}
                locale={{ last: '', close: strings['contextualHelp.close'] }}
                floaterProps={{
                    styles: {
                        floater: {
                            filter: "drop-shadow(-2px 2px 8px rgba(0, 0, 0, 0.08))"
                        }
                    }
                }}
                styles={{
                    options: {
                        width: 320,
                        // Enable when Cloud Browser and Cloud Configurator match the Cycnly theme
                        backgroundColor: '#CDE3F9',
                        arrowColor: '#CDE3F9'
                    },
                    tooltipContainer: {
                        textAlign: "unset"
                    },
                    tooltipContent: {
                        padding: "8px 0 0 0",
                        fontSize: 13,
                        fontWeight: 400,
                        lineHeight: "20px"
                    },
                    tooltip: {
                        padding: 16,
                        borderRadius: 16
                    },
                    buttonClose: {
                        color: '#1A1A1A'
                    },
                    buttonNext: {
                        backgroundColor: 'transparent',
                        color: '#1A6CFF',
                        borderRadius: 0,
                        padding: 0,
                        fontSize: 12,
                        outline: 'none'
                    }
                }}
            />    
        :
            <Tooltip className='tooltip-width' id={tooltipId} hidden={false} />
        }
        
        </>
        
    )
}

function getContextualHelpStep(stepIndex?: number, index?: number) {
    if (typeof stepIndex === 'undefined' || stepIndex < 0) {
        return [];
    }

    let copiedStep: Array<Step> = [];

    if (typeof index !== 'undefined' && index >= 0) {
        copiedStep[index] = {
            ...ContextualHelpSteps[stepIndex],
            target: `${ContextualHelpSteps[stepIndex].target}_${index}`
        };
    } else {
        copiedStep[stepIndex] = { ...ContextualHelpSteps[stepIndex] };
    }

    return copiedStep;
}

