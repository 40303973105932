import React from 'react'
import { IIconProps } from 'Interfaces'

export function TapIcon(props: IIconProps) {
    const width: number = props.width || 16;

    return (
        width < 20 
        ? 
            <svg width={props.width ?? 16} height={props.height ?? 16}  viewBox="0 0 16 16" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.88456 4.92308H10.9615C12.8076 4.92308 14.0384 6.15385 14.0384 8V11.0769H10.3461V8.61539H2.96148V9.84615H1.73071V3.69231H2.96148V4.92308H6.65379V1.84615H5.23841C5.20764 1.87692 5.17687 1.92308 5.1461 1.96923C5.11533 2.01538 5.08456 2.06154 5.05379 2.09231C4.80764 2.33846 4.49994 2.46154 4.19225 2.46154C3.88456 2.46154 3.57687 2.33846 3.33071 2.09231C3.08456 1.84615 2.96148 1.53846 2.96148 1.23077C2.96148 0.923077 3.08456 0.615385 3.33071 0.369231C3.57687 0.123077 3.88456 0 4.19225 0C4.49994 0 4.80764 0.123077 5.05379 0.369231C5.08456 0.4 5.11533 0.446154 5.1461 0.492308C5.17687 0.538462 5.20764 0.584615 5.23841 0.615385H6.65379V0H7.88456V0.615385H9.29994C9.33071 0.584615 9.36148 0.538462 9.39225 0.492308C9.42302 0.446154 9.45379 0.4 9.48456 0.369231C9.73071 0.123077 10.0384 0 10.3461 0C10.6538 0 10.9615 0.123077 11.2076 0.369231C11.4538 0.615385 11.5769 0.923077 11.5769 1.23077C11.5769 1.53846 11.4538 1.84615 11.2076 2.09231C10.9615 2.33846 10.6538 2.46154 10.3461 2.46154C10.0384 2.46154 9.73071 2.33846 9.48456 2.09231C9.45379 2.06154 9.42302 2.01538 9.39225 1.96923C9.36148 1.92308 9.33071 1.87692 9.29994 1.84615H7.88456V4.92308ZM11.5769 9.84615H12.8076V8C12.8076 6.83077 12.1307 6.15385 10.9615 6.15385H2.96148V7.38462H10.3461C10.9615 7.38462 11.5769 8 11.5769 8.61539V9.84615Z" fill="currentColor"/>
            <path d="M12.1923 12.3077C12.1923 12.3077 13.423 14.0923 13.423 14.7692C13.423 15.4462 12.8692 16 12.1923 16C11.5153 16 10.9615 15.4462 10.9615 14.7692C10.9615 14.0923 12.1923 12.3077 12.1923 12.3077Z" fill="#388EE8"/>
            </svg>
        :
            <svg width={props.width ?? 25} height={props.height ?? 24} viewBox="0 0 25 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.98 7.68002H15.38C19.94 7.68002 22.58 10.32 22.58 14.928V17.328H17.78V14.448C17.78 13.296 17.012 12.528 15.86 12.528H4.33996V16.368H1.45996V4.84802H4.33996V7.72802H10.148V4.84802C9.81196 4.84802 9.52396 5.04002 9.33196 5.18402C8.85196 5.56802 8.32396 5.76002 7.74796 5.76002C7.02796 5.76002 6.35596 5.42402 5.87596 4.84802C5.39596 4.27202 5.20396 3.50402 5.39596 2.78402C5.58796 2.01602 6.25996 1.29602 7.12396 1.05602C7.89196 0.864023 8.70796 1.05602 9.23596 1.53602C9.52396 1.77602 9.85996 1.92002 10.244 1.92002H12.836C13.172 1.92002 13.556 1.77602 13.844 1.48802C14.42 1.00802 15.188 0.816023 15.956 1.00802C16.82 1.20002 17.54 1.92002 17.684 2.73602C17.828 3.45602 17.684 4.22402 17.204 4.80002C16.724 5.37602 16.052 5.71202 15.332 5.71202C14.804 5.71202 14.228 5.52002 13.796 5.13602C13.652 4.99202 13.364 4.84802 12.98 4.80002V7.68002ZM6.59596 4.22402C7.07596 4.84802 8.03596 4.94402 8.65996 4.41602C9.04396 4.08002 9.57196 3.88802 10.148 3.84002H11.108H12.068H13.028C13.508 3.84002 14.036 4.03202 14.468 4.41602C14.66 4.60802 14.948 4.80002 15.38 4.80002C15.812 4.80002 16.244 4.60802 16.484 4.17602C16.772 3.84002 16.868 3.40802 16.772 2.92802C16.676 2.44802 16.244 2.01602 15.764 1.92002C15.284 1.82402 14.852 1.92002 14.516 2.20802C14.036 2.64002 13.412 2.88002 12.836 2.88002H10.244C9.61996 2.88002 9.04396 2.68802 8.56396 2.25602C8.27596 1.96802 7.79596 1.87202 7.31596 1.96802C6.83596 2.11202 6.40396 2.49602 6.30796 2.97602C6.21196 3.40802 6.30796 3.88802 6.59596 4.22402ZM12.02 4.80002H11.06V7.68002H12.02V4.80002ZM2.41996 15.36H3.37996V12.48V7.68002V5.76002H2.41996V15.36ZM18.74 16.32H21.62V14.88C21.62 10.8 19.46 8.64002 15.38 8.64002H4.33996V11.52H15.86C17.54 11.52 18.74 12.72 18.74 14.4V16.32Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M20.18 17.808L19.796 18.48C19.556 18.864 18.26 20.976 18.26 21.936C18.26 23.088 19.124 24 20.18 24C21.236 24 22.1 23.088 22.1 21.936C22.1 20.976 20.852 18.912 20.564 18.48L20.18 17.808ZM19.22 21.936C19.22 22.56 19.652 23.04 20.18 23.04C20.708 23.04 21.14 22.56 21.14 21.936C21.14 21.552 20.66 20.544 20.18 19.68C19.7 20.544 19.22 21.552 19.22 21.936Z" fill="#388EE8"/>
            <path d="M3.37996 15.36H2.41996V5.76002H3.37996V7.68002V12.48V15.36Z" fill="#D7CDF9"/>
            <path d="M8.65996 4.41602C8.03596 4.94402 7.07596 4.84802 6.59596 4.22402C6.30796 3.88802 6.21196 3.40802 6.30796 2.97602C6.40396 2.49602 6.83596 2.11202 7.31596 1.96802C7.79596 1.87202 8.27596 1.96802 8.56396 2.25602C9.04396 2.68802 9.61996 2.88002 10.244 2.88002H12.836C13.412 2.88002 14.036 2.64002 14.516 2.20802C14.852 1.92002 15.284 1.82402 15.764 1.92002C16.244 2.01602 16.676 2.44802 16.772 2.92802C16.868 3.40802 16.772 3.84002 16.484 4.17602C16.244 4.60802 15.812 4.80002 15.38 4.80002C14.948 4.80002 14.66 4.60802 14.468 4.41602C14.036 4.03202 13.508 3.84002 13.028 3.84002H12.068H11.108H10.148C9.57196 3.88802 9.04396 4.08002 8.65996 4.41602Z" fill="#D7CDF9"/>
            </svg>

    )
}