import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import './themeProvider.scss';

type ThemeProviderProps = PropsWithChildren<{
    className?: string;
    theme?: string;
}>;

export function ThemeProvider(props: ThemeProviderProps) {
    const { children, theme, className } = props;
    const cn = classNames(theme || 'cyncly-theme', className);

    return (
        <div className={cn}>
            {children}             
        </div>
    );
}
