
export enum EVENT {
    DESIGN_STATE_LOADED = "designStateLoaded",
    ITEM_ADDED = "itemAdded",
    ITEM_REMOVED = "itemRemoved",    
    ITEMS_REMOVED = "itemsRemoved",
    ITEMS_UPDATED = "itemsUpdated",
    SELECTION_CHANGED = "selectionChanged",
    SELECTION_CLEARED = "selectionCleared"
}


//=========================================================================
class EventBus {
    #_notificationCallbacks: Set<Function>;

    //=========================================================================
    constructor() {
        this.#_notificationCallbacks = new Set();
    }

    //=========================================================================
    registerToNotifications(func: Function) {
        this.#_notificationCallbacks.add(func);
    }

    //=========================================================================
    unregisterFromNotifications(func: Function) {
        this.#_notificationCallbacks.delete(func);
    }

    //=========================================================================
    protected _notify(eventName: EVENT, data: any) {
        this.#_notificationCallbacks.forEach((callback: Function) => {
            callback(eventName, data);
        })
    }

}

export default EventBus;