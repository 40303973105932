import { EntityType } from "../..";
import { AuthoringStateMgr, State } from "./AuthoringStateMgr";

export class RestrictionsMgr {
    private authoringMgr: AuthoringStateMgr;
    private state: State;

    constructor(authoringMgr: AuthoringStateMgr) {
        this.authoringMgr = authoringMgr;
        this.state = this.authoringMgr.state;
    }

    public async copyRestriction(sourceCatalogId: string, code: string) {
        if (
            !(await this.authoringMgr.entities.exists(
                EntityType.RESTRICTIONS,
                code
            ))
        ) {
            const { entity } = await this.authoringMgr.entities.fetchEntity(
                EntityType.RESTRICTIONS,
                code,
                sourceCatalogId,
                undefined,
                true
            );

            const original = entity as ICatalogRestrictionDef;
            if (original) {
                this.authoringMgr.entities.createEntity(
                    EntityType.RESTRICTIONS,
                    code,
                    String(this.state.currentCatalogVersion!.id),
                    {
                        ...original,
                        code,
                    },
                    true
                );
            }
        }
    }
}
