import { authoringHubState } from "../authoringHubState";
import { PendingChange } from "../authoringStateMgr/PendingChangesMgr";
import {
    EntityNotFoundException,
    GroupTypes,
    InvalidException,
    StateEntity,
} from "./authoringGroupsState.type";
import { AuthoringGroupsStateBase } from "./authoringGroupsStateBase";

export class AuthoringGroupsState extends AuthoringGroupsStateBase {
    public isLoaded = (id: string, groupType: string) => {
        return (
            id in this._stateManagement._states._original &&
            groupType in this._stateManagement._states._original[id]
        );
    };

    public load = (id: string, groupType: string, groups: any[]) => {
        this._stateManagement.load(id, groupType, groups);
    };

    public getCurrentGroups = (id: string, groupType: string) => {
        if (
            id in this._stateManagement._states._current &&
            groupType in this._stateManagement._states._current[id]
        ) {
            return this._stateManagement.getCurrentEntities(id, groupType);
        } else {
            return [];
        }
    };

    public getCurrentGroup = (id: string, groupType: string, code: string) => {
        if (
            id in this._stateManagement._states._current &&
            groupType in this._stateManagement._states._current[id]
        ) {
            return this._stateManagement.getCurrentEntity(id, groupType, code);
        } else {
            return undefined;
        }
    };

    public getCurrentGroupStructure = (
        id: string,
        groupType: string,
        code: string
    ) => {
        if (
            id in this._stateManagement._states._current &&
            groupType in this._stateManagement._states._current[id]
        ) {
            return this._stateManagement.getCurrentGroupStructure(
                id,
                groupType,
                code
            );
        } else {
            return [];
        }
    };

    public getOriginalGroup = (id: string, groupType: string, code: string) => {
        if (
            id in this._stateManagement._states._original &&
            groupType in this._stateManagement._states._original[id]
        ) {
            return this._stateManagement.getOriginalEntity(id, groupType, code);
        } else {
            return undefined;
        }
    };

    public canCreateNew = (id: string, groupType: string, code: string) => {
        this._validate(id, groupType);

        for (const group in this._stateManagement._states._deleted) {
            if (
                this._stateManagement._states._deleted[id][groupType][group]
                    .entity.code === code
            ) {
                return false;
            }
        }

        return true;
    };

    public new = (
        id: string,
        groupType: string,
        entity: any,
        parentCode?: string
    ) => {
        this._validate(id, groupType);

        const code = this._validateCode(id, groupType, entity.code);
        entity.code = code;

        if (!code) {
            throw new InvalidException("Entity code should not be empty");
        }

        if (parentCode) {
            const parent = this._stateManagement.getCurrentGroup(
                id,
                groupType,
                parentCode
            );
            if (parent) {
                this._stateManagement.newSubgroup(
                    id,
                    groupType,
                    entity,
                    parent
                );
            } else {
                throw new EntityNotFoundException("Parent not found");
            }
        } else {
            this._stateManagement.new(id, groupType, entity);
        }

        return entity;
    };

    public move = (
        id: string,
        groupType: string,
        sourceCode: string,
        targetCode: string
    ) => {
        this._validate(id, groupType);

        const source = this._stateManagement.getCurrentGroup(
            id,
            groupType,
            sourceCode
        );
        if (!source) {
            throw new EntityNotFoundException("Source not found");
        }
        const target = this._stateManagement.getCurrentGroup(
            id,
            groupType,
            targetCode
        );
        if (!target) {
            throw new EntityNotFoundException("Target not found");
        }
        if (sourceCode === targetCode) {
            throw new InvalidException("Invalid target");
        }
        const isChild = this._stateManagement.isChild(targetCode, source);
        if (isChild) {
            throw new InvalidException("Target cannot be a child from source");
        }

        this._stateManagement.move(id, groupType, source, target);
    };

    public delete = (
        id: string,
        groupType: string,
        code: string,
        targetCode?: string
    ) => {
        this._validate(id, groupType);

        const group = this._stateManagement.getCurrentGroup(
            id,
            groupType,
            code
        );
        if (!group) {
            throw new EntityNotFoundException("Group not found");
        }

        let target: StateEntity | undefined;
        if (targetCode) {
            if (group.entity.code === targetCode) {
                throw new InvalidException("Invalid target");
            }
            target = this._stateManagement.getCurrentGroup(
                id,
                groupType,
                targetCode
            );
            if (!target) {
                throw new EntityNotFoundException("Target not found");
            }
            const isChild = this._stateManagement.isChild(targetCode, group);
            if (isChild) {
                throw new InvalidException(
                    "Target cannot be a child in the deletion tree"
                );
            }
        }

        let structure = this._stateManagement.getCurrentGroupStructure(
            id,
            groupType,
            code
        );
        structure = structure?.sort((a, b) => (a.level > b.level ? -1 : 1));

        structure?.forEach((e) => {
            if (e.isNew) {
                this._stateManagement.delete(id, groupType, e, target);
            } else {
                if (e.entity.code !== code) {
                    if (e.parent && e.parent.isNew) {
                        this._stateManagement.delete(id, groupType, e, target);
                    }
                } else {
                    this._stateManagement.delete(id, groupType, e, target);
                }
            }
        });
    };

    public getGroupTypes = () => {
        return this._getGroupTypes();
    };

    public getChanges = (id: string, groupType: string) => {
        if (!this.isLoaded(id, groupType)) {
            return {
                deleted: [],
                moved: [],
                new: [],
            };
        } else {
            return this._stateManagement.getChanges(id, groupType);
        }
    };

    public getGroupPendingChanges = (
        id: string,
        groupType: string,
        code: string
    ) => {
        const changes = this._getPendingChanges(id, groupType, code);
        return changes.length ? changes[0] : undefined;
    };

    public mergePendingChanges = (
        entityChanges: PendingChange[],
        id: string,
        groupType: string
    ) => {
        const find = (a: PendingChange, b: PendingChange) => {
            const acode = a.removed ? a.original?.code : a.current?.code;
            const bcode = b.removed ? b.original?.code : b.current?.code;
            return a.type === b.type && acode === bcode;
        };

        const merge = (changes: PendingChange[]) => {
            let toBeRemoved: string[] = [];

            //target code
            entityChanges.forEach((e, i) => {
                if (e.type === groupType) {
                    const change = changes.find((c) => find(c, e));
                    if (change) {
                        e.targetCode = change.targetCode;
                    } else if (e.new) {
                        toBeRemoved.push(e.current?.code!);
                    }
                }
            });

            //remove nested new groups
            entityChanges = entityChanges.filter((e) => {
                return e.type !== groupType ||
                       !toBeRemoved.some((r) => r === e.current?.code)
            });

            //add additional changes
            changes.forEach((c) => {
                if (!entityChanges.some((e) => find(c, e))) {
                    entityChanges.push(c);
                }
            });

            //remove ghosts (group that was create + deleted)
            return entityChanges.filter((e) => {
                if (e.type === groupType) {
                    return e.removed ? changes.some((c) => find(c, e)) : true;
                } else {
                    return true;
                }
            });
        };

        let pendingChanges: PendingChange[] = [];
        GroupTypes.forEach((e) => {
            if (this.isLoaded(id, e)) {
                const changes = this._getPendingChanges(id, e);
                pendingChanges = [...pendingChanges, ...changes];
            }
        });

        return merge(pendingChanges);
    };

    public revertChange = (id: string, groupType: string, code: string) => {
        this._stateManagement.revert(id, groupType, code);
    };

    public hasDirectChanges = (id: string, groupType: string, code: string) => {
        const group = this._stateManagement.getCurrentGroup(
            id,
            groupType,
            code
        );
        if (group) {
            return this._stateManagement.hasDirectChanges(id, groupType, group);
        } else {
            return false;
        }
    };

    public isChild = (
        id: string,
        groupType: string,
        source: string,
        target: string
    ) => {
        const sourceGroup = this._stateManagement.getCurrentGroup(
            id,
            groupType,
            source
        );
        const targetGroup = this._stateManagement.getCurrentGroup(
            id,
            groupType,
            target
        );
        if (sourceGroup && targetGroup) {
            return this._stateManagement.isChild(source, targetGroup);
        } else {
            return false;
        }
    };

    public applyNewChange = (id: string, groupType: string, code: string) => {
        const group = this._stateManagement.getCurrentGroup(
            id,
            groupType,
            code
        );
        if (group && group.isNew) {
            this._stateManagement.applyNewChange(id, groupType, group);
        }
    };

    public applyDeleteChange = (
        id: string,
        groupType: string,
        code: string
    ) => {
        const deletedGroup = this._stateManagement.getCurrentDeletedGroup(
            id,
            groupType,
            code
        );
        if (deletedGroup) {
            this._stateManagement.applyDeleteChange(
                id,
                groupType,
                deletedGroup
            );
        }
    };

    private _validateCode = (
        catalogVersionId: string,
        groupType: string,
        code: string = ""
    ) => {
        let validCode = code;
        let count = 2;
        if (validCode) {
            while (
                this._stateManagement.getCurrentGroup(
                    catalogVersionId,
                    groupType,
                    validCode
                )
            ) {
                validCode = code + "_" + count;
                count++;
            }
        }

        return validCode;
    };
}

export const authoringGroupsState = authoringHubState.registerModule(
    "authoringGroups",
    new AuthoringGroupsState()
) as AuthoringGroupsState;
