import { authoringStateMgr } from "..";
import { StateEntity } from "./authoringGroupsState.type";

export class GroupEntity {
    private _id: string;

    public code: string;
    public entity: any;

    public groups?: GroupEntity[];

    public constructor(id: string, state: StateEntity) {
        this._id = id;
        this.code = state.entity.code;
        this.entity = JSON.parse(JSON.stringify(state.entity));
        if (state.subgroups) {
            this.groups = [];
            for (const subgroup in state.subgroups) {
                this.groups.push(
                    new GroupEntity(id, state.subgroups[subgroup])
                );
            }
        }
    }

    private removeGroups(entity: any) {
        if (entity.groups) {
            delete this.entity.groups;
        }
        return entity;
    }

    public getGroupState(ignoreState?: boolean) {
        if (
            !ignoreState &&
            this._id in authoringStateMgr.state.entities &&
            this.code in
                authoringStateMgr.state.entities[this._id].itemgroups.current
        ) {
            return this.removeGroups(
                authoringStateMgr.state.entities[this._id].itemgroups.current[
                    this.code
                ]
            );
        } else {
            return this.removeGroups(this.entity);
        }
    }

    public getGroupStateTree(ignoreState?: boolean) {
        const state = this.getGroupState(ignoreState);
        if (this.groups) {
            state.groups = [];
            this.groups.forEach((subgroup) => {
                state.groups.push(subgroup.getGroupStateTree(ignoreState));
            });
        }
        return state;
    }
}
