import React, { useEffect, useState } from 'react';
import { ICatalogPlus } from 'Interfaces';
import { ThreeDots } from 'react-loader-spinner';
import { CatalogCard } from '../catalogCard/CatalogCard';
import { useInView } from 'react-intersection-observer';
import { strings } from 'strings';
import './catalogGallery.scss';
import config from 'config';

type CatalogGalleryProps = {
    visibleCatalogs: ICatalogPlus[],
    hiddenCatalogCodes: Array<string>,
    isLoading: boolean,    
    isOnline: boolean,
    theme: string,
    startFresh: number,
    baseUrl: string,
    tabIndex: number,
    onCatalogAction?(action: string): void,
    onCatalogVisibilityChanged(catalog: ICatalogPlus): void,
    onRequestCatalogAccess(catalog: ICatalogPlus): void
}

export function CatalogGallery(props: CatalogGalleryProps) {
    const catalogPerPage: number = 20,
        loaderColor: string = config.loaderColor,
        [pageOffset, setPageOffset] = useState<number>(0),
        [loadedCatalogs, setLoadedCatalogs] = useState<number>(catalogPerPage),
        [activeCardOffset, setActiveCardOffset] = useState<number>(0),
        [displayCatalogs, setDisplayCatalogs] = useState<Array<ICatalogPlus>>(props.visibleCatalogs.slice(0, loadedCatalogs)),
        { ref, inView, entry } = useInView({
            root: document.querySelector('#catalogListEntries'),
            rootMargin: '0px 0px 150px 0px',
            threshold: 0
        }), 

        area: HTMLElement | null = document.getElementById('catalogListEntries'),
        areaRect: DOMRect | null = area ? area.getBoundingClientRect() : null,
        containerWidth: number = areaRect ? areaRect.width - 20 : 356,
        cardsPerRow: number = Math.floor(containerWidth / 356),

        addCatalogToDisplay = () => {
            //load next page in 
            if (props.visibleCatalogs.length > displayCatalogs.length) {
                const nextPage: number = pageOffset + 1,
                    nextCatalogs: Array<ICatalogPlus> = props.visibleCatalogs.slice(nextPage * catalogPerPage, catalogPerPage + (nextPage * catalogPerPage)),
                    updaytedDisplayCatalogs: Array<ICatalogPlus> = displayCatalogs.concat(nextCatalogs);

                setDisplayCatalogs(updaytedDisplayCatalogs);
                setPageOffset(nextPage);
            }
        },
        handleCatalogVisibilityChanged = (catalog: ICatalogPlus) => {
            const cardElement: HTMLElement | null = document.getElementById(catalog.code);

            if (cardElement) {
                const cardRect: DOMRect = cardElement.getBoundingClientRect(),
                    offset: number = cardElement.offsetTop - cardRect.y + 113;

                setActiveCardOffset(offset);
                setLoadedCatalogs(displayCatalogs.length);                
            }

            props.onCatalogVisibilityChanged(catalog);
        };

    useEffect(() => {
        if (props.visibleCatalogs.length) {
            const updatedCatalog: Array<ICatalogPlus> = props.visibleCatalogs.slice(0, loadedCatalogs);
            
            setPageOffset(loadedCatalogs / catalogPerPage - 1);
            setDisplayCatalogs([...updatedCatalog]);           
        } else {
            setPageOffset(0);
            setDisplayCatalogs([]);
        }

    }, [props.visibleCatalogs]);

    useEffect(() => {
        if (inView) {
            addCatalogToDisplay();
        }
    }, [inView]);

    useEffect(() => {
        const parentDiv: HTMLElement | null = document.getElementById('catalogListEntries');

        if (activeCardOffset && parentDiv) {
            parentDiv.scrollTop = activeCardOffset;            
            setActiveCardOffset(0);
        }
    }, [displayCatalogs]);

    useEffect(() => {
        setLoadedCatalogs(catalogPerPage);
        const parentDiv: HTMLElement | null = document.getElementById('catalogListEntries');

        if (parentDiv) {
            parentDiv.scrollTop = 0;            
            setActiveCardOffset(0);
        }
    }, [props.startFresh]);

    return (
        <div className={`catalog-gallery__container nice-scrolling`} id={`catalogListEntries`}>
            {!props.isLoading && props.visibleCatalogs.length === 0 &&
                <div className="catalog-gallery__no-items">
                    {strings['catalog.entry.noResult']}
                </div>
            }
            {props.isLoading && !props.visibleCatalogs.length &&
                <div className="catalog-gallery__loader">
                    <ThreeDots color={loaderColor} height={80} width={80} />
                </div>
            }
            {displayCatalogs.length > 0 && displayCatalogs.map((catalog: ICatalogPlus, index: number) => {

                const borderTop: boolean = index + 1 > cardsPerRow;
                const borderRight: boolean = (index % cardsPerRow !== 0) && (index < displayCatalogs.length ) ;

                return (
                    <CatalogCard
                        key={catalog.id}
                        catalog={catalog}
                        hiddenCatalogCodes={props.hiddenCatalogCodes}
                        isHidden={catalog.isHidden}                        
                        isOnline={props.isOnline}
                        borderRight={borderRight}
                        borderTop={borderTop}
                        baseUrl={props.baseUrl}
                        tabIndex={props.tabIndex + index + 1}
                        onCatalogAction={props.onCatalogAction}
                        onCatalogVisibilityChanged={handleCatalogVisibilityChanged}
                        onRequestCatalogAccess={props.onRequestCatalogAccess}
                    />
                );
            })}
            {displayCatalogs.length > 0 &&
                <div ref={ref} className='item-gallery__loadMore'>{inView}</div>
            }
        </div>
    )
}