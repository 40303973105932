import React from "react";
import { Control } from "../control/control.component";
import { ControlProps } from "../control/control.type";
import './text-field.scss'

type TextFieldProps = ControlProps & React.TextareaHTMLAttributes<HTMLTextAreaElement>

export const TextField = React.forwardRef<HTMLDivElement, TextFieldProps>(

    (props: TextFieldProps, controlRef) => {

        const rest = {...props};

        delete rest.label;
        delete rest.required;
        delete rest.icon;
        delete rest.onClickIcon;
        delete rest.iconAction;
        delete rest.onClickAction;
        delete rest.errorMessage;
        delete rest.className;
        delete rest.style;
        delete rest.value;
        delete rest.disabledAction;
        delete rest.tooltipIcon;
        delete rest.tooltipIconAction;

        return <Control
            ref={controlRef}
            label={props.label}
            required={props.required}
            disabled={props.disabled}
            icon={props.icon}
            onClickIcon={props.onClickIcon}
            iconAction={props.iconAction}
            onClickAction={props.onClickAction}
            errorMessage={props.errorMessage}
            className={props.className}
            disabledAction={props.disabledAction}
            tooltipIcon={props.tooltipIcon}
            tooltipIconAction={props.tooltipIconAction}
        >
            <textarea className="cy-text-field" value={props.value ?? ""} {...rest} />
        </Control>
    }

)