import { useState } from 'react';

export function usePropertyChangeEvents() {

    const [state, setState] = useState<{
        manager: PropertyChangeEventsMgr
    }>({
        manager: propertyChangeEvents
    });

    const getPropertyEvent = (path: (string | number)[]) =>  {
        return state.manager.getPropertyEvent(path);
    }

    const addPropertyChangeEvent = (path: (string | number)[], action: () => void) => {
        state.manager.addPropertyChangeEvent(path, action);
        setState({ manager: state.manager });
    }

    const removePropertyChangeEvent = (path: (string | number)[]) => {
        state.manager.removePropertyChangeEvent(path);
        setState({ manager: state.manager });
    }

    return {
        getPropertyEvent,
        addPropertyChangeEvent,
        removePropertyChangeEvent
    };

}

export class PropertyChangeEventsMgr {

    public events: {
        path: (string | number)[],
        action: () => void
    }[];

    constructor() {
        this.events = [];
    }

    public getPropertyEvent = (path: (string | number)[]) =>  {
        const externalSchema = this.events.find((e) => e.path.join(".") === path.join("."));
        if (externalSchema) {
            return externalSchema.action;
        }
    }

    public addPropertyChangeEvent = (path: (string | number)[], action: () => void) => {
        const foundEvent = this.events.find((e) => e.path.join(".") === path.join("."));
        if (foundEvent) {
            foundEvent.action = action;
        }
        else {
            this.events.push({ path, action });
        }
    }

    public removePropertyChangeEvent = (path: (string | number)[]) => {
        this.events.filter((e) => e.path.join(".") !== path.join("."));
    }

}

const propertyChangeEvents = new PropertyChangeEventsMgr();