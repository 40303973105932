import JSONStringifier from "./JSONStringifier";
import { CommonUtils } from "@cic/utils";

export default class DesignStateZone extends JSONStringifier<IZone> implements IZone {
    private _id: string;
    private _type?: string;
    private _position?: any;
    private _orientation?: any;
    private _shape?: any;
    private _connections?: Array<IConnection>;
    
    constructor() {
        super();
        this._id = "zone_" + CommonUtils.genGUID();
    }

    get id(): string {
        return this._id;
    }
    
    get type(): string | undefined {
        return this._type;
    }
    
    get position(): any | undefined {
        return this._position;
    }

    get orientation(): any | undefined {
        return this._orientation;
    }

    get shape(): any | undefined {
        return this._shape;
    }

    get connections(): Array<IConnection> | undefined {
        return this._connections;
    }

    load(zone: IZone) {
        this._id = zone.id;
        this._type = zone.type;
        this._position = zone.position;
        this._orientation = zone.orientation;
        this._shape = zone.shape;
        this._connections = zone.connections;        
    }
}