import React, { useEffect, useState } from "react";
import "./CiCLoader.scss";

export function CiCLoader(props: {alwaysShow?: boolean}) {
    const [showLoader, setShowLoader] = useState(props.alwaysShow ?? false);

    useEffect(() => {
        if (!props.alwaysShow) {
            window.addEventListener(
                "cicapi-loading",
                () => {
                    setShowLoader(true);
                },
                { once: true },
            );
            window.addEventListener(
                "cicapi-ready",
                () => {
                    setShowLoader(false);
                },
                { once: true },
            );
        }
    }, [props]);
    
    if (!showLoader) return null;

    return ( 
        <div className="cic-loader">
            <div className="cic-loader__loader"></div>
        </div>
    );
}
