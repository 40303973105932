import { CatalogVersion, IConsolidatedCatalogVersion } from "./CatalogVersion";
import { ConfigurationState, FeatureState, IConsolidatedConfigurationState } from "./ConfigurationState";
import { ItemBase, IConsolidatedItemBase } from "./ItemBase";
import { ConfigurationChange, IConsolidatedConfigurationChange } from "./ConfigurationChange";
import { GeometriesInfo, IConsolidatedGeometriesInfo } from "./GeometriesInfo";

export class ItemVariantFactory  {
    static create(itemVariant: IItemVariant | V2.IItemVariant): IConsolidatedItemVariant {
        return new ItemVariant(itemVariant).serialize();
    }
}
    
export class ItemVariant extends ItemBase  {
    protected _configurationState?: ConfigurationState;
    protected _subItemScope?: string;
    protected _subItems?: Array<ItemVariant>;
    protected _hasFeatures?: boolean;
    protected _uuid?: string;
    protected _selected?: boolean;
    protected _position?: V2.Vector3;
    protected _orientation?: V2.Vector3;
    // V1 ONLY
    protected _attributes?: Array<IAttribute>;
    protected _catalogVersion?: CatalogVersion;
    protected _appliedChanges?: Array<ConfigurationChange>;
    // V2 ONLY
    protected _orientationCenter?: V2.Vector3;
    protected _scale?: V2.Vector3;
    protected _scaleCenter?: V2.Vector3;
    protected _geometriesInfo: GeometriesInfo;
    protected _scaleFactorUnit: number;

    constructor(itemVariant: IItemVariant | V2.IItemVariant) {
        super(itemVariant, (itemVariant as V2.IItemVariant)?.geometriesInfo);

        if (itemVariant.configurationState?.length) {
            this._configurationState = [];
            itemVariant.configurationState.forEach((featureState: IFeatureState) => {
                this._configurationState?.push(new FeatureState(featureState));
            });
        }

        this._subItemScope = itemVariant.subItemScope;

        if (itemVariant.subItems?.length) {
            this._subItems = [];
            itemVariant.subItems.forEach((subItem: IItemVariant | V2.IItemVariant) => {
                this._subItems?.push(new ItemVariant(subItem));
            });
        }

        this._hasFeatures = itemVariant.hasFeatures;
        this._uuid = itemVariant.uuid;
        this._selected = itemVariant.selected;
        this._position = itemVariant.position as V2.Vector3;
        this._orientation = itemVariant.orientation as V2.Vector3;

        // V1 ONLY
        let v1ItemVariant: IItemVariant | undefined = itemVariant as IItemVariant;

        if (v1ItemVariant.attributes?.length) {
            this._attributes = [];
            v1ItemVariant.attributes.forEach((attribute: IAttribute) => {
                this._attributes?.push(attribute);
            });
        }

        if (v1ItemVariant.catalogVersion) {
            this._catalogVersion = new CatalogVersion(v1ItemVariant.catalogVersion as ICatalogVersion);
        }

        if (v1ItemVariant.appliedChanges?.length) {
            this._appliedChanges = [];
            v1ItemVariant.appliedChanges.forEach((change: IConfigurationChange) => {
                this._appliedChanges?.push(new ConfigurationChange(change));
            });
        }

        // V2 ONLY
        let v2ItemVariant: V2.IItemVariant | undefined = itemVariant as V2.IItemVariant;
        this._scaleFactorUnit = v2ItemVariant.scaleFactorUnit ? v2ItemVariant.scaleFactorUnit : 1000;
        
        if (v2ItemVariant.geometriesInfo) {
            this._geometriesInfo = new GeometriesInfo(v2ItemVariant.geometriesInfo);
        } else {
            this._geometriesInfo = new GeometriesInfo({ 
                geometryTypes: v1ItemVariant.geometryTypes ?? [],
                hasGeometry: v1ItemVariant.hasGeometry ?? false,
            });
        }

        this._orientationCenter = v2ItemVariant.orientationCenter;
        this._scale = v2ItemVariant.scale;
        this._scaleCenter = v2ItemVariant.scaleCenter;
    }

    serialize(): IConsolidatedItemVariant {
        const itemVariant: IConsolidatedItemVariant = {
            ...super.serialize(),
            geometriesInfo: this._geometriesInfo.serialize(),
            scaleFactorUnit: this._scaleFactorUnit
        };

        if (this._configurationState !== undefined) {
            itemVariant.configurationState = this._configurationState.map((featureState: FeatureState) => featureState.serialize());
        }

        if (this._subItemScope !== undefined) {
            itemVariant.subItemScope = this._subItemScope;
        }

        if (this._subItems !== undefined) {
            itemVariant.subItems = this._subItems.map((subItem: ItemVariant) => subItem.serialize());
        }

        if (this._hasFeatures !== undefined) {
            itemVariant.hasFeatures = this._hasFeatures;
        }

        if (this._uuid !== undefined) {
            itemVariant.uuid = this._uuid;
        }

        if (this._selected !== undefined) {
            itemVariant.selected = this._selected;
        }

        if (this._position !== undefined) {
            itemVariant.position = this._position;
        }

        if (this._orientation !== undefined) {
            itemVariant.orientation = this._orientation;
        }

        if (this._attributes !== undefined) {
            itemVariant.attributes = this._attributes;
        }

        if (this._catalogVersion !== undefined) {
            itemVariant.catalogVersion = this._catalogVersion.serialize();
        }

        if (this._appliedChanges !== undefined) {
            itemVariant.appliedChanges = this._appliedChanges.map((change: ConfigurationChange) => change.serialize());
        }

        if (this._orientationCenter !== undefined) {
            itemVariant.orientationCenter = this._orientationCenter;
        }

        if (this._scale !== undefined) {
            itemVariant.scale = this._scale;
        }

        if (this._scaleCenter !== undefined) {
            itemVariant.scaleCenter = this._scaleCenter;
        }

        return itemVariant;
    }
}

export interface IConsolidatedItemVariant extends IConsolidatedItemBase {
    configurationState?: IConsolidatedConfigurationState;
    subItemScope?: string;
    subItems?: Array<IConsolidatedItemVariant>;
    hasFeatures?: boolean;
    uuid?: string;
    selected?: boolean;
    position?: V2.Vector3;
    orientation?: V2.Vector3;
    attributes?: Array<IAttribute>;
    catalogVersion?: IConsolidatedCatalogVersion;
    appliedChanges?: Array<IConsolidatedConfigurationChange>;
    orientationCenter?: V2.Vector3;
    scale?: V2.Vector3;
    scaleCenter?: V2.Vector3;
    geometriesInfo: IConsolidatedGeometriesInfo;
    scaleFactorUnit: number;
}