export class NumericalRangeFactory {
    static create(range: INumericalRange): IConsolidatedNumericalRange {
        return new NumericalRange(range).serialize();
    }
}

export class NumericalRange {
    protected _min: string | number;
    protected _max: string | number;
    protected _increment?: string | number;

    constructor(range: INumericalRange) {
        this._min = range.min;
        this._max = range.max;
        this._increment = range.increment;
    }

    get min(): string | number {
        return this._min;
    }

    get max(): string | number {
        return this._max;
    }

    get increment(): string | number | undefined {
        return this._increment;
    }

    serialize(): IConsolidatedNumericalRange {
        const range: IConsolidatedNumericalRange = {
            min: this._min,
            max: this._max
        };

        if (this._increment !== undefined) {
            range.increment = this._increment;
        }

        return range;
    }
}

export interface IConsolidatedNumericalRange {
    min: string | number;
    max: string | number;
    increment?: string | number;
}