export class DimensionsFactory {
    static create(dimensions: IDimensions): IConsolidatedDimensions {
        return new Dimensions(dimensions).serialize();
    }
}
    
export class Dimensions {
    protected _width?: number;
    protected _height?: number;
    protected _depth?: number;
    protected _others?: IOtherDimensions;

    constructor(dimensions: IDimensions) {
        this._width = dimensions.width;
        this._height = dimensions.height;
        this._depth = dimensions.depth;
        this._others = dimensions.others;
    }

    serialize(): IConsolidatedDimensions {
        const dimensions: IConsolidatedDimensions = {};

        if (this._width !== undefined) {
            dimensions.width = this._width;
        }

        if (this._height !== undefined) {
            dimensions.height = this._height;
        }

        if (this._depth !== undefined) {
            dimensions.depth = this._depth;
        }

        if (this._others !== undefined) {
            dimensions.others = this._others;
        }

        return dimensions;
    }
}

export interface IConsolidatedDimensions {
    width?: number;
    height?: number;
    depth?: number;
    others?: IOtherDimensions;
}