import { StateModule } from "../authoringHubState";
import { StatesManagement } from "./statesManagement";
import { States, NotLoadedException, GroupTypes, StateEntity, GroupPendingChange } from "./authoringGroupsState.type";
import { EntityType } from "../../components";

export class AuthoringGroupsStateBase implements StateModule<States> {
    _stateManagement: StatesManagement = new StatesManagement();

    //StateModule
    get state() {
        return this._stateManagement._states;
    }

    //StateModule
    set state(state: States) {
        this._stateManagement._states = state;
    }

    //StateModule
    serialize() {
        return this.state;
    }

    _validate = (id: string, groupType: string) => {
        if (
            !(id in this._stateManagement._states._original) ||
            !(id in this._stateManagement._states._current) ||
            !(id in this._stateManagement._states._deleted)
        ) {
            throw new NotLoadedException("ID not found");
        } else if (
            !(groupType in this._stateManagement._states._original[id]) ||
            !(groupType in this._stateManagement._states._current[id]) ||
            !(groupType in this._stateManagement._states._deleted[id])
        ) {
            throw new NotLoadedException("Group type not found");
        }
    };

    _getGroupTypes = () => {
        return GroupTypes;
    };

    _createPendingChange = (id: string, groupType: string, code: string, states?: { new?: boolean, removed?: boolean, targetCode?: string }, nesting?: { rootEntity: StateEntity }, tree?: boolean): GroupPendingChange => {

        const original = this._stateManagement.getOriginalEntity(id, groupType, code)?.entity;

        let currentEntity;
        if (nesting?.rootEntity) {
            currentEntity = this._stateManagement.getCurrentEntity(id, groupType, nesting.rootEntity?.entity.code);
        }
        else {
            currentEntity = this._stateManagement.getCurrentEntity(id, groupType, code);
        }

        const current = tree ? 
            currentEntity?.getGroupStateTree() :
            currentEntity?.getGroupState();
        
        return {
            type: groupType as EntityType,
            original: original,
            current: current,
            rootEntity: nesting?.rootEntity.entity,
            ...states
        };

    }

   _getPendingChanges = (id: string, groupType: string, code?: string): GroupPendingChange[] => {
        
        let rootEntity: StateEntity | undefined;
        let changes = this._stateManagement.getChanges(id, groupType);
        let nesting: any | undefined;

        if (code) {

            changes.new.forEach((e) => {
                const group = this._stateManagement.getCurrentGroup(id, groupType, e.code);
                if (group) {
                    if (this._stateManagement._isChild(code, group)) {
                        nesting = {
                            rootEntity: group
                        }
                    }
                }
            });

            if (nesting) {
                changes.new = [{
                    code: code,
                    targetCode: rootEntity?.entity.code
                }];
            }
            else {
                changes.new = changes.new.filter((e) => e.code === code);
            }

            changes.deleted = changes.deleted.filter((e) => e.code === code);
            changes.moved = changes.moved.filter((e) => e.code === code);
        }

        let pendingChanges: GroupPendingChange[] = [
            ...changes.new.map((e) => this._createPendingChange(id, groupType, e.code, { new: true, targetCode: e.targetCode }, nesting, true)),
            ...changes.deleted.map((e) => this._createPendingChange(id, groupType, e.code, { removed: true, targetCode: e.targetCode })),
            ...changes.moved.map((e) => this._createPendingChange(id, groupType, e.code, { targetCode: e.targetCode }))
        ];

        return pendingChanges;

    }
}
