import React from 'react';
import { createRoot } from 'react-dom/client';
import { importCiCAPI } from '@cic/ui-toolkit';
import CatalogSettingsApp from 'components/catalogSettingsApp/CatalogSettingsApp';
import { CiCLoader } from 'components/common/loader/CiCLoader';
import { loadAppConfig, IAppConfig } from 'components/common/api/loadAppConfig';
import { ConsoleOutput } from 'components/common/consoleOutput/ConsoleOutput';

//=============================================================================
function renderLoading(root: any) {    
    root.render(
        <CiCLoader  alwaysShow={true} />            
    );
}

//=============================================================================
function render(root: any) {    
    root.render(
        <CatalogSettingsApp 
            baseUrl={''} 
            width={0} 
            height={0} 
            hideNews={false} 
            itemContextList={[]} 
            theme={''} 
        />
    );
}

//=============================================================================

async function main() {
    let root: any = createRoot(document.getElementById('root') as Element);
    
    renderLoading(root);

    let appConfig: IAppConfig = await loadAppConfig();

    await importCiCAPI(appConfig.cicapi_url);
    await CiCAPI.init({ apiSrcUrl: appConfig.cicapi_url });

    render(root);
    ConsoleOutput('Catalog Browser', appConfig.build_version);
}


//=============================================================================
window.addEventListener('load', () => {
    main();
});