export enum EntityType {
    ITEMGROUPS = "ItemGroups",
    MATERIALS = "Materials",
    ITEMS = "Items",
    FEATURES = "Features",
    RESTRICTIONS = "Restrictions",
    ATTRIBUTES = "Attributes",
    DEPENDENCIES = "Dependencies",
}

export enum CustomInput {
    URIInput = "URIInput",
    ColorInput = "ColorInput",
    VectorInput = "VectorInput",
    AttributeDataType = "AttributeDataType",
    MCodeInput = "MCodeInput",
    ItemModificationAttributes = "ItemModificationAttributes",
    ItemImages = "ItemImage",
}

export const config = {
    navigatable: {
        itemRef: EntityType.ITEMS,
        featureRef: EntityType.FEATURES,
        restrictionRefs: EntityType.RESTRICTIONS,
        materialRef: EntityType.MATERIALS,
        attributeRef: EntityType.ATTRIBUTES,
        layers: EntityType.MATERIALS,
    },
    minEnumSizeSelect: 3,
    minObjectKeysList: 5,
    collapsable: ["groupRefs", "restrictionRefs", "featureRefs/[]"],
    disabledSections: ["groupRefs", "groups"],
    anyOf: [
        {
            path: "geometries/.*/lightProperties$",
            key: "type",
        },
        {
            path: "geometries/.*/uvProjection$",
            key: "method",
        },
    ],
    customInputs: [
        {
            path: "mCode",
            input: CustomInput.MCodeInput,
        },

        {
            path: "itemModification/attributes$",
            input: CustomInput.ItemModificationAttributes,
        },
        {
            path: "images/.*/uri",
            input: CustomInput.ItemImages,
        },
        {
            path: "baseColorFactor|avgColor",
            input: CustomInput.ColorInput,
        },
        {
            path: "defaultValue",
            input: CustomInput.AttributeDataType,
        },
        //        {
        //            path: 'geometries/.*/uri',
        //            input: CustomInput.URIInput,
        //            options: {
        //                imgPreview: false,
        //            },
        //        },
        //        {
        //            path: 'textures/.*/uri',
        //            input: CustomInput.URIInput,
        //            options: {
        //                imgPreview: true,
        //                RGBChannels: true,
        //            },
        //        },
        //        {
        //            path: 'images/.*/uri',
        //            input: CustomInput.URIInput,
        //            options: {
        //                imgPreview: true,
        //                RGBChannels: false,
        //            },
        //        },
    ],
};
