import classNames from "classnames";
import React from "react";
import { Icons } from "../../components/icon/icons.type";
import { Icon } from "../../components/icon/icon.component";
import { Control } from "../../components/control/control.component";
import { ControlNoIconProps } from "../../components/control/control.type";
import './select.scss'

type SelectProps = ControlNoIconProps & React.SelectHTMLAttributes<HTMLSelectElement>

export const Select = React.forwardRef<HTMLDivElement, SelectProps>(

    (props: SelectProps, controlRef) => {

        const classes = classNames("cy-select", props.disabled ? "cy-select--disabled" : "");
        const rest = {...props};

        delete rest.label;
        delete rest.required;
        delete rest.iconAction;
        delete rest.onClickAction;
        delete rest.errorMessage;
        delete rest.className;
        delete rest.style;
        delete rest.disabledAction;
        delete rest.tooltipIconAction;

        return <Control
            ref={controlRef}
            label={props.label}
            required={props.required}
            disabled={props.disabled}
            iconAction={props.iconAction}
            onClickAction={props.onClickAction}
            errorMessage={props.errorMessage}
            className={props.className}
            disabledAction={props.disabledAction}
            tooltipIconAction={props.tooltipIconAction}
        >
            <div className={classes}>
                <select {...rest} title={props.value?.toString()}>
                    {props.children}
                </select>
                <Icon className="cy-select__icon" name={Icons.ChevronDownSmall} height={16} width={16} />
            </div> 
        </Control>

   }

)