import React, { Fragment, CSSProperties, MutableRefObject, useRef, useState, useCallback } from 'react';
import { CSSTransition } from 'react-transition-group';
import { ThumbnailViewer } from 'thumbnailViewer/ThumbnailViewer';
import _ from 'lodash';
import './entryLabel.scss';

type EntryLabelProps = {    
    objectType: any,
    label: string,
    thumbnail?: string | undefined,
    isSelected: boolean,    
    draggable?: boolean,
    sidePadding?: string,
    sideMargin?: string,
    flexGrow?: boolean,
    isDisabled: boolean,
    optionStyle?: boolean, 
    featureStyle?: boolean,
    variantStyle?: boolean,
    nbrItems?: string,
    nbrItemsNextTo?: boolean,
    dataFor?: string,
    tabIndex?: number,
    onDragStart?(event: React.DragEvent<HTMLButtonElement>): void,    
    onLabelClicked?(objectType: any): void,
    onMouseOver?(): void,
    onMouseOut?(): void
}

export function EntryLabel(props: EntryLabelProps) {
    const debounceTime: number = 1000,
        thumbnailRef: MutableRefObject<HTMLDivElement | null> = useRef(null),
        nodeTransitionRef = useRef(null),
        [isHoveredThumbnail, setHoveredThumnail] = useState<boolean>(false),
        //[debounceEnabled, setDebounceEnabled] = useState<boolean>(true),
        activeClassName: string = props.isSelected ? 'is-active' : '', //needed?
        paddingStyle: CSSProperties = props.sidePadding ? {padding: '0px ' + props.sidePadding} : {},
        marginStyle: CSSProperties = props.sideMargin ? {margin: '0px ' + props.sideMargin} : {},
        flexGrowClassName: string = props.flexGrow ? 'full-width' : '', 
        disabledClassName: string = props.isDisabled ? 'is-disabled' : '',  
        fontClassName: string = props.optionStyle ? 'option-style' : props.featureStyle ? 'feature-style' : props.variantStyle ? 'variant-style' :'',
        numberOfItems: string = props.nbrItems ? props.nbrItems : '',
        labelFlexGrow: string = props.nbrItemsNextTo !== true ? 'full-width' : '',

        handleLabelClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            event.preventDefault();
            if (props.onLabelClicked) {
                props.onLabelClicked(props.objectType);   
            }                                             
        },
        handleDragStart = (event: React.DragEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            if (props.onDragStart) {
                props.onDragStart(event);
            }
        },
        handleMouseOver = (event: React.MouseEvent<HTMLButtonElement>) => {
            if (props.onMouseOver) {
                props.onMouseOver();
            }
        },
        handleMouseOut = (event: React.MouseEvent<HTMLButtonElement>) => {
            if (props.onMouseOut) {
                props.onMouseOut();
            }
        },
        handleMouseOverThumnail = (event: React.MouseEvent<HTMLSpanElement>) => {
            event.stopPropagation();
            event.preventDefault();
            setHoveredThumnail(true);
        },
        handleMouseOutThumnail = (event: React.MouseEvent<HTMLSpanElement>) => {    
            event.stopPropagation();
            event.preventDefault();
            setHoveredThumnail(false);
            //debounce();
        },
        debounce = useCallback(
            _.debounce(() => {
                setHoveredThumnail(false);
            }, debounceTime),
            []
        );

    return (        
        <Fragment>
            <button className={`entry-label__container ${activeClassName} ${fontClassName} ${disabledClassName} ${flexGrowClassName}  `} 
                style={paddingStyle}
                onClick={handleLabelClick}
                draggable={props.draggable ? "true" : 'false'}
                onDragStart={handleDragStart}
                onMouseOver={handleMouseOver} 
                onMouseOut={handleMouseOut}
                data-tooltip-id={props.dataFor || undefined}
                data-tooltip-content={props.label}
                data-tooltip-delay-show={1500}
                data-tooltip-hide={100}                 
                data-tooltip-place='bottom'                
                tabIndex={props.tabIndex}
            >
                <div className={`entry-label__name ${labelFlexGrow}`}>                    
                    {props.label}
                
                    {numberOfItems !== '' &&
                        <span className='entry-label__nbr-items'>                                            
                            ({numberOfItems})                                            
                        </span>
                    }                                         
                </div>     
                {props.thumbnail && 
                    <div ref={thumbnailRef} className='entry-label__thumbnail' 
                        onMouseOver={handleMouseOverThumnail}
                        onMouseOut={handleMouseOutThumnail}
                    >                                            
                        <img src={props.thumbnail} width='32' height='32'/>                                            
                    </div>
                }                           
            </button>  

            <CSSTransition
                    nodeRef={nodeTransitionRef}
                    in={isHoveredThumbnail && thumbnailRef.current !== null}
                    timeout={300}
                    classNames='popup'
                    unmountOnExit                            
                >
                <ThumbnailViewer
                    nodeRef={nodeTransitionRef}
                    key={props.thumbnail || ''}
                    uri={props.thumbnail || ''}
                    el={thumbnailRef.current! as HTMLDivElement} 
                />
            </CSSTransition>
        </Fragment>          
    );
}