import { IConsolidatedItemVariant } from "./ItemVariant";
import { IConsolidatedItemBase, ItemBase } from "./ItemBase";
import { ItemVariantProposal, IConsolidatedItemVariantProposal } from "./ItemVariantProposal";

export class SearchItemFactory {
    static create(searchItem: IItem | V2.IItem): IConsolidatedSearchItem {
        return new SearchItem(searchItem).serialize();
    }

    static fromItemVariant(source: IConsolidatedItemVariant): IConsolidatedSearchItem {
        return source as IConsolidatedItemBase as IConsolidatedSearchItem;
    }
}

export class SearchItem extends ItemBase {
    protected _variantProposals?: Array<ItemVariantProposal>;

    constructor(searchItem: IItem | V2.IItem) {
        super(searchItem);

        if (searchItem.variantProposals?.length) {
            this._variantProposals = [];
            searchItem.variantProposals.forEach((variantProposal: IItemVariantProposal | V2.IItemVariantProposal) => {
                this._variantProposals?.push(new ItemVariantProposal(variantProposal));
            });
        }
    }

    get variantProposals(): Array<ItemVariantProposal> | undefined {
        return this._variantProposals;
    }

    serialize(): IConsolidatedSearchItem {
        const searchItem: IConsolidatedSearchItem = {
            ...super.serialize()
        };

        if (this._variantProposals !== undefined) {
            searchItem.variantProposals = this._variantProposals.map((variantProposal: ItemVariantProposal) => variantProposal.serialize());
        }

        return searchItem;
    }
}

export interface IConsolidatedSearchItem extends IConsolidatedItemBase {
    variantProposals?: Array<IConsolidatedItemVariantProposal>;
}