import { FeatureClassification, IConsolidatedFeatureClassification } from "./FeatureClassification";
import { FeatureOptionClassification, IConsolidatedFeatureOptionClassification } from "./FeatureOptionClassification";

export type ConfigurationState = Array<FeatureState>;
export type IConsolidatedConfigurationState = Array<IConsolidatedFeatureState>;

export class FeatureStateFactory {
    static create(featureState: IFeatureState): IConsolidatedFeatureState {
        return new FeatureState(featureState).serialize();
    }

    static createMany(featureStates: IFeatureState[]): IConsolidatedConfigurationState {
        return featureStates.map((featureState: IFeatureState) => FeatureStateFactory.create(featureState));
    }
}

export class ConfigurationStateFactory {
    static create(configurationState: IConfigurationState): IConsolidatedConfigurationState {
        return FeatureStateFactory.createMany(configurationState);
    }
}

export class FeatureState {
    protected _featureId: string;
    protected _optionId: string;
    protected _featureClassification?: FeatureClassification;
    protected _optionClassification?: FeatureOptionClassification;
    protected _value?: number;
    protected _subItemScope?: string;
    protected _source?: FeatureStateSource;

    constructor(featureState: IFeatureState) {
        this._featureId = featureState.featureId;
        this._optionId = featureState.optionId;
        this._featureClassification = featureState.featureClassification ? new FeatureClassification(featureState.featureClassification) : undefined;
        this._optionClassification = featureState.optionClassification ? new FeatureOptionClassification(featureState.optionClassification) : undefined;
        this._value = featureState.value;
        this._subItemScope = featureState.subItemScope;
        this._source = featureState.source;
    }

    serialize(): IConsolidatedFeatureState {
        const featureState: IConsolidatedFeatureState = {
            featureId: this._featureId,
            optionId: this._optionId
        };

        if (this._featureClassification !== undefined) {
            featureState.featureClassification = this._featureClassification.serialize();
        }

        if (this._optionClassification !== undefined) {
            featureState.optionClassification = this._optionClassification.serialize();
        }

        if (this._value !== undefined) {
            featureState.value = this._value;
        }

        if (this._subItemScope !== undefined) {
            featureState.subItemScope = this._subItemScope;
        }

        if (this._source !== undefined) {
            featureState.source = this._source;
        }

        return featureState;
    }
}

export interface IConsolidatedFeatureState {
    featureId: string;
    optionId: string;
    featureClassification?: IConsolidatedFeatureClassification;
    optionClassification?: IConsolidatedFeatureOptionClassification;
    value?: number;
    subItemScope?: string;
    source?: FeatureStateSource;
}