import { SearchItem, IConsolidatedSearchItem } from "./SearchItem";
import { SearchResult, IConsolidatedSearchResult } from "./SearchResult";

export class SearchItemsResultFactory {
    static create(searchItemsResult: ISearchItemsResult | V2.ISearchItemsResult): IConsolidatedSearchItemsResult {
        return new SearchItemsResult(searchItemsResult).serialize();
    }
}

export class SearchItemsResult extends SearchResult {
    protected _items: Array<SearchItem>;

    constructor(searchItemsResult: ISearchItemsResult | V2.ISearchItemsResult) {
        super(searchItemsResult);

        this._items = [];
        if (searchItemsResult.items?.length) {
            searchItemsResult.items.forEach((item: IItem | V2.IItem) => {
                this._items.push(new SearchItem(item));
            });
        }
    }

    serialize(): IConsolidatedSearchItemsResult {
        return {
            ...super.serialize(),
            items: this._items.map((item: SearchItem) => item.serialize())
        };
    }
}

export interface IConsolidatedSearchItemsResult extends IConsolidatedSearchResult {
    items?: Array<IConsolidatedSearchItem>;
}