import { ItemPriceReportItemCalculationSteps, IConsolidatedItemPriceReportItemCalculationSteps } from "./ItemPriceReportItemCalculationSteps";

export class PricingReportItemCalculationStepsFactory {
    static create(pricingReportItemCalculationSteps: IPricingReportItemCalculationSteps): IConsolidatedPricingReportItemCalculationSteps {
        return new PricingReportItemCalculationSteps(pricingReportItemCalculationSteps).serialize();
    }
}

export class PricingReportItemCalculationSteps {
    protected _code: string;
    protected _expression: string;
    protected _baseValueType: string;
    protected _runningTotal: number;
    protected _baseValue: number;
    protected _sequence: number;
    protected _fromSequence?: number;
    protected _toSequence?: number;
    protected _calculationSteps?: Array<PricingReportItemCalculationSteps>;
    protected _descriptions?: IPricingReportItemCalculationDescriptions;
    protected _selectors?: IPricingReportItemCalculationSelectors;
    protected _customFunction?: ItemPriceReportItemCalculationSteps;
    protected _value: number;

    constructor(pricingReportItemCalculationSteps: IPricingReportItemCalculationSteps) {
        this._code = pricingReportItemCalculationSteps.code;
        this._expression = pricingReportItemCalculationSteps.expression;
        this._baseValueType = pricingReportItemCalculationSteps.baseValueType;
        this._runningTotal = pricingReportItemCalculationSteps.runningTotal;
        this._baseValue = pricingReportItemCalculationSteps.baseValue;
        this._sequence = pricingReportItemCalculationSteps.sequence;
        this._fromSequence = pricingReportItemCalculationSteps.fromSequence;
        this._toSequence = pricingReportItemCalculationSteps.toSequence;
        this._calculationSteps = pricingReportItemCalculationSteps.calculationSteps?.map((calculationStep: IPricingReportItemCalculationSteps) => new PricingReportItemCalculationSteps(calculationStep));
        this._descriptions = pricingReportItemCalculationSteps.descriptions;
        this._selectors = pricingReportItemCalculationSteps.selectors;
        this._customFunction = pricingReportItemCalculationSteps.customFunction ? new ItemPriceReportItemCalculationSteps(pricingReportItemCalculationSteps.customFunction) : undefined;
        this._value = pricingReportItemCalculationSteps.value;
    }

    serialize(): IConsolidatedPricingReportItemCalculationSteps {
        const pricingReportItemCalculationSteps: IConsolidatedPricingReportItemCalculationSteps = {
            code: this._code,
            expression: this._expression,
            baseValueType: this._baseValueType,
            runningTotal: this._runningTotal,
            baseValue: this._baseValue,
            sequence: this._sequence,
            value: this._value
        };

        if (this._fromSequence !== undefined) {
            pricingReportItemCalculationSteps.fromSequence = this._fromSequence;
        }
        
        if (this._toSequence !== undefined) {
            pricingReportItemCalculationSteps.toSequence = this._toSequence;
        }
        
        if (this._calculationSteps !== undefined) {
            pricingReportItemCalculationSteps.calculationSteps = this._calculationSteps.map((calculationStep: PricingReportItemCalculationSteps) => calculationStep.serialize());
        }
        
        if (this._descriptions !== undefined) {
            pricingReportItemCalculationSteps.descriptions = this._descriptions;
        }

        if (this._selectors !== undefined) {
            pricingReportItemCalculationSteps.selectors = this._selectors;
        }

        if (this._customFunction !== undefined) {
            pricingReportItemCalculationSteps.customFunction = this._customFunction.serialize();
        }

        return pricingReportItemCalculationSteps;

    }
}

export interface IConsolidatedPricingReportItemCalculationSteps {
    code: string;
    expression: string;
    baseValueType: string;
    runningTotal: number;
    baseValue: number;
    sequence: number;
    fromSequence?: number;
    toSequence?: number;
    calculationSteps?: Array<IConsolidatedPricingReportItemCalculationSteps>;
    descriptions?: IPricingReportItemCalculationDescriptions;
    selectors?: IPricingReportItemCalculationSelectors;
    customFunction?: IConsolidatedItemPriceReportItemCalculationSteps;
    value: number;
}