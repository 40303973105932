export const DEFAULT_PRECISION: number = 1000; // represents 3 decimal places
export const ROUNDING_1_32_INCH_PRECISION: number = (1 / 32) * 100000; // 1/32 of an inch corresponds to 5 decimals

export class NumberUtils {
    // this method can be used to round a number to a specific precision
    static roundToPrecision(value: number, precision: number = DEFAULT_PRECISION): number {
        return Math.round(value * precision) / precision;
    }

    // this method can be used to scale a number to a specific precision
    static scaleToPrecision(value: number, precision: number = DEFAULT_PRECISION, scaleFactor: number = 1): number {
        return this.roundToPrecision(value * scaleFactor, precision);
    }

    // this function should be used to compare two numbers to see if they are close to each other, when dealing with floating point numbers
    static isNumberCloseTo(num: number, epsilon: number = 0.01): boolean {
        return Math.abs(num) <= epsilon;
    }

    // this function will return a random number between min and max
    static randomInteger(min: number = 1, max: number = Number.MAX_SAFE_INTEGER): number { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    // this function is used to get the value of a number that is bound by a min and max value, then rounded to the nearest increment
    static getBoundValue(value: number, min: number, max: number, increment?: number): number {
        let boundValue: number = value;

        boundValue = Math.max(boundValue, min);
        boundValue = Math.min(boundValue, max);

        if (increment !== undefined && increment != 0) {
            boundValue = Math.round(boundValue / increment) * increment;
        }

        return boundValue;
    }
}
