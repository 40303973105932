import React from 'react'
import { IIconProps } from 'Interfaces'

export function PlusIcon(props: IIconProps) {
    return (
        <svg width={props.width ?? 16} height={props.height ?? 16} viewBox='0 0 16 16' fill='currentColor'>
            <path d="M14.7692 7.38462H8.61533V1.23077H7.38456V7.38462H1.23071V8.61539H7.38456V14.7692H8.61533V8.61539H14.7692V7.38462Z" fill="currentColor"/>
        </svg>
    )
}
