import { ConfigurationState, FeatureState, IConsolidatedConfigurationState } from "./ConfigurationState";
import { ItemBase, IConsolidatedItemBase } from "./ItemBase";

export class ItemVariantProposalFactory {
    static create(itemVariantProposal: IItemVariantProposal | V2.IItemVariantProposal): IConsolidatedItemVariantProposal {
        return new ItemVariantProposal(itemVariantProposal).serialize();
    }
}

export class ItemVariantProposal extends ItemBase {
    protected _proposedConfigurationState?: ConfigurationState;

    constructor(variantProposal: IItemVariantProposal | V2.IItemVariantProposal) {
        super(variantProposal);

        let configurationState: IConfigurationState | undefined = (variantProposal as IItemVariantProposal).proposedConfigurationState ?? (variantProposal as V2.IItemVariantProposal).configurationState;
        if (configurationState?.length) {
            this._proposedConfigurationState = [];
            configurationState.forEach((featureState: IFeatureState) => {
                this._proposedConfigurationState?.push(new FeatureState(featureState));
            });
        }
    }

    get proposedConfigurationState(): ConfigurationState | undefined {
        return this._proposedConfigurationState;
    }
    
    get configurationState(): ConfigurationState | undefined {
        return this._proposedConfigurationState;
    }
    
    serialize(): IConsolidatedItemVariantProposal {
        const itemVariantProposal: IConsolidatedItemVariantProposal = {
            ...super.serialize()
        };

        if (this.configurationState !== undefined) {
            itemVariantProposal.proposedConfigurationState = this.configurationState.map((featureState: FeatureState) => featureState.serialize());
            itemVariantProposal.configurationState = itemVariantProposal.proposedConfigurationState;
        }

        return itemVariantProposal;
    }
}

export interface IConsolidatedItemVariantProposal extends IConsolidatedItemBase {
    /** @deprecated use configurationState instead */
    proposedConfigurationState?: IConsolidatedConfigurationState;
    configurationState?: IConsolidatedConfigurationState;
}