import { IEntitySchema } from '../../SchemaEntityUtils';
import {
    SchemaPath,
    ValidationExtraData,
    ValidationResponse,
    ValidationRule,
} from '../SchemaValidationTypes';
import { MCodeUtils } from '../../MCodeUtils';

export class MCodeValidationRule extends ValidationRule {
    async exec(
        value: string,
        schema: IEntitySchema,
        path: SchemaPath,
        extraData?: ValidationExtraData | undefined
    ): Promise<ValidationResponse> {
        const mCodeObject: any = MCodeUtils.buildMCodeObject(schema);
        const [group, type, finish, coating] = value.split('.');

        const selectedTypeData = MCodeUtils.extractOrDefault(mCodeObject, group);
        const selectedFinishData = MCodeUtils.extractOrDefault(selectedTypeData, type);
        const selectedCoatingData = MCodeUtils.extractOrDefault(selectedFinishData, finish);

        const allSelected = {
            groups: Object.keys(mCodeObject),
            types: Object.keys(selectedTypeData),
            finishes: Object.keys(selectedFinishData),
            coatings: Object.keys(selectedCoatingData)
        };

        const response: ValidationResponse = {
            success: allSelected.groups.includes(group) &&
                allSelected.types.includes(type) &&
                allSelected.finishes.includes(finish) &&
                allSelected.coatings.includes(coating)
        };

        if (!response.success) {
            response.error = new Error('MCode is invalid.');
        }

        return response;
    }
}
