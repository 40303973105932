export default abstract class JSONStringifier<T> {
    // noinspection JSUnusedGlobalSymbols
    /**
     *  We add the toJSON function here so that it works with JSON.stringify and will only output valid getters
     */
    toJSON(): T {
        // This means we can't call JSON.stringify() within toJSON as it will cause an infinite loop so we clone using Object.assign(...)
        let clone: T = Object.assign({}, this) as T;

        // Find the getter method descriptors
        // Get methods are on the prototype, not the instance
        const descriptors = Object.getOwnPropertyDescriptors(Object.getPrototypeOf(this));

        // Check to see if each descriptor is a get method
        Object.keys(descriptors).forEach(key => {
            if (descriptors[key] && descriptors[key].get) {
                //Copy the result of each getter method onto the clone as a field
                delete clone[key as keyof T];
                // @ts-ignore
                clone[key] = this[key]; //Call the getter
            }
        });

        // Remove any left over private fields starting with '_'
        Object.keys(clone as object).forEach(key => {
            if (key.indexOf('_') == 0) {
                delete clone[key as keyof T];
            }
        });

        // toJSON requires that we return an object
        return clone;
    }
}