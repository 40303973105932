import React from 'react'
import { IIconProps } from 'Interfaces'

export function ArrowRightIcon(props: IIconProps) {
    return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none">
        <path d="M5.35373 1.29999L4.49219 2.16153L10.8306 8.49999L4.49219 14.8384L5.35373 15.7L12.5537 8.49999L5.35373 1.29999Z" fill="currentColor"/>
        <path d="M5.35373 1.29947L4.49219 2.16101L5.61897 3.28779L6.46172 2.40746L5.35373 1.29947Z" fill="#886AEE"/>
    </svg>
    )
}