import { EntityType } from "../schema";

export enum ConfigurableFieldChoice {
    TRANSLATIONS = 'translations',
}
export enum FieldChoice {
    AUTO = 'auto',
    ALL = 'all',
    CUSTOM = 'custom',
} 

type FieldTemplate = {
    fields: string[];
    unwind: string[];
}

type FieldTemplateList = {
    [key in ConfigurableFieldChoice]: {
        [key in EntityType]?: FieldTemplate
    };
};

export const fieldChoiceConfig: FieldTemplateList = {
    [ConfigurableFieldChoice.TRANSLATIONS]: {
        [EntityType.ITEMS]: {
            fields: [
                'code',
                "names.main",
                "names.planView",
                "names.others",
                "descriptions.main",
                "descriptions.short",
                "descriptions.long",
                "descriptions.moreInfoUri",
                "descriptions.keywords",
                "descriptions.others",
            ],
            unwind: [
                "names.main",
                "names.planView",
                "descriptions.main",
                "descriptions.short",
                "descriptions.long",
                "descriptions.moreInfoUri",
                "descriptions.keywords",
            ]
        },
        [EntityType.FEATURES]: {
            fields: [
                "code",
                "names",
                "descriptions",
                "options.names",
                "options.descriptions"
            ],
            unwind: [
                "names",
                "descriptions",
                "options",
                "options.names",
                "options.descriptions"
            ]
        },
        [EntityType.ATTRIBUTES]: {
            fields: [
                "code",
                "names",
                "descriptions",        
            ],
            unwind: [
                "names",
                "descriptions",        
            ]
        },
        [EntityType.ITEMGROUPS]: {
            fields: [
                'code',
                'names'
            ],
            unwind: [
                'names'
            ]
        },
        [EntityType.MATERIALS]: {
            fields: [
                'code',
                'names',
                'descriptions'
            ],
            unwind: [
                'names',
                'descriptions'
            ]
        },
        [EntityType.RESTRICTIONS]: {
            fields: [
                'code',
                'names'
            ],
            unwind: [
                'names'
            ]
        },
    }
}

export const localizationFields = [
    'names',
    'names.main',
    'descriptions',
    'descriptions.main',
    'descriptions.short',
    'descriptions.long',
    'descriptions.moreInfoUri',
    'descriptions.keywords',
    'descriptions.others.otherDescription',
    "options.names",
    "options.descriptions"
];


export function getAllFieldsTemplate(fieldChoice: ConfigurableFieldChoice): {
    fields: Record<EntityType, Set<string>>,
    unwind: Record<EntityType, Set<string>>,
} {
    const fields: Record<EntityType, Set<string>> = {
        [EntityType.ITEMS]: new Set(),
        [EntityType.FEATURES]: new Set(),
        [EntityType.ATTRIBUTES]: new Set(),
        [EntityType.ITEMGROUPS]: new Set(),
        [EntityType.MATERIALS]: new Set(),
        [EntityType.RESTRICTIONS]: new Set(),
        [EntityType.DEPENDENCIES]: new Set()
    };
    const unwind: Record<EntityType, Set<string>> = {
        [EntityType.ITEMS]: new Set(),
        [EntityType.FEATURES]: new Set(),
        [EntityType.ATTRIBUTES]: new Set(),
        [EntityType.ITEMGROUPS]: new Set(),
        [EntityType.MATERIALS]: new Set(),
        [EntityType.RESTRICTIONS]: new Set(),
        [EntityType.DEPENDENCIES]: new Set()
    };

    Object.keys(fieldChoiceConfig[fieldChoice] ?? {}).forEach((type) => {
        fields[type as EntityType] = new Set(fieldChoiceConfig[fieldChoice][type as EntityType]?.fields);
        unwind[type as EntityType] = new Set(fieldChoiceConfig[fieldChoice][type as EntityType]?.unwind);
    });

    return { fields, unwind };
}