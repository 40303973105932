import { EntityType } from "../components";
import { Entity } from "./EntityIOService";


export async function getCatalogEntities(type: EntityType, catalogVersionId: string) {
    let catalogEntitiesResponse: any

    const handleResponse = (resp: { success?: unknown; result?: Entity[] }) => {
        if (resp.success) {
            catalogEntitiesResponse = resp.result
        } else {
            console.error(resp);
        }
    };

    switch (type) {

        case EntityType.ITEMS:
            await CiCAPI.authoring
                .getCatalogItemDefs(catalogVersionId)
                .then(handleResponse);
            break;

        case EntityType.RESTRICTIONS:
            await CiCAPI.authoring
                //@ts-ignore
                .getCatalogRestrictionDefs(catalogVersionId)
                .then(handleResponse);
            break;

        case EntityType.MATERIALS:
            await CiCAPI.authoring
                //@ts-ignore
                .getCatalogMaterialDefs(catalogVersionId)
                .then(handleResponse);
            break;

        case EntityType.FEATURES:
            await CiCAPI.authoring
                //@ts-ignore
                .getCatalogFeatureDefs(catalogVersionId)
                .then(handleResponse);
            break;

        case EntityType.ATTRIBUTES:
            await CiCAPI.authoring
                //@ts-ignore
                .getCatalogAttributeDefs(catalogVersionId)
                .then(handleResponse);
            break;

        case EntityType.DEPENDENCIES:
            await CiCAPI.authoring.getDependenciesDef(catalogVersionId)
                .then((resp) => {
                    if (resp.result) {
                        catalogEntitiesResponse = [{
                            code: `${catalogVersionId}-dependencies`,
                            entity: resp.result
                        }];
                    }
                })
            break;
    }

    return catalogEntitiesResponse
}