
import { Helpers } from "./Helpers";
import * as stringsEN from "../../../public/nls/strings.en.json";
import * as stringsFR from "../../../public/nls/strings.fr.json";
import * as stringsFRCA from "../../../public/nls/strings.fr-CA.json";
import * as stringsFRFR from "../../../public/nls/strings.fr-FR.json";
import * as stringsES from "../../../public/nls/strings.es.json";
import * as stringsPTBR from "../../../public/nls/strings.pt-br.json";
import * as stringsPT from "../../../public/nls/strings.pt-pt.json";
import * as stringsIT from "../../../public/nls/strings.it-it.json";
import * as stringsDefault from "../../../public/nls/strings.en.json";
import config from 'config';

const STRINGS_BY_LOCALE = {
    'en': stringsEN,
    'fr': stringsFR,
    'fr-fr': stringsFRFR,
    'fr-ca': stringsFRCA,
    'es': stringsES,
    'pt-br': stringsPTBR,
    'pt-pt': stringsPT,
    'it-it': stringsIT,
    'default': stringsDefault
};

class Strings {
    default: string = 'en';
    strings: {[key: string]: string} = {};

    constructor() {
        this.strings = STRINGS_BY_LOCALE.default; 
        this.strings = Object.assign({}, this.strings, STRINGS_BY_LOCALE[this.getLocale()?.toLocaleLowerCase().split('-')[0] as keyof Object]);
        this.strings = Object.assign({}, this.strings, STRINGS_BY_LOCALE[this.getLocale()?.toLocaleLowerCase() as keyof Object]);
    }

    getLocale() {
        let defaultLocale = 'en',        
            storageValue: string | null = localStorage.getItem('locale'); 
        
        try {        
            if (CiCAPI && CiCAPI.getExecutionContext()) {
                return CiCAPI.getExecutionContext().locale
            } else if (storageValue) {    
                return storageValue;
            } else {
                return config['locale'];
            }
            
        } catch (e) {
            return storageValue || defaultLocale;  
        }
    }
}

export const strings = new Proxy(new Strings(), {
    get: function(target: any, prop, receiver) {
        if (target[prop]) {
            return Reflect.get(target, prop, receiver);
        } else {
            return target.strings[prop];
        }
    }
});