import { Icons } from "@cyncly/design-system";

export enum CommandPaletteGroup {
    Layout = "Layout",
    Authoring = "Authoring",
    Components = "Components",
    Items = "Items",
    Features = "Features",
}

export enum CommandPaletteStateEvent {
    AddExplorer = "command-palette:add-component:explorer",
    AddSource = "command-palette:add-component:source",
    AddViewer = "command-palette:add-component:glbViewer",
    AddTestConfig = "command-palette:add-component:itemConfigurator",
    AddConsole = "command-palette:add-component:console",
    AddTestBrowser = "command-palette:add-component:testBrowser",
    AddDashboard = "command-palette:add-component:dashboard",
    AddCacheViewer = "command-palette:add-component:cacheViewer",
    AddItemCreator = "command-palette:add-component:itemCreator",
    AddDependency = "command-palette:add-component:dependency",
}
export const defaultCommands = [
    {
        id: "addExplorer",
        event: CommandPaletteStateEvent.AddExplorer,
        label: "Add Catalog Explorer",
        icon: Icons.CatalogSimple,
        group: CommandPaletteGroup.Components,
    },
    {
        id: "addSource",
        event: CommandPaletteStateEvent.AddSource,
        label: "Add Catalog Dependencies Browser",
        icon: Icons.LinkColor,
        group: CommandPaletteGroup.Components,
    },

    {
        id: "addTestConfig",
        event: CommandPaletteStateEvent.AddTestConfig,
        label: "Add Test configurator",
        icon: Icons.StyleColorPalette,
        group: CommandPaletteGroup.Components,
    },
    {
        id: "addViewer",
        event: CommandPaletteStateEvent.AddViewer,
        label: "Add GLB Viewer",
        icon: Icons.DisplayARColor,
        group: CommandPaletteGroup.Components,
    },
    {
        id: "addConsole",
        event: CommandPaletteStateEvent.AddConsole,
        label: "Add Console",
        icon: Icons.ExtraCodes,
        group: CommandPaletteGroup.Components,
    },
    {
        id: "addTestBrowser",
        event: CommandPaletteStateEvent.AddTestBrowser,
        label: "Add Test Browser",
        icon: Icons.ListSearchColor,
        group: CommandPaletteGroup.Components,
    },
    {
        id: "addDashboard",
        event: CommandPaletteStateEvent.AddDashboard,
        label: "Add Dashboard",
        icon: Icons.BarChartColor,
        group: CommandPaletteGroup.Components,
    },
    {
        id: "addCacheViewer",
        event: CommandPaletteStateEvent.AddCacheViewer,
        label: "Add Cache Viewer",
        icon: Icons.DisplayEyeOpen,
        group: CommandPaletteGroup.Components,
    },
    {
        id: "addItemCreator",
        event: CommandPaletteStateEvent.AddItemCreator,
        label: "Add Item Creator",
        icon: Icons.FileColor,
        group: CommandPaletteGroup.Components,
    }
];
