import React from 'react'
import { IIconProps } from 'Interfaces'

export function MaterialsIcon(props: IIconProps) {
    return (
        <svg width={props.width ?? 16} height={props.height ?? 16} viewBox='0 0 16 16' fill='none'>
        <g clipPath="url(#clip0_28199_1232)">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.30633 8.47897L10.2436 9.17709C10.2399 9.28588 10.1953 9.40684 10.0875 9.55477C9.90998 9.79845 9.59453 10.0594 9.16464 10.3167C8.79309 10.539 8.37229 10.7374 7.95687 10.9059L7.96108 10.8859C8.0176 10.6133 8.05008 10.3393 8.07649 10.0915C8.08227 10.0372 8.08772 9.98468 8.09303 9.93356C8.11296 9.74158 8.13081 9.56956 8.15602 9.40312C8.2221 8.96664 8.31689 8.758 8.43781 8.64805C8.52792 8.56612 8.74255 8.44358 9.30633 8.47897ZM11.1496 10.308C11.436 9.91482 11.4938 9.47098 11.5329 8.90476C11.5465 8.70642 11.3405 8.58233 11.4407 8.40981C11.536 8.2455 11.9498 8.13907 11.9498 8.13907L16 0.701322L14.9075 0L9.76704 6.52594C9.76704 6.52594 9.80102 6.88175 9.71787 6.99764C9.60785 7.15095 9.24823 7.18459 9.24823 7.18459C8.54775 7.16292 7.97419 7.31461 7.55134 7.6991C7.07781 8.12968 6.935 8.73256 6.86233 9.21246C6.8322 9.41149 6.81042 9.62181 6.79019 9.81715C6.78528 9.86462 6.78045 9.91121 6.77562 9.95653C6.74984 10.1985 6.72258 10.4199 6.67958 10.6272C6.59367 11.0415 6.45796 11.3327 6.22272 11.5363C5.9945 11.7339 5.93408 12.0604 6.07683 12.3247C6.21958 12.5891 6.52796 12.7218 6.82145 12.6451C7.59974 12.4418 8.84363 12.0181 9.843 11.4201C10.3396 11.1229 10.8245 10.7543 11.1496 10.308Z" fill="currentColor"/>
        <path d="M3.51998 9C2.81306 9 2.23999 9.59695 2.23999 10.3333C2.23999 11.0697 2.81306 11.6667 3.51998 11.6667C4.2269 11.6667 4.79998 11.0697 4.79998 10.3333C4.79998 9.59695 4.2269 9 3.51998 9Z" fill="#6AAAEE"/>
        <path d="M3.51998 5C2.81306 5 2.23999 5.59695 2.23999 6.33333C2.23999 7.06971 2.81306 7.66667 3.51998 7.66667C4.2269 7.66667 4.79998 7.06971 4.79998 6.33333C4.79998 5.59695 4.2269 5 3.51998 5Z" fill="#C168FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7.90738 0C9.56156 0 11.2386 0.675604 12.625 1.66689L11.8099 2.70169C10.6178 1.87161 9.22182 1.33333 7.90738 1.33333C4.22785 1.33333 1.27999 4.33792 1.27999 8C1.27999 11.6621 4.22785 14.6667 7.90738 14.6667C8.54276 14.6667 8.79278 14.4511 8.93593 14.2103C9.11194 13.9144 9.19711 13.4585 9.22593 12.8269C9.67395 12.616 10.1098 12.3793 10.5192 12.1001C10.519 12.3217 10.5171 12.5474 10.5094 12.7684C10.4848 13.4748 10.4009 14.2788 10.024 14.9125C9.60453 15.6179 8.89761 16 7.90738 16C3.55959 16 0 12.4381 0 8C0 3.5619 3.55959 0 7.90738 0ZM15.045 4.15L14.3194 5.48244C14.385 5.61543 14.4429 5.74785 14.493 5.87909C14.7654 6.59334 14.7887 7.22716 14.5832 7.74556C14.4771 8.01329 14.3354 8.10496 14.1244 8.14865C13.8518 8.20507 13.4872 8.16823 12.9976 8.08442C12.9692 8.07956 12.9402 8.07451 12.9108 8.06931C12.6767 8.49917 12.2517 9.3056 12.2517 9.3056C12.2517 9.3056 12.4165 9.33434 12.5032 9.34976C12.5952 9.36613 12.6907 9.38311 12.79 9.40011C13.2639 9.48122 13.8445 9.56598 14.3738 9.45642C14.9647 9.33412 15.4818 8.97219 15.7663 8.25444C16.1346 7.32548 16.038 6.31894 15.6825 5.3868C15.522 4.96589 15.3057 4.55088 15.045 4.15ZM10.4084 3C10.7473 3 11.0514 3.15567 11.2582 3.40212L9.84613 5.19481C9.50776 4.99186 9.27996 4.61136 9.27996 4.17544C9.27996 3.52626 9.78516 3 10.4084 3Z" fill="currentColor"/>
        <path d="M6.07997 2.33333C5.37305 2.33333 4.79998 2.93029 4.79998 3.66667C4.79998 4.40305 5.37305 5 6.07997 5C6.78689 5 7.35996 4.40305 7.35996 3.66667C7.35996 2.93029 6.78689 2.33333 6.07997 2.33333Z" fill="#886AEE"/>
        <path d="M10.2436 9.17709L9.30633 8.47897C8.74255 8.44358 8.52792 8.56612 8.43781 8.64805C8.31689 8.758 8.2221 8.96664 8.15602 9.40312C8.13081 9.56956 8.11296 9.74158 8.09303 9.93356C8.08772 9.98468 8.08227 10.0372 8.07649 10.0915C8.05008 10.3393 8.0176 10.6133 7.96108 10.8859L7.95687 10.9059C8.37229 10.7374 8.79309 10.539 9.16464 10.3167C9.59453 10.0594 9.90998 9.79845 10.0875 9.55477C10.1953 9.40684 10.2399 9.28588 10.2436 9.17709Z" fill="#6DD8FF"/>
        </g>
        <defs>
        <clipPath id="clip0_28199_1232">
        <rect width="16" height="16" fill="white"/>
        </clipPath>
        </defs>
        </svg>
    )
}