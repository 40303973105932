import { Entity, EntityType } from "../..";
import { AuthoringStateMgr } from "./AuthoringStateMgr";

export class DependencyMgr {
    private authoringMgr: AuthoringStateMgr;

    constructor(authoringMgr: AuthoringStateMgr) {
        this.authoringMgr = authoringMgr;
    }

    public createDependency(
        catalogVersionId: string,
        refCode: string,
        dependency: IVersionDependencyDef,
        original?: Entity
    ) {
        const entityCode = `${catalogVersionId}-dependencies`;
        const currentState = this.authoringMgr.entities.getCurrentEntity(
            catalogVersionId!,
            EntityType.DEPENDENCIES,
            entityCode
        );

        this.authoringMgr.entities.setEntity(
            catalogVersionId!,
            EntityType.DEPENDENCIES,
            original ?? {
                code: entityCode,
            },
            !currentState
        );

        this.authoringMgr.entities.updateEntityProperty(
            catalogVersionId!,
            EntityType.DEPENDENCIES,
            ["entity", refCode],
            entityCode,
            dependency
        );

        this.updateCatalogState();
    }

    public updateDependency(
        catalogVersionId: string,
        refCode: string,
        dependency: IVersionDependencyDef
    ) {
        const entityCode = `${catalogVersionId}-dependencies`;
        const currentState = this.authoringMgr.entities.getCurrentEntity(
            catalogVersionId,
            EntityType.DEPENDENCIES,
            entityCode
        );

        if (!currentState) {
            this.authoringMgr.entities.setEntity(
                catalogVersionId,
                EntityType.DEPENDENCIES,
                {
                    code: entityCode,
                }
            );
        }

        this.authoringMgr.entities.updateEntityProperty(
            catalogVersionId,
            EntityType.DEPENDENCIES,
            ["entity", refCode],
            entityCode,
            dependency
        );

        this.updateCatalogState();
    }
    
    public removeDependency(
        catalogVersionId: string,
        refCode: string,
        original?: Entity
    ) {
        const entityCode = `${catalogVersionId}-dependencies`;
        const currentState = this.authoringMgr.entities.getCurrentEntity(catalogVersionId, EntityType.DEPENDENCIES, entityCode);
        
        if (!currentState) {
            this.authoringMgr.entities.setEntity(
                catalogVersionId,
                EntityType.DEPENDENCIES,
                original
            );
        }
        this.authoringMgr.entities.deleteEntityProperty(
            catalogVersionId,
            EntityType.DEPENDENCIES,
            ['entity', refCode!],
            entityCode
        );
        
        this.updateCatalogState();
    }

    public updateCatalogState() {
        this.authoringMgr.catalogs.updateCurrentCatalogState();
    }

    public async getCatalogDependencies(catalogVersionId: string) {
        const resp = await CiCAPI.authoring.getVersionDefById(catalogVersionId);
        if(resp.success && resp.result) {
            return resp.result.catalogDependencies;
        }
    }
}
