import React from 'react'
import { IIconProps } from 'Interfaces'

export function ArchitecturalIcon(props: IIconProps) {
    return (
        <svg width={props.width ?? 48} height={props.height ?? 48} viewBox='0 0 48 48' fill='none'>
            <path fillRule="evenodd" clipRule="evenodd" d="M4 1H44V8.15556C44 10.9783 41.7614 13.2667 39 13.2667H38V15.3111H43V17.3556H38V36.7778H41V40.8667H44V47H4V40.8667H7V36.7778H10V17.3556H5V15.3111H10V13.2667H9C6.23858 13.2667 4 10.9783 4 8.15556V1ZM12 13.2667V15.3111H36V13.2667H12ZM36 17.3556H33V36.7778H36V17.3556ZM31 17.3556H28V36.7778H31V17.3556ZM26 17.3556H22V36.7778H26V17.3556ZM20 17.3556H17V36.7778H20V17.3556ZM15 17.3556H12V36.7778H15V17.3556ZM9 40.8667H39V38.8222H9V40.8667ZM6 42.9111V44.9556H42V42.9111H6ZM6 3.04444V6.11111H42V3.04444H6ZM42 8.15556H6C6 9.84923 7.34315 11.2222 9 11.2222H39C40.6569 11.2222 42 9.84923 42 8.15556Z" fill="currentColor"/>
            <path d="M6 44.9556V42.9111H42V44.9556H6Z" fill="#CDE3F9"/>
            <path d="M12 15.3111V13.2667H36V15.3111H12Z" fill="#CDE3F9"/>
            <path d="M6 6.11111V3.04444H42V6.11111H6Z" fill="#CDE3F9"/>
        </svg>
    )
}