export class SearchResultFactory {
    static create(searchResult: ISearchResult | V2.ISearchResult): IConsolidatedSearchResult {
        return new SearchResult(searchResult).serialize();
    }
}

export class SearchResult {
    protected _total: number;
    protected _nbPerPage: number;
    protected _offset: number;
    protected _facets?: Array<IFacet>;
    protected _baseItemTypes?: Array<IFacetValue>;
    protected _catalogs?: Array<IFacetValue>;
    protected _companies?: Array<IFacetValue>;
    protected _dimensions?: Array<IFacet>;

    constructor(searchResult : ISearchResult | V2.ISearchResult) {
        this._total = searchResult.total;
        this._nbPerPage = searchResult.nbPerPage;
        this._offset = searchResult.offset;
        this._facets = searchResult.facets;
        this._baseItemTypes = searchResult.baseItemTypes;
        this._catalogs = searchResult.catalogs;
        this._companies = searchResult.companies;
        this._dimensions = searchResult.dimensions;
    }

    serialize(): IConsolidatedSearchResult {
        const searchResult: IConsolidatedSearchResult = {
            total: this._total,
            nbPerPage: this._nbPerPage,
            offset: this._offset
        };

        if (this._facets !== undefined) {
            searchResult.facets = this._facets;
        }

        if (this._baseItemTypes !== undefined) {
            searchResult.baseItemTypes = this._baseItemTypes;
        }

        if (this._catalogs !== undefined) {
            searchResult.catalogs = this._catalogs;
        }

        if (this._companies !== undefined) {
            searchResult.companies = this._companies;
        }

        if (this._dimensions !== undefined) {
            searchResult.dimensions = this._dimensions;
        }

        return searchResult;
    }
}

export interface IConsolidatedSearchResult {
    total: number;
    nbPerPage: number;
    offset: number;
    facets?: Array<IFacet>;
    baseItemTypes?: Array<IFacetValue>;
    catalogs?: Array<IFacetValue>;
    companies?: Array<IFacetValue>;
    dimensions?: Array<IFacet>;
}