import { ObjectUtils } from "@cic/utils";
import { authoringHubState, StateModule } from "./authoringHubState";

export type Config = Record<string, any>;
export type State = {
    config: Config;
    urlOverrides: Config;
};

export enum ConfigStateEvent {
    ConfigChanged = "ConfigChanged",
}

class ConfigState implements StateModule<State> {
    public state: State = {
        config: {},
        urlOverrides: {},
    };

    constructor() {
        this.state.urlOverrides = this._getUrlOverrides();
    }

    private _getUrlOverrides() {
        const urlOverrides: Config = {};
        const url = new URL(window.location.href);
        for (const [key, value] of url.searchParams.entries()) {
            const keys = key.split(".");
            let current = urlOverrides;
            for (let i = 0; i < keys.length - 1; i++) {
                const k = keys[i];
                if (!current[k]) {
                    current[k] = {};
                }
                current = current[k];
            }
            current[keys[keys.length - 1]] = value;
        }
        return urlOverrides;
    }

    getMergedConfig() {
        return ObjectUtils.deepMergeObjects(
            this.state.config,
            this.state.urlOverrides
        ) as Config;
    }

    serialize() {
        return this.state;
    }

    setConfig(config: Config) {
        this.state.config = config;
        authoringHubState.notifyChange({
            type: ConfigStateEvent.ConfigChanged,
            state: this.state,
        });
    }
}

export const configState = authoringHubState.registerModule(
    "config",
    new ConfigState()
) as ConfigState;
