import React from 'react'
import { IIconProps } from 'Interfaces'

export function AllCatalogIcon(props: IIconProps) {
    return (
<svg width="17" height="16" viewBox="0 0 17 16" fill="none">
<g clipPath="url(#clip0_1926_1716)">
<path d="M15.2692 0H11.5769C10.9 0 10.3462 0.553846 10.3462 1.23077H6.65385C6.65385 0.553846 6.1 0 5.42308 0H1.73077C1.05385 0 0.5 0.553846 0.5 1.23077V14.7692C0.5 15.4462 1.05385 16 1.73077 16H5.42308C5.66923 16 5.85385 15.9385 6.03846 15.8154C6.22308 15.9385 6.40769 16 6.65385 16H10.3462C10.5923 16 10.7769 15.9385 10.9615 15.8154C11.1462 15.9385 11.3308 16 11.5769 16H15.2692C15.9462 16 16.5 15.4462 16.5 14.7692V1.23077C16.5 0.553846 15.9462 0 15.2692 0ZM1.73077 14.7692V1.23077H5.42308V14.7692H1.73077ZM6.65385 14.7692V2.46154H10.3462V14.7692H6.65385ZM11.5769 14.7692V1.23077H15.2692V14.7692H11.5769Z" fill="currentColor"/>
<path d="M14.0385 2.46154H12.8077V3.69231H14.0385V2.46154Z" fill="#3DCBFF"/>
<path d="M9.11539 3.69231H7.88462V4.92308H9.11539V3.69231Z" fill="#3DCBFF"/>
<path d="M4.19231 2.46154H2.96154V3.69231H4.19231V2.46154Z" fill="#3DCBFF"/>
</g>
<defs>
<clipPath id="clip0_1926_1716">
<rect width="16" height="16" fill="white" transform="translate(0.5)"/>
</clipPath>
</defs>
</svg>
    )
}