import { Entity } from './EntityIOService';

enum EntityType {
    ITEMGROUPS = 'ItemGroups',
    MATERIALS = 'Materials',
    ITEMS = 'Items',
    ITEM_REFS = 'ItemRefs',
    FEATURES = 'Features',
    FEATURE_REFS = 'FeatureRefs',
    RESTRICTIONS = 'Restrictions',
    ATTRIBUTES = 'Attributes',
    DEPENDENCIES = 'Dependencies'
}

export class LiveEditService {
    static async updateEntity(
        catalogVersionId: string,
        type: EntityType,
        entity: Entity
    ) {
        const entityId = `${catalogVersionId}.${entity.code}`;

        switch (type) {
            case EntityType.ITEMS:
            case EntityType.ITEM_REFS:
                await CiCAPI.authoring.updateCatalogItemDef(entityId, entity);
                break;
            case EntityType.FEATURES:
            case EntityType.FEATURE_REFS:
                await CiCAPI.authoring.updateCatalogFeatureDef(
                    entityId,
                    entity
                );
                break;
            case EntityType.RESTRICTIONS:
                await CiCAPI.authoring.updateCatalogRestrictionDef(
                    entityId,
                    entity
                );
                break;
            case EntityType.MATERIALS:
                await CiCAPI.authoring.updateMaterialDef(entityId, entity);
                break;
            case EntityType.ATTRIBUTES:
                await CiCAPI.authoring.updateCatalogAttributeDef(
                    catalogVersionId,
                    entity
                );
                break;
            case EntityType.DEPENDENCIES:
                await CiCAPI.authoring.updateCatalogDependenciesDef(
                    catalogVersionId,
                    entity.entity
                );
                break;
        }
    }

    static updateEntities(
        catalogVersionId: string,
        type: EntityType,
        entities: Entity[]
    ) {
        return Promise.all(
            entities.map((entity) =>
                this.updateEntity(catalogVersionId, type, entity)
            )
        );
    }
}
