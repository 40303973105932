export class GeometriesInfoFactory {
    static create(geometriesInfo: V2.IGeometriesInfo): IConsolidatedGeometriesInfo {
        return new GeometriesInfo(geometriesInfo).serialize();
    }
}   
    
export class GeometriesInfo {
    protected _geometryTypes: Array<GeometryType>;
    protected _hasGeometry?: boolean;
    protected _originConvention?: IGeometryOriginConvention;
    protected _collisionBox?: ICollisionBox;
    protected _geometries?: EmbeddedGeometries;
    protected _automations?: Record<string, unknown>;
    protected _overridableMaterials?: boolean;

    constructor(geometriesInfo: V2.IGeometriesInfo) {
        this._geometryTypes = geometriesInfo.geometryTypes;
        this._hasGeometry = geometriesInfo.hasGeometry;
        this._originConvention = geometriesInfo.originConvention;
        this._collisionBox = geometriesInfo.collisionBox;
        this._geometries = geometriesInfo.geometries;
        this._automations = geometriesInfo.automations;
        this._overridableMaterials = geometriesInfo.overridableMaterials;
    }

    serialize(): IConsolidatedGeometriesInfo {
        const geometriesInfo: IConsolidatedGeometriesInfo = {
            geometryTypes: this._geometryTypes,
        };

        if (this._hasGeometry !== undefined) {
            geometriesInfo.hasGeometry = this._hasGeometry;
        }

        if (this._originConvention !== undefined) {
            geometriesInfo.originConvention = this._originConvention;
        }

        if (this._collisionBox !== undefined) {
            geometriesInfo.collisionBox = this._collisionBox;
        }

        if (this._geometries !== undefined) {
            geometriesInfo.geometries = this._geometries;
        }

        if (this._automations !== undefined) {
            geometriesInfo.automations = this._automations;
        }

        if (this._overridableMaterials !== undefined) {
            geometriesInfo.overridableMaterials = this._overridableMaterials;
        }

        return geometriesInfo;
    }
}

export interface IConsolidatedGeometriesInfo {
    geometryTypes: Array<GeometryType>;
    hasGeometry?: boolean;
    originConvention?: IGeometryOriginConvention;
    collisionBox?: ICollisionBox;
    geometries?: EmbeddedGeometries;
    automations?: Record<string, unknown>;
    overridableMaterials?: boolean;
}