import React, { Fragment, useRef, MutableRefObject } from 'react';
import './actionButton.scss'

type ActionButtonProps = {
    label: string,
    disabled?: boolean,
    isSelected?: boolean,
    size?: 'mini' | 'small' | 'medium' | 'large',
    variant?: 'primary' | 'secondary' | 'tertiary',
    width?: string,
    tabIndex?: number,
    targetClassName?: string,
    onClick(): void,
    onMouseEnter?(buttonRef: MutableRefObject<HTMLButtonElement | null>): void,
}

export function ActionButton(props: ActionButtonProps) {   
    const targetElementRef = useRef<HTMLButtonElement | null>(null),
        variant: string = props.variant || 'primary',
        size: string = props.size || 'small',
        variantClassName = `simple-button__${variant}`,
        sizeClassName = `simple-button__${size}`, 
        targetClassName = props.targetClassName ? ` ${props.targetClassName}` : '',
        widthStyle = props.width ? {width: props.width} : {}, 

        handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            event.preventDefault();
            props.onClick();           
        },
        handleMouseEnter = () => {            
            if (props.onMouseEnter) {
                props.onMouseEnter(targetElementRef);
            }
        };        

    return (
        <Fragment>
            <button 
                className={`simple-button__container ${variantClassName} ${sizeClassName} ${targetClassName}`}
                style={widthStyle}
                disabled={props.disabled}
                tabIndex={props.tabIndex}
                ref={targetElementRef}
                onClick={handleClick}
                onMouseEnter={handleMouseEnter}
            >
                {props.label}
            </button>
        </Fragment>        
    )
}