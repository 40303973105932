import { authoringHubState, StateModule } from "./authoringHubState";
import { tabState } from "./tabState";

export type ExplorerTabState = {
    id: string;
    path: string[];
    prevPaths: string[][];
    nextPaths: string[][];
};

export type State = Record<
    string,
    {
        tabs: Record<string, ExplorerTabState>;
    }
>;

export enum ExplorerStateEvent {
    PathChanged = "explorerstate-path-changed",
}

class ExplorerState implements StateModule<State> {
    public state: State = {};

    serialize() {
        return this.state;
    }

    setExplorerId(explorerId: string) {
        if (!this.state[explorerId]) {
            this.state[explorerId] = { tabs: {} };
        }
    }

    setPath(
        explorerId: string,
        tabId: string,
        path: string[],
        incognito = false,
        label?: string        
    ) {
        this.setExplorerId(explorerId);

        const state = this.state[explorerId];
        if (!state.tabs[tabId]) {
            state.tabs[tabId] = {
                id: tabId,
                path,
                prevPaths: [],
                nextPaths: [],
            };
        } else if (!incognito) {
            state.tabs[tabId].prevPaths.push(state.tabs[tabId].path);
        }

        state.tabs[tabId].path = path;

        tabState.updateTab(explorerId, tabId, { label: label || path.at(-1) });
        authoringHubState.notifyChange({
            type: ExplorerStateEvent.PathChanged,
            state: this.state,
        });
    }

    setPreviousPath(explorerId: string, tabId: string) {
        const state = this.state[explorerId].tabs[tabId];
        if (state) {
            state.nextPaths.push(state.path);
            this.setPath(explorerId, tabId, state.prevPaths.pop() || [], true);
        }
    }

    setNextPath(explorerId: string, tabId: string) {
        const state = this.state[explorerId].tabs[tabId];
        if (state) {
            state.prevPaths.push(state.path);
            this.setPath(explorerId, tabId, state.nextPaths.pop() || [], true);
        }
    }
}

export const explorerState = authoringHubState.registerModule(
    "explorer",
    new ExplorerState(),
) as ExplorerState;
