export interface StateModule<T> {
    state: T;
    serialize(): Record<string, any>;
}

class AuthoringHubState {
    public modules: Record<string, StateModule<unknown>> = {};

    public registerModule(name: string, module: StateModule<unknown>) {
        if (!this.modules[name]) {
            this.modules[name] = module;
        }

        return this.modules[name];
    }

    public notifyChange(
        options?: {
            type?: string;
            state?: Record<string, any>;
        } & Record<string, any>
    ) {
        const {
            type = "authoringhubstate-changed",
            state,
            ...rest
        } = options || {};
        window.postMessage({ type, state, ...rest }, "*");
    }
}

//@ts-ignore
window._cynclyAuthoringHubState =
    //@ts-ignore
    window._cynclyAuthoringHubState || new AuthoringHubState();
export const authoringHubState: AuthoringHubState =
    //@ts-ignore
    window._cynclyAuthoringHubState;
