import { SchemaPath, ValidationExtraData, ValidationResponse, ValidationRule } from './SchemaValidationTypes';
import config, { ValidationRules } from './config';
import * as Rules from './rules';
import { IEntitySchema } from '../SchemaEntityUtils';

export { type ValidationError } from './SchemaValidationTypes';

export class SchemaValidationService {
    private config: { rules: { path: RegExp, rule: ValidationRules }[] }

    constructor() {
        this.config = {
            ...config,
            rules: config.rules.map(rule => {
                return {
                    ...rule,
                    path: new RegExp(rule.path.replace(/\//g, "\/"))
                }
            })
        }
    }

    async validate(
        schema: IEntitySchema,
        path: SchemaPath,
        value: unknown,
        extraData?: ValidationExtraData
    ): Promise<ValidationResponse> {
        if (!schema || !path) return { success: true };

        const rule = this.getRule(path);
        return rule.exec(value, schema, path, extraData);
    }

    private getRule(fullPath: SchemaPath): ValidationRule {
        const pathToTest = fullPath.join('/');

        for (let i = 0; i < this.config.rules.length; i++) {
            const { path, rule } = this.config.rules[i];
            if (path.test(pathToTest)) {
                return this.getRuleInstance(rule);
            }
        }

        return this.getRuleInstance();
    }

    private getRuleInstance(rule?: ValidationRules) {
        switch (rule) {
            case ValidationRules.ATTRIBUTE:
                return new Rules.AttributeRefValidationRule();
            case ValidationRules.FEATURE_REF:
                return new Rules.FeatureRefValidationRule();
            case ValidationRules.POS_ORI_SCALE:
                return new Rules.PosOriScaleValidationRule();
            case ValidationRules.MCODE:
                return new Rules.MCodeValidationRule();
            case ValidationRules.ITEM_REF:
                return new Rules.ItemRefValidationRule();
            case ValidationRules.MATERIAL_REF:
                return new Rules.MaterialRefValidationRule();
            case ValidationRules.TYPE:
            default:
                return new Rules.TypeValidationRule();
        }
    }
}

export const schemaValidationService = new SchemaValidationService();
