import React from 'react';
import'./headerNavigation.scss';

type HeaderNavigationProps = {
    theme: string;
    baseUrl: string,
}

export function HeaderNavigation(props: HeaderNavigationProps) {
    let localOrigin: boolean = window.location.origin === 'http://localhost:2023',
        imagePath: string = localOrigin ? window.location.origin + window.location.pathname : '.';

    return (
        <div className={`header-navigation__container  `}>
            <div className='header-navigation__logo'>
                    
                {props.theme?.trim() === 'cyncly-theme' &&                
                    <img alt='CiC' src={`${props.baseUrl}/assets/images/CiC-logo.png`}/>                     
                }
            </div>                    
        </div>        
    )
}