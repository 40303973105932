import { AuthoringGroupsStateEvent, StateEntity, StateSubgroups, States } from "./authoringGroupsState.type";
import { GroupEntity } from "./groupEntity";

export abstract class StatesManagementBase {

    _states: States = {
        _original: { },
        _current: { },
        _deleted: { }
    }

    _load = (id: string, groupType: string, entities: any[]) => {

        if (id in this._states._original) delete this._states._original[id];
        if (id in this._states._current) delete this._states._current[id];
        if (id in this._states._deleted) delete this._states._deleted[id];

        this._states._current[id] = { };
        this._states._current[id][groupType] = {
            index: { },
            groups: { }
        };

        this._states._original[id] = { };
        this._states._original[id][groupType] = {
            index: { },
            groups: { }
        };
        
        this._states._deleted[id] = { };
        this._states._deleted[id][groupType] = { };

        entities.forEach((e) => {
            const group = this._createGroup(e, false);
            this._indexAdd(group, this._states._original[id][groupType].index);
            this._states._original[id][groupType].groups[e.code] = group;
        });

        entities.forEach((e) => {
            const group = this._createGroup(e, false);
            this._indexAdd(group, this._states._current[id][groupType].index);
            this._states._current[id][groupType].groups[e.code] = group;
        });

    }

    _copyEntity = (entity: any) => {
        let copy = JSON.parse(JSON.stringify(entity));
        delete copy.groups;
        return copy;
    }

    _indexAdd = (group: StateEntity, index: Record<string, StateEntity>) => {
        if (!index[group.entity.code]) {
            index[group.entity.code] = group;
        }
        for (const subgroup in group.subgroups) {
            this._indexAdd(group.subgroups[subgroup], index)
        }
    }

    _indexDelete = (group: StateEntity, index: Record<string, StateEntity>) => {
        if (index[group.entity.code]) {
            delete index[group.entity.code];
            for (const subgroup in group.subgroups) {
                this._indexDelete(group.subgroups[subgroup], index)
            }
        }
    }

    _createGroups = (entities: any[], isNew: boolean, parent?: StateEntity) => {
        let state: StateSubgroups = { };
        entities.forEach((e) => {
            state[e.code] = this._createGroup(e, isNew, parent);
        });
        return state;
    }

    _createGroup = (entity: any, isNew: boolean, parent?: StateEntity) => {
        let copy = this._copyEntity(entity);
        let state: StateEntity = {
            entity: copy,
            parent: parent,
            isNew: isNew,
            level: parent ? parent.level + 1 : 0
        };
        if (entity.groups && entity.groups.length > 0) {
            state.subgroups = this._createGroups(entity.groups, isNew, state);
        }
        return state;
    }

    _findGroup = (code: string, index: Record<string, StateEntity>): StateEntity | undefined => {
        if (code in index) {
            return index[code];
        }
    }

    _getStructure = (group: StateEntity) => {
        let structure: StateEntity[] = [];
        structure.push(group);
        if (group.subgroups) {
            for (const sub in group.subgroups) {
                const result = this._getStructure(group.subgroups[sub]);
                structure = [...structure, ...result];
            }
        }
        return structure;
    }

    _isChild = (target: string, source: StateEntity): boolean => {
        if (target === source.entity.code) {
            return true;
        }
        if (source.subgroups) {
            for (const groupCode in source.subgroups) {
                const isChild = this._isChild(target, source.subgroups[groupCode]);
                if (isChild) {
                    return true;
                }
            }
        }
        return false;
    }

    _isDeletedFromCurrent = (id: string, groupType: string, code: string) => {
        for (const group in this._states._current[id][groupType].groups) {
            const isChild = this._isChild(code, this._states._current[id][groupType].groups[group]);
            if (isChild) {
                return false;
            }
        }
        return true;
    }

    _getOriginalStructure = (id: string, groupType: string) => {
        let structure: StateEntity[] = [];
        for (const group in this._states._original[id][groupType].groups) {
            structure = [...structure, ...this._getStructure(this._states._original[id][groupType].groups[group])];
        }
        structure = structure?.sort((a, b) => (a.level > b.level) ? -1 : 1);
        return structure;
    }

    _getCurrentStructure = (id: string, groupType: string) => {
        let structure: StateEntity[] = [];
        for (const group in this._states._current[id][groupType].groups) {
            structure = [...structure, ...this._getStructure(this._states._current[id][groupType].groups[group])];
        }
        structure = structure?.sort((a, b) => (a.level > b.level) ? -1 : 1);
        return structure;
    }

    _hasSubgroupMoved = (group: StateEntity, movedList: any[]) => {
        if (group.subgroups) {
            for (const sub in group.subgroups) {
                if (movedList.some((e) => e.code === group.subgroups![sub].entity.code)) {
                    return true;
                }
            }
        }
        return false;
    }

    _groupAsEntity(id: string, group: StateEntity) {   
        return new GroupEntity(id, group);       
    }

    _notifyChange(type?: string, code?: string) {     
        window.postMessage({
            type: type ?? AuthoringGroupsStateEvent.StateChanged,
            stateGroups: this._states,
            code: code
        }, "*");        
    }

}