const APP_CONFIG_NAME = 'appconfig.json';

export interface IAppConfig {
    cicapi_url: string,
    build_version: string
}

export async function loadAppConfig (baseUrl?: string): Promise<IAppConfig> {
    let config: IAppConfig = {
        cicapi_url: '',
        build_version: ''
    };

    try {
        let response: Response = await fetch(baseUrl ? baseUrl + APP_CONFIG_NAME : APP_CONFIG_NAME);
        if (response.status < 400) {
            config = await response.json();
        } else {
            console.log('[+] no Config Override present, continuing load...');
        }
    } catch (e) {
        console.error(e);
        console.log(`[!] crash occured while trying to fetch ${APP_CONFIG_NAME}, continuing load...`);
    }

    return config;    
}
