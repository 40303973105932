import React, { useContext, useRef, useEffect, useState } from 'react';
import { CatalogMenuItem } from 'Interfaces';
import { ContextualHelpContext } from 'contextualHelp/contextualHelpContext';
import { ContextualHelpStepsIndex } from 'contextualHelp/ContextualHelpSteps';
import './navigationItem.scss';

type MenuItemProps = {
    menu: CatalogMenuItem,
    isActive?: boolean,        
    tabIndex: number,  
    contextualHelpEnabled: boolean,
    onClick(menu: CatalogMenuItem): void
};

export function NavigationItem(props: MenuItemProps) {
    const { menu, isActive, tabIndex, contextualHelpEnabled, onClick } = props;
    const activeClassName = isActive ? 'is-active' : '';       
    const { showContextualHelp } = useContext(ContextualHelpContext);
    
    const ctxlHelpPath: { [key: string]: { path: string, index?: number } } = {
        all: { path: 'step-catalog-all' },
        kitchen: { path: 'step-catalog-cabinetry'},
        bathroom: { path: 'step-catalog-sink'},
        bedroom: { path: 'step-catalog-furniture'},
        appliances: { path: 'step-catalog-appliances'},
        decorate: { path: 'step-catalog-decorate'},
        storage: { path: 'step-catalog-storage'},
        lighting: { path: 'step-catalog-lighting'},
        office: { path: 'step-catalog-office'},
        outdoor: { path: 'step-catalog-outdoor'},
        architectural: { path: 'step-catalog-architectural'},
        materials: { path: 'step-catalog-materials'},

    };
    const targetElementRef = useRef<HTMLButtonElement | null>(null);
    const [ stepClassName, setStepClassName] = useState(ctxlHelpPath[props.menu.path]?.path || '');

    const handleClick = () => {
        onClick(props.menu);
    };

    

    const handleOnMouseEnter = () => {
        if (contextualHelpEnabled === false) return;
        
        if (menu.path === 'all') {
            showContextualHelp(ContextualHelpStepsIndex.CATALOG_ALL, targetElementRef);
        } else if (menu.path === 'kitchen') {
            showContextualHelp(ContextualHelpStepsIndex.CATALOG_CABINETRY, targetElementRef);            
        } else if (menu.path === 'bathroom') {
            showContextualHelp(ContextualHelpStepsIndex.CATALOG_SINKS, targetElementRef);            
        } else if (menu.path === 'bedroom') {
            showContextualHelp(ContextualHelpStepsIndex.CATALOG_FURNITURE, targetElementRef);
        } else if (menu.path === 'appliances') {
            showContextualHelp(ContextualHelpStepsIndex.CATALOG_APPLIANCE, targetElementRef);            
        } else if (menu.path === 'decorate') {
            showContextualHelp(ContextualHelpStepsIndex.CATALOG_DECORATE, targetElementRef);            
        } else if (menu.path === 'storage') {
            showContextualHelp(ContextualHelpStepsIndex.CATALOG_STORAGE, targetElementRef);
        } else if (menu.path === 'lighting') {
            showContextualHelp(ContextualHelpStepsIndex.CATALOG_LIGHTING, targetElementRef);            
        } else if (menu.path === 'office') {
            showContextualHelp(ContextualHelpStepsIndex.CATALOG_OFFICE, targetElementRef);            
        } else if (menu.path === 'outdoor') {
            showContextualHelp(ContextualHelpStepsIndex.CATALOG_OUTDOOR, targetElementRef);
        } else if (menu.path === 'architectural') {
            showContextualHelp(ContextualHelpStepsIndex.CATALOG_ARCHITECTURAL, targetElementRef);            
        } else if (menu.path === 'materials') {
            showContextualHelp(ContextualHelpStepsIndex.CATALOG_MATERIALS, targetElementRef);
        }
    };

    useEffect(() => {
        setStepClassName(ctxlHelpPath[menu.path]?.path || '');
    }, [props.menu.path]);    

    return (
        <div className={`navigation-item__container ${activeClassName} ${stepClassName}  `}>
            <div className={`navigation-item__active-line`} />
            <button className={`navigation-item__icon-and-label`}
                tabIndex={tabIndex}
                onClick={handleClick}
                onMouseEnter={handleOnMouseEnter}
                ref={targetElementRef}
            >                
                <div className='navigation-item__icon'>
                    {menu.icon}
                </div>                    
                <div className='navigation-item__name'>
                    {menu.name}
                </div>                
            </button>
        </div>
    );
}