import React from 'react'
import { IIconProps } from 'Interfaces'

export function DeleteIcon(props: IIconProps) {
    return (
        <svg width={props.width ?? 16} height={props.height ?? 16} viewBox="0 0 16 16" fill="none">
            <g clipPath="url(#clip0_15791_5653)">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.33333 8C1.33333 4.3181 4.3181 1.33333 8 1.33333C11.6819 1.33333 14.6667 4.3181 14.6667 8C14.6667 11.6819 11.6819 14.6667 8 14.6667C4.3181 14.6667 1.33333 11.6819 1.33333 8ZM8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM4.19526 5.13807L7.05719 8L4.19526 10.8619L5.13807 11.8047L8 8.94281L10.8619 11.8047L11.8047 10.8619L8.94281 8L11.8047 5.13807L10.8619 4.19526L8 7.05719L5.13807 4.19526L4.19526 5.13807Z" fill="currentColor"/>
            </g>
            <defs>
            <clipPath id="clip0_15791_5653">
            <rect width="16" height="16" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    )
}