
export type IPill = {
    name: string;
    selectedOptions: IPillFilterOption[] | undefined;
    selectedValue: string | undefined;
    type: IPillType;
    contentType: IPillContentType;
    isOpen: boolean;    
    level: number;  
    originalData?: any;      
};

export type IPillFilterCategory = {
    name: string;
    code: string;
    level: number;
    type: IPillType;
    contentType: IPillContentType;
    values?: IPillFilterOption[];
    value?: string;
};

export type IPillFilterOption = {
    name: string;
    code: string;
    count: number;
    range?: IDimensionRanges
}; 

export enum IPillType {
    singleSelect = 'singleSelect',
    multiSelect = 'multiSelect',
    dimensions = 'dimensions',
    dimension = 'dimension',
    inputValue = 'inputValue'
};

export enum IPillContentType {
    baseItemType = 'baseItemType',
    characteristic = 'characteristic',
    dimensions = 'dimensions',
    dimension = 'dimension',
    inputValue = 'inputValue'
};