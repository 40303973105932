import { EntityType } from '../../';
import { AuthoringStateMgr, State } from './AuthoringStateMgr';
import { Entity } from './AuthoringStateMgr.types';

export class SchemaMgr {
    private authoringMgr: AuthoringStateMgr;
    private state: State;
    private schemas: State['schemas'];

    constructor(authoringMgr: AuthoringStateMgr) {
        this.authoringMgr = authoringMgr;
        this.state = this.authoringMgr.state;
        this.schemas = this.state.schemas;
    }

    public async fetchSchema(type: EntityType, entity?: Entity) {
        const entityType = type === EntityType.ITEMGROUPS ? "Groups" : type;
        let schema: ISchemaDef | undefined = this.schemas[entityType];

        if (!schema) {
            schema = (await CiCAPI.authoring.getEntitySchemaDef(entityType))
                .result?.items;
            if (schema) {
                this.schemas[entityType] = schema;
                this.authoringMgr.notifyChange();
            }
        }

        return this.getSchema(type, entity);
    }

    public getSchema(type: EntityType, entity?: Entity) {
        const entityType = type === EntityType.ITEMGROUPS ? "Groups" : type;
        let schema = this.schemas[entityType];

        if (entity && schema) {
            if (
                entityType === EntityType.ITEMS ||
                entityType === EntityType.FEATURES
            ) {
                //@ts-ignore
                if (entity.itemRef || entity.featureRef) {
                    schema = schema.anyOf![1]!;
                } else {
                    schema = schema.anyOf![0]!;
                }
            }
        }

        return schema;
    }
}
