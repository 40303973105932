export default class CiCUtils {
    static async getItemCollisionBox(itemId: string, configurationState?: IConfigurationState, measurementUnit?: MeasurementUnit, precision?: number): Promise<ICollisionBox> {
        let collisionBox: ICollisionBox | undefined,
            variantResponse: IAPIResponse<V2.IItemVariant>,
            itemVariant: V2.IItemVariant;

        variantResponse = await CiCAPI.content.v2.getItemVariant({ itemId, configurationState }, {
            computeDefaultCollisionBox: true,
            collisionBoxMeasurementUnit: measurementUnit
        });

        if (variantResponse.success && variantResponse.result) {
            itemVariant = variantResponse.result;
            collisionBox = itemVariant.geometriesInfo.collisionBox!;
        } else {
            throw (new Error("Unable to retrieve item : " + itemId + " reason: " + variantResponse.message));
        }

        return collisionBox;
    }
};