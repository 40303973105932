// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.94.0_@swc+core@1.7.21_webpack-cli@4.10.0_/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.94.0_@swc+core@1.7.21_webpack-cli@4.10.0_/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.94.0_@swc+core@1.7.21_webpack-cli@4.10.0_/node_modules/css-loader/dist/cjs.js!./fonts.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "iframe{border:none}html,body{margin:0;padding:0;height:100%;width:100%;font-family:\"FoundersGrotesk\",\"Segoe UI\",Tahoma,Geneva,Verdana,sans-serif}p,h1,h2,h3,h4,h5,h6,ul,ol,li,div,button,fieldset,label,dl,dt,dd{margin:0;padding:0;font-weight:normal;font-family:\"FoundersGrotesk\",\"Segoe UI\",Tahoma,Geneva,Verdana,sans-serif}body{background:#fff;min-width:210px;overflow:hidden}#root{height:100%;display:flex;flex-flow:column;width:100%;justify-content:space-between;align-items:stretch}", "",{"version":3,"sources":["webpack://./src/components/catalogSettingsApp/catalogSettingsApp.scss"],"names":[],"mappings":"AAEA,OACI,WAAA,CAGJ,UACI,QAAA,CACA,SAAA,CACA,WAAA,CACA,UAAA,CACA,yEAAA,CAGJ,gEACI,QAAA,CACA,SAAA,CACA,kBAAA,CACA,yEAAA,CAGJ,KACI,eAAA,CACA,eAAA,CACA,eAAA,CAGJ,MACI,WAAA,CACA,YAAA,CACA,gBAAA,CACA,UAAA,CACA,6BAAA,CACA,mBAAA","sourcesContent":["@import './fonts.css';\n\niframe {\n    border: none;\n}\n\nhtml, body {\n    margin: 0;\n    padding: 0;\n    height: 100%;\n    width: 100%; \n    font-family: \"FoundersGrotesk\", \"Segoe UI\", Tahoma, Geneva, Verdana, sans-serif;\n}\n\np, h1, h2, h3, h4, h5, h6, ul, ol, li, div, button, fieldset, label, dl, dt, dd {\n    margin: 0;\n    padding: 0;\n    font-weight: normal;\n    font-family: \"FoundersGrotesk\", \"Segoe UI\", Tahoma, Geneva, Verdana, sans-serif;\n}\n\nbody {\n    background: white;\n    min-width: 210px;\n    overflow: hidden;\n}\n\n#root {\n    height: 100%;\n    display: flex;\n    flex-flow: column;\n    width: 100%;\n    justify-content: space-between;\n    align-items: stretch;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
