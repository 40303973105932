import React from 'react'
import { IIconProps } from 'Interfaces'

export function StorageIcon(props: IIconProps) {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M1.83333 0H0.5V16H1.83333V14.6727H15.1667V16H16.5V0H15.1667V5.85009H14.3289V0.669596H2.83333V5.85009H1.83333V0ZM12.9956 2.00293V5.85009H9.2478V2.00293H10.2028V3.00651H12.2028V2.00293H12.9956ZM7.91447 2.00293V5.85009H4.16667V2.00293H5.16667V3.00651H7.16667V2.00293H7.91447ZM2.83333 13.3394H1.83333V7.18342H15.1667V13.3394H14.3289V8.35384H2.83333V13.3394ZM4.16667 13.3394H7.91447V9.68717H7.16667V10.668H5.16667V9.68717H4.16667V13.3394ZM9.2478 13.3394H12.9956V9.68717H12.2028V10.668H10.2028V9.68717H9.2478V13.3394Z" fill="currentColor"/>
        <path d="M12.9956 5.85009V2.00293H12.2028V3.00651H10.2028V2.00293H9.2478V5.85009H12.9956Z" fill="#CDE3F9"/>
        <path d="M7.91447 5.85009V2.00293H7.16667V3.00651H5.16667V2.00293H4.16667V5.85009H7.91447Z" fill="#CDE3F9"/>
        <path d="M4.16667 13.3394H7.91447V9.68717H7.16667V10.668H5.16667V9.68717H4.16667V13.3394Z" fill="#CDE3F9"/>
        <path d="M9.2478 13.3394H12.9956V9.68717H12.2028V10.668H10.2028V9.68717H9.2478V13.3394Z" fill="#CDE3F9"/>
        </svg>
    )
}