import { Feature, IConsolidatedFeature } from "./Feature";
import { GroupBase, IConsolidatedGroupBase } from "./GroupBase";

export class FeatureGroupFactory {
    static create(featureGroup: IFeatureGroup | V2.IFeatureGroup): IConsolidatedGroupBase {
        return new FeatureGroup(featureGroup).serialize();
    }
}

export class FeatureGroup extends GroupBase {
    protected _features?: Array<Feature>;
    protected _featureGroups?: Array<FeatureGroup>;

    constructor(featureGroup: IFeatureGroup | V2.IFeatureGroup) {
        super(featureGroup);

        this._features = featureGroup.features?.map((feature: IFeature | V2.IFeature) => new Feature(feature));
        this._featureGroups = featureGroup.featureGroups?.map((featureGroup: IFeatureGroup | V2.IFeatureGroup) => new FeatureGroup(featureGroup));
    }

    get features(): Array<Feature> | undefined {
        return this._features;
    }

    get featureGroups(): Array<FeatureGroup> | undefined {
        return this._featureGroups;
    }

    serialize(): IConsolidatedFeatureGroup {
        const featureGroup: IConsolidatedFeatureGroup = {
            ...super.serialize()
        };

        if (this._features !== undefined) {
            featureGroup.features = this._features.map((feature: Feature) => feature.serialize());
        }

        if (this._featureGroups !== undefined) {
            featureGroup.featureGroups = this._featureGroups.map((featureGroup: FeatureGroup) => featureGroup.serialize());
        }

        return featureGroup;
    }
}

export interface IConsolidatedFeatureGroup extends IConsolidatedGroupBase {
    features?: Array<IConsolidatedFeature>;
    featureGroups?: Array<IConsolidatedFeatureGroup>;
}