export class ItemClassificationFactory {
    static create(classification: IClassification): IConsolidatedItemClassification {
        return new ItemClassification(classification).serialize();
    }
}
    
export class ItemClassification {
    protected _baseItemType: string;
    protected _characteristics?: ICharacteristics;
    protected _fullItemType?: string;
    protected _tags?: Array<string>;

    constructor(classification: IClassification) {
        this._baseItemType = classification.baseItemType;
        this._characteristics = classification.characteristics;
        this._fullItemType = classification.fullItemType;
        this._tags = classification.tags;
    }

    serialize(): IConsolidatedItemClassification {
        const classification: IConsolidatedItemClassification = {
            baseItemType: this._baseItemType,
        };

        if (this._characteristics !== undefined) {
            classification.characteristics = this._characteristics;
        }

        if (this._fullItemType !== undefined) {
            classification.fullItemType = this._fullItemType;
        }

        if (this._tags !== undefined) {
            classification.tags = this._tags;
        }

        return classification;
    }
}

export interface IConsolidatedItemClassification {
    baseItemType: string;
    characteristics?: ICharacteristics;
    fullItemType?: string;
    tags?: Array<string>;
}