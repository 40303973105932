export const generateTimeSince = (
    date: string,
    locale: string
) => {
    const now = new Date();
    const then = new Date(date);

    const diff = Math.abs(now.getTime() - then.getTime());
    const minutes = Math.floor(diff / 60000);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);

    const rtf = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' });


    if (months > 0) {
        return rtf.format(-months, 'month');
    }

    if (weeks > 0) {
        return rtf.format(-weeks, 'week');
    }

    if (days > 0) {
        return rtf.format(-days, 'day');
    }

    if (hours > 0) {
        return rtf.format(-hours, 'hour');
    }

    return rtf.format(-minutes, 'minute');
}

export function areAllValuesNullOrUndefined(obj: any): boolean {
    if (obj === null || obj === undefined) {
        return true;
    }

    if (typeof obj === "object") {
        for (const key in obj) {
            if (!areAllValuesNullOrUndefined(obj[key])) {
                return false;
            }
        }
        return true;
    }

    if (typeof obj === "string" && obj.trim() === "") {
        return true;
    }

    return false;
}
