import { DEFAULT_PRECISION, NumberUtils } from "./NumberUtils";
import { MeasurementUtils } from "./MeasurementUtils";
import { quat, vec3, vec2, mat3, mat4 } from "gl-matrix";

// ----------------------------------------------------------------------------
export const VEC3_ZERO: vec3 = vec3.create();
export const VEC2_ZERO: vec2 = vec2.create();
export const VEC2_ONE: vec2 = vec2.fromValues(1, 1);

type EulerOrder = "XYZ" | "YXZ" | "ZXY" | "ZYX" | "YZX" | "XZY";

// ----------------------------------------------------------------------------
export class VectorUtils {
    /**
     * Returns the string representation of the vec2 or vec3. E.g. [1.333333333, 2, 3] ...outputs: "[1.333, 2.000, 3.000]"
     */
    static toString(vector: vec2 | vec3, fixedDigits: number = 3): string {
        return `[${Array.from(vector).map((entry: number) => entry.toFixed(fixedDigits)).join(', ')}]`;
    }

    static toVec2Array(vector: vec2): V2.Vector2 {
        return Array.from(vector) as V2.Vector2;
    }

    static toVec3Array(vector: vec3): V2.Vector3 {
        return Array.from(vector) as V2.Vector3;
    }

    static rotate(vector: vec3, orientation: vec3): vec3 {
        let origin: vec3 = vec3.create(),
            rotatedVector: vec3 = vec3.clone(vector);

        Array.from(orientation)
            .forEach((deg: number, idx: number) => {
                let rotateFunc: (out: vec3, a: vec3, b: vec3, rad: number) => vec3,
                    rads: number = MeasurementUtils.degToRad(deg);

                if (idx === 0) {
                    rotateFunc = vec3.rotateX;
                } else if (idx === 1) {
                    rotateFunc = vec3.rotateY;
                } else {
                    rotateFunc = vec3.rotateZ;
                }

                rotateFunc(rotatedVector, rotatedVector, origin, rads);
            });

        return rotatedVector;
    }

    static roundVec3ToPrecision(position: vec3, precision: number = DEFAULT_PRECISION): vec3 {
        return [
            NumberUtils.roundToPrecision(position[0], precision),
            NumberUtils.roundToPrecision(position[1], precision),
            NumberUtils.roundToPrecision(position[2], precision)
        ];
    }

    static scaleVec3ToPrecision(position: vec3, precision: number = DEFAULT_PRECISION, scaleFactor: number = 1): vec3 {
        return [
            NumberUtils.scaleToPrecision(position[0], precision, scaleFactor),
            NumberUtils.scaleToPrecision(position[1], precision, scaleFactor),
            NumberUtils.scaleToPrecision(position[2], precision, scaleFactor)
        ];
    }

    /**
     * Returns an euler angle representation of a quaternion
     * @param  {vec3} out Euler angles, pitch-yaw-roll
     * @param  {quat} quat Quaternion
     * @return {vec3} out
     */
    static quaternionToEuler(out: vec3, quat: quat, order: EulerOrder = "XYZ", toDegrees?: boolean): vec3 {
        let rotationMatrix: mat4 = mat4.fromQuat(mat4.create(), quat);

        const m11 = rotationMatrix[0], m12 = rotationMatrix[4], m13 = rotationMatrix[8];
        const m21 = rotationMatrix[1], m22 = rotationMatrix[5], m23 = rotationMatrix[9];
        const m31 = rotationMatrix[2], m32 = rotationMatrix[6], m33 = rotationMatrix[10];

        let x: number = 0,
            y: number = 0,
            z: number = 0;

        switch (order) {
            case 'XYZ':
                y = Math.asin(NumberUtils.getBoundValue(m13, -1, 1));

                if (Math.abs(m13) < 0.9999999) {
                    x = Math.atan2(- m23, m33);
                    z = Math.atan2(- m12, m11);
                } else {
                    x = Math.atan2(m32, m22);
                    z = 0;
                }
                break;
            case 'YXZ':
                x = Math.asin(-1 * NumberUtils.getBoundValue(m23, -1, 1));

                if (Math.abs(m23) < 0.9999999) {
                    y = Math.atan2(m13, m33);
                    z = Math.atan2(m21, m22);
                } else {
                    y = Math.atan2(- m31, m11);
                    z = 0;
                }
                break;

            case 'ZXY':
                x = Math.asin(NumberUtils.getBoundValue(m32, -1, 1));

                if (Math.abs(m32) < 0.9999999) {
                    y = Math.atan2(- m31, m33);
                    z = Math.atan2(- m12, m22);
                } else {
                    y = 0;
                    z = Math.atan2(m21, m11);
                }
                break;
            case 'ZYX':
                y = Math.asin(- NumberUtils.getBoundValue(m31, -1, 1));

                if (Math.abs(m31) < 0.9999999) {
                    x = Math.atan2(m32, m33);
                    z = Math.atan2(m21, m11);
                } else {
                    x = 0;
                    z = Math.atan2(- m12, m22);
                }
                break;
            case 'YZX':
                z = Math.asin(NumberUtils.getBoundValue(m21, - 1, 1));

                if (Math.abs(m21) < 0.9999999) {
                    x = Math.atan2(- m23, m22);
                    y = Math.atan2(- m31, m11);
                } else {
                    x = 0;
                    y = Math.atan2(m13, m33);
                }
                break;
            case 'XZY':
                z = Math.asin(- NumberUtils.getBoundValue(m12, - 1, 1));

                if (Math.abs(m12) < 0.9999999) {
                    x = Math.atan2(m32, m22);
                    y = Math.atan2(m13, m11);
                } else {
                    x = Math.atan2(- m23, m33);
                    y = 0;
                }
                break;
        }

        // let roundingFactor: number = 10000;
        // Math.round( * roundingFactor) / roundingFactor
        // Math.round( * roundingFactor) / roundingFactor
        // Math.round( * roundingFactor) / roundingFactor
        out[0] = x;
        out[1] = y;
        out[2] = z;

        if (toDegrees) {
            out.forEach((num: number, idx: number) => out[idx] = num * 180 / Math.PI);
        }

        return out;
    }

    // pitch (x), roll (y), yaw (z)
    /**
     * Returns an euler angle representation of a quaternion
     * 
     * @param  {vec3} eulerAngleInDeg Euler angles, pitch-yaw-roll
     * @param  {string} order optional order, default is XYZ
     * @return {vec3} out
     */
    static eulerToQuaternion(eulerAngleInDeg: vec3, order: EulerOrder = "XYZ"): quat {
        // Code ported from ThreeJS
        let x: number = MeasurementUtils.degToRad(eulerAngleInDeg[0]),
            y: number = MeasurementUtils.degToRad(eulerAngleInDeg[1]),
            z: number = MeasurementUtils.degToRad(eulerAngleInDeg[2]),
            w: number = 1;

        // http://www.mathworks.com/matlabcentral/fileexchange/
        // 	20696-function-to-convert-between-dcm-euler-angles-quaternions-and-euler-vectors/
        //	content/SpinCalc.m

        const cos = Math.cos;
        const sin = Math.sin;

        const c1 = cos(x / 2);
        const c2 = cos(y / 2);
        const c3 = cos(z / 2);

        const s1 = sin(x / 2);
        const s2 = sin(y / 2);
        const s3 = sin(z / 2);

        switch (order) {

            case 'XYZ':
                x = s1 * c2 * c3 + c1 * s2 * s3;
                y = c1 * s2 * c3 - s1 * c2 * s3;
                z = c1 * c2 * s3 + s1 * s2 * c3;
                w = c1 * c2 * c3 - s1 * s2 * s3;
                break;

            case 'YXZ':
                x = s1 * c2 * c3 + c1 * s2 * s3;
                y = c1 * s2 * c3 - s1 * c2 * s3;
                z = c1 * c2 * s3 - s1 * s2 * c3;
                w = c1 * c2 * c3 + s1 * s2 * s3;
                break;

            case 'ZXY':
                x = s1 * c2 * c3 - c1 * s2 * s3;
                y = c1 * s2 * c3 + s1 * c2 * s3;
                z = c1 * c2 * s3 + s1 * s2 * c3;
                w = c1 * c2 * c3 - s1 * s2 * s3;
                break;

            case 'ZYX':
                x = s1 * c2 * c3 - c1 * s2 * s3;
                y = c1 * s2 * c3 + s1 * c2 * s3;
                z = c1 * c2 * s3 - s1 * s2 * c3;
                w = c1 * c2 * c3 + s1 * s2 * s3;
                break;

            case 'YZX':
                x = s1 * c2 * c3 + c1 * s2 * s3;
                y = c1 * s2 * c3 + s1 * c2 * s3;
                z = c1 * c2 * s3 - s1 * s2 * c3;
                w = c1 * c2 * c3 - s1 * s2 * s3;
                break;

            case 'XZY':
                x = s1 * c2 * c3 - c1 * s2 * s3;
                y = c1 * s2 * c3 - s1 * c2 * s3;
                z = c1 * c2 * s3 + s1 * s2 * c3;
                w = c1 * c2 * c3 + s1 * s2 * s3;
                break;
        }

        return quat.fromValues(x, y, z, w);
    }

    // Convert orientation from Euler XYZ convention to Euler ZYX (all values in degrees)
    static eulerXYZToZYX(eulerXYZInDeg: vec3): vec3 {
        let quatAngle: quat = this.eulerToQuaternion(eulerXYZInDeg, "XYZ");
        let rotationMat: mat3 = mat3.fromQuat(mat3.create(), quatAngle);

        // pitch (x), roll (y), yaw (z) 
        let x: number,
            y: number,
            z: number;

        // matrix columns
        const m11 = rotationMat[0], m12 = rotationMat[3];
        const m21 = rotationMat[1], m22 = rotationMat[4];
        const m31 = rotationMat[2], m32 = rotationMat[5], m33 = rotationMat[8];

        // XYZ to ZYX
        y = Math.asin(-NumberUtils.getBoundValue(m31, -1, 1));

        if (Math.abs(m31) < 0.9999999) {
            x = Math.atan2(m32, m33);
            z = Math.atan2(m21, m11);
        } else {
            x = 0;
            z = Math.atan2(- m12, m22);
        }

        x = MeasurementUtils.radToDeg(x);
        y = MeasurementUtils.radToDeg(y);
        z = MeasurementUtils.radToDeg(z);

        return vec3.fromValues(x, y, z);
    }

    // Convert orientation from Euler ZYX convention to Euler XYZ (all values in degrees)
    static eulerZYXToXYZ(eulerZYXInDeg: vec3): vec3 {

        let quatAngle: quat = this.eulerToQuaternion(eulerZYXInDeg, "ZYX");
        let rotationMat: mat3 = mat3.fromQuat(mat3.create(), quatAngle);

        // pitch (x), roll (y), yaw (z) 
        let x: number,
            y: number,
            z: number;

        // matrix columns
        const m11 = rotationMat[0], m12 = rotationMat[3], m13 = rotationMat[6];
        const m22 = rotationMat[4], m23 = rotationMat[7];
        const m32 = rotationMat[5], m33 = rotationMat[8];

        // ZYX to XYZ
        y = Math.asin(NumberUtils.getBoundValue(m13, -1, 1));

        if (Math.abs(m13) < 0.9999999) {
            x = Math.atan2(- m23, m33);
            z = Math.atan2(- m12, m11);
        } else {
            x = Math.atan2(m32, m22);
            z = 0;
        }

        x = MeasurementUtils.radToDeg(x);
        y = MeasurementUtils.radToDeg(y);
        z = MeasurementUtils.radToDeg(z);

        return vec3.fromValues(x, y, z);
    }

    static getVectorPosition(direction: vec3, length: number, translation: vec3, orientation: vec3): Vector3 {
        let vector: vec3 = vec3.clone(direction), // a 1 length vector on x axis
            vectorOrigin: vec3 = vec3.create(); // 0 0 0 of the vector

        // scale vector at proper width
        vec3.scale(vector, vector, length);

        // rotate the vector for the calculation 
        if (!vec3.exactEquals(orientation, vec3.create())) {
            vec3.rotateX(vector, vector, vectorOrigin, MeasurementUtils.degToRad(orientation[0]));
            vec3.rotateY(vector, vector, vectorOrigin, MeasurementUtils.degToRad(orientation[1]));
            vec3.rotateZ(vector, vector, vectorOrigin, MeasurementUtils.degToRad(orientation[2]));
        }

        // vector is at proper width and orientation, translate it from the item's original position 
        vec3.add(vector, vector, translation);

        return vector as Vector3;
    }

    static clampVector(toClamp: vec3, clampVector: vec3): vec3 {
        let [x, y, z]: vec3 = toClamp,
            [clampX, clampY, clampZ]: vec3 = clampVector;

        if (clampX !== 0 && x !== 0) {
            x = 0;
        } else if (clampY !== 0 && y !== 0) {
            y = 0;
        } else if (clampZ !== 0 && z !== 0) {
            z = 0;
        }

        return vec3.fromValues(x, y, z);
    }
}