import React, { PropsWithChildren } from "react";
import { TestComponentError } from "./TestComponentError";

type ErrorBoundaryProps = PropsWithChildren<{
    component: any;
}>;

type ErrorBoundaryState = {
    error?: Error;
};

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {

    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {};
    }

    static getDerivedStateFromError(error: unknown) {
        return { error };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.error("ErrorBoundary caught an error", error, errorInfo);
    }

    render() {
        if (this.state.error) {
            return <TestComponentError error={this.state.error} />;
        }
        return <>{this.props.children}</>;
    }
}