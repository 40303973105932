import { useEffect, useState } from "react";
import { IPill, IPillFilterCategory, IPillFilterOption, IPillType, IPillContentType } from "./PillsInterface";

export const persistedPills: Map<string, IPill[]> = new Map(); // Pills by id

export function usePills(
        id: string,
        onFilterChange: (
            filterContent: IPillFilterCategory | undefined, 
            selections: IPillFilterOption[] | string | undefined, 
            contentType: IPillContentType | undefined
        ) => void
    ) {   
    const persistedPillsForId = persistedPills.get(id) || []; 
    const [pills, setPills] = useState<IPill[]>(persistedPillsForId);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    
    const addPill = (newPill: IPill) => {
        closePills();    
        if (!pills.find(p => p.name === newPill.name)) {
            updatingPills([...pills, newPill]);
            
            if (newPill.isOpen) {
                setIsFilterOpen(false);
            }

            if (newPill.selectedOptions) {
                onFilterChange(
                    newPill.originalData, 
                    newPill.type === IPillType.inputValue ? newPill.selectedValue : newPill.selectedOptions, 
                    newPill.contentType                    
                );      
            }
        }
    };
    
    const openPill = (pill: IPill) => {
        setIsFilterOpen(false);
        const updatedPills = pills.map(p => {
            if (p === pill) {
                return {
                    ...p,
                    isOpen: !p.isOpen
                }
            } else {
                p.isOpen = false;
            }
            return p;
        });
        updatingPills(updatedPills);
    };

    const closePill = (pill: IPill) => {
        const updatedPills = pills.map(p => {
            if (p === pill) {
                return {
                    ...p,
                    isOpen: false
                }
            }
            return p;
        });
        updatingPills(updatedPills);
    }

    const openFilters = () => {
        closePills();   
        setIsFilterOpen(isFilterOpen => !isFilterOpen);
    };

    const closeFilters = () => {
        setIsFilterOpen(false);
    };

    const updatePill = (pill: IPill) => {
        let updatedPills: IPill[]= [];

        if (pill.level === 1) {
            updatedPills = pills.filter(p => p.level === 1);
        } else {
            updatedPills = pills.map(p => {
                if (p === pill) {
                    return {
                        ...p,
                        value: pill.type === IPillType.inputValue ? pill.selectedValue : pill.selectedOptions
                    }
                }
                return p;
            });
        }   
        updatingPills(updatedPills);
        onFilterChange(
            pill.originalData, 
            pill.type === IPillType.inputValue ? pill.selectedValue : pill.selectedOptions, 
            pill.contentType
        );  
    };
    
    const clearPills = () => {
        closePills();  
        updatingPills([]);   
        setIsFilterOpen(false);

        onFilterChange(
            undefined, 
            undefined, 
            undefined            
        ); 
    };

    const closePills = () => {
        pills.forEach((p: IPill) => {
            p.isOpen = false;            
        });        
    };
        
    const deletePill = (pill: IPill) => {
        if (pill.level === 1) {
            clearPills();
        } else {
            const newPills = pills.filter(p => p !== pill);
            closePills();    
            updatingPills(newPills);
            onFilterChange(
                pill.originalData, 
                undefined,
                pill.contentType
            ); 
        }
    };

    const updatingPills = (pills: IPill[]) => {
        setPills(pills);
        persistedPills.set(id, pills);
    }

    useEffect(() => {
        const persistedPillsForId = persistedPills.get(id) || []; 
        
        if (persistedPillsForId.length > 0) {
            updatingPills(persistedPillsForId);
            persistedPillsForId.forEach(pill => {
                onFilterChange(
                    pill.originalData, 
                    pill.type === IPillType.inputValue ? pill.selectedValue : pill.selectedOptions, 
                    pill.contentType                    
                );  
            });
        } else {
            onFilterChange(
                undefined, 
                undefined, 
                undefined            
            ); 
        }
        
    }, [id]);
    return {
        pills,
        isFilterOpen,
        addPill,
        openPill,
        closePill,
        updatePill,
        deletePill,
        clearPills,
        openFilters,
        closeFilters
    };
}