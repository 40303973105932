import { IEntitySchema } from '../../SchemaEntityUtils';
import {
    SchemaPath,
    ValidationExtraData,
    ValidationResponse,
    ValidationRule,
} from '../SchemaValidationTypes';
import { TypeValidationRule } from './TypeValidationRule';

export class ItemRefValidationRule extends ValidationRule {
    async exec(
        value: unknown,
        schema: IEntitySchema,
        path: SchemaPath,
        extraData?: ValidationExtraData
    ): Promise<ValidationResponse> {
        const response: ValidationResponse = { success: true };
        const isRef = path.at(-1) === 'itemRef';
        //@ts-ignore
        const ref = isRef ? value : extraData?.parentEntity?.itemRef;

        if (!ref && isRef) {
            response.success = false;
            response.error = new Error('Please enter an item ref.');

            return response;
        }

        const item = await this.getItem(ref as string, extraData!);
        if (isRef && item) {
            if (!this.isUnique(ref as string, extraData!)) {
                response.success = false;
                response.error = new Error('This item is already in use.');
            }
        } 
        if (!item) {
            response.success = false;
            response.error = new Error('This item does not exist.');
        }

        if (response.success && !isRef) {
            return new TypeValidationRule().exec(value, {
                type: schema!.type,
            });
        }

        return response;
    }

    isUnique(value: string, extraData: ValidationExtraData) {
        const { refList } = extraData;
        return !refList?.find(
            (ref) => ref.itemRef.toLowerCase() === value.toLowerCase()
        );
    }

    async getItem(ref: string, extraData: ValidationExtraData) {
        const { catalogVersionId } = extraData;
        
        const refSplit = ref?.split(':');
        let cicResponse;
        if(refSplit && refSplit.length>1)
        {
            cicResponse= await CiCAPI.authoring.getCatalogItemDef( ref,catalogVersionId);
            return cicResponse.result;
        }
        else{
            cicResponse= await CiCAPI.authoring.getCatalogItemDefs(catalogVersionId!);
        }

        if(cicResponse.success)
        {
            return cicResponse.result?.find(
                (item:ICatalogItemDef)=>
                    item.code.toLowerCase()===ref.toLowerCase()
            );
        }

        return null;
    }
}
