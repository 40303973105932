import { NumberUtils } from "./NumberUtils";


// prettier-ignore
const UnitConversionTbl: Record<MeasurementUnit, number> = {
    "mm": 1,
    "cm": 10,
    "m": 1000,
    "2032s": 0.0125,    // 1/2032 of an inch corresponds to 1/80=0.0125 of a mm
    "inches": 25.4      // 2032/80
};


// ----------------------------------------------------------------------------
export class MeasurementUtils {
    // ------------------------------------------------------------------------
    static degToRad(deg: number): number {
        return deg / 180 * Math.PI;
    }

    // ------------------------------------------------------------------------
    static radToDeg(rad: number): number {
        return rad / Math.PI * 180;
    }

    // ------------------------------------------------------------------------
    static convertUnit(currentValue: number | string, oldUnit: MeasurementUnit, newUnit: MeasurementUnit, roundToPrecision = false, precision?: number): number {
        const numericValue = (typeof currentValue === 'string') ? parseFloat(currentValue) : currentValue;

        if (oldUnit === newUnit) {
            return numericValue;
        }

        if (isNaN(numericValue)) {
            throw new Error("Invalid numeric value");
        }

        let factor = UnitConversionTbl[oldUnit] || 1;
        const oldValueInMillimeters: number = numericValue * factor;

        factor = UnitConversionTbl[newUnit] || 1;
        let newValue: number = oldValueInMillimeters / factor;

        if (roundToPrecision) {
            // if in inches, we round to 1/32th of an inch, which is 5 decimals here the multiplication by 100000
            newValue = NumberUtils.roundToPrecision(newValue, precision ?? newUnit === "inches" ? (1 / 32) * 100000 : 1000);
        }

        return newValue;
    }

    // ------------------------------------------------------------------------
    static convertToMeasurementSystem(value: number, measurementSystem: string): number {
        let converted: number = value,
            unit: string = (measurementSystem || "").toLowerCase();

        if (unit === "imperial") {
            converted = (value * 80) / 2032;
        }

        return converted;
    }

    // ------------------------------------------------------------------------
    /**
     * Make sure to pass defaultUOM
     */
    static resolveToUOMDisplayString(value: number | string | undefined, uom?: string): string {
        // let unit: string = (uom || Config.getConfig("contentPlatform.unitOfMeasureSystem") as string || "").toLowerCase(),
        let unit: string = (uom || "").toLowerCase(),
            uomDisplayString: string = value?.toString() ?? "";

        if (!isNaN(Number(value))) {
            if (unit === "metric") {
                uomDisplayString += " mm";
            } else if (unit === "imperial") {
                uomDisplayString += `"`;
            } else if (uom) {
                uomDisplayString += " " + uom;
            }
        }

        return uomDisplayString;
    }
}
