import { useState } from 'react';
import { EntityType } from '../config';

type Path = (string | number)

type ExternalSchema = {
    entityType: EntityType
    path: Path[]
    schema: any
}

export function useExternalSchema() {

    const [state, setState] = useState<{
        manager: ExternalSchemaMgr
    }>({
        manager: externalSchemaManager
    });

    const getExternalSchema = (entityType: EntityType, path: Path[]) =>  {
        return state.manager.getExternalSchema(entityType, path);
    }

    const addExternalSchema = (entityType: EntityType, path: Path[], schema: any) => {
        state.manager.addExternalSchema(entityType, path, schema);
        setState({ manager: state.manager });
    }

    return {
        getExternalSchema,
        addExternalSchema
    };

}

export class ExternalSchemaMgr {

    public externalSchemas: ExternalSchema[];

    constructor() {
        this.externalSchemas = [];
    }

    public getExternalSchema = (entityType: EntityType, path: Path[]) =>  {
        const externalSchema = this.externalSchemas.find((e) => e.entityType === entityType && e.path.join(".") === path.join("."));
        if (externalSchema) {
            return externalSchema.schema;
        }
    }

    public addExternalSchema = (entityType: EntityType, path: Path[], schema: any) => {
        const foundSchema = this.externalSchemas.find((e) => e.entityType === entityType && e.path.join(".") === path.join("."));
        if (foundSchema) {
            foundSchema.schema = schema;
        }
        else {
            this.externalSchemas.push({ entityType, path, schema });
        }
    }

}

const externalSchemaManager = new ExternalSchemaMgr();