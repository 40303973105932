import React from 'react'
import { IIconProps } from 'Interfaces'

export function Search2Icon(props: IIconProps) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
            <g clipPath="url(#clip0_1926_667)">
            <path d="M15.9077 15.0463L10.9846 10.1232C11.8461 9.07705 12.4 7.72321 12.4 6.24628C12.4 2.86167 9.63075 0.0924377 6.24613 0.0924377C2.86152 0.0924377 0.0922852 2.86167 0.0922852 6.24628C0.0922852 9.6309 2.86152 12.4001 6.24613 12.4001C7.72305 12.4001 9.0769 11.9078 10.1231 10.9847L15.0461 15.9078L15.9077 15.0463ZM6.24613 11.1694C3.53844 11.1694 1.32305 8.95398 1.32305 6.24628C1.32305 3.53859 3.53844 1.32321 6.24613 1.32321C8.95382 1.32321 11.1692 3.53859 11.1692 6.24628C11.1692 8.95398 8.95382 11.1694 6.24613 11.1694Z" fill="currentColor"/>
            </g>
            <defs>
            <clipPath id="clip0_1926_667">
            <rect width="16" height="16" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    )
}