import { IConsolidatedItemVariant, IConsolidatedClientItem, ClientItemFactory, IConsolidatedFeatureState, IConsolidatedConfigurationState } from "@cic/ui-toolkit/src/models";
import { IAddItemOptions, TresAmigos } from "./DesignEngine";
import { CommonUtils, MeasurementUtils, ObjectUtils } from "@cic/utils";
import ConnectionMap from "./ConnectionMap";
import { vec3 } from "gl-matrix";
import md5 from 'md5';
import { CiCUtils } from "@cic/cic-utils";
import UIToolkitUtils from "@cic/ui-toolkit/src/UIToolkitUtils";

interface IPoints {
    posLBB: vec3,
    posLFB: vec3,
    posLBT: vec3,
    posLFT: vec3,
    // right points
    posRBB: vec3,
    posRFB: vec3,
    posRBT: vec3,
    posRFT: vec3
}

//=============================================================================
class DesignItem {
    #_apiHandle: ICiCAPI;
    
    private _instanceId: string;
    private _itemVariant: IConsolidatedItemVariant;
    private _position: vec3;
    private _instanceExtras: Record<string, unknown>;
    private _source: string | undefined;
    private _geometries: EmbeddedGeometries | undefined;

    private _posLeftBackBottom: vec3;
    private _posLeftFrontBottom: vec3;
    private _posLeftBackTop: vec3;
    private _posLeftFrontTop: vec3;
    
    private _posRightBackBottom: vec3;
    private _posRightFrontBottom: vec3;
    private _posRightBackTop: vec3;
    private _posRightFrontTop: vec3;
 
    private _orientation: vec3;
    private _configurationState: IConsolidatedConfigurationState;
    // items connected to a surface of this item (ie: cabinets next to each other)
    private _connectionMap: ConnectionMap;
    // linked items not necessarily connected to any surfaces, but relatated to this item, ie: crown molding item
    private _linkedItemsMap: Map<string, DesignItem>;
    private _fingerprint: string;
    private _collisionBox: ICollisionBox;
    private _positionOffset: vec3;
    private _automations?: Record<string, unknown>

    //=========================================================================
    constructor(instanceId: string, itemVariant: IConsolidatedItemVariant, configurationState: IConsolidatedConfigurationState, options?: IAddItemOptions) {
        this.#_apiHandle = options?.apiHandle ?? CiCAPI;
        this._itemVariant = itemVariant;
        this._orientation = vec3.create();
        this._instanceExtras = options?.extras ?? {};

        this._geometries = options?.geometries;
        this._source = options?.source;

        this._collisionBox = options?.collisionBox ?? {
            min: [0, -(itemVariant.dimensions?.depth ?? 0), 0],
            max: [itemVariant.dimensions?.width ?? 0, 0, itemVariant.dimensions?.height ?? 0]
        };

        this._position = vec3.create();
        this._positionOffset = vec3.create();

        this._posLeftBackBottom = vec3.create();
        this._posLeftFrontBottom = vec3.create();
        this._posLeftBackTop = vec3.create();
        this._posLeftFrontTop = vec3.create();

        this._posRightBackBottom = vec3.create();
        this._posRightFrontBottom = vec3.create();
        this._posRightBackTop = vec3.create();
        this._posRightFrontTop = vec3.create();

        if (options?.position) {
            this.position = options.position;
        }

        if (options?.orientation) {
            this.orientation = options.orientation;
        }

        this._instanceId = instanceId || options?.instanceId || CommonUtils.genUUID();
        this._itemVariant.uuid = this._instanceId;
        this._configurationState = configurationState;

        this._fingerprint = this._createFingerprint(); // initialized

        this.updateWorldBBox();

        this._connectionMap = new ConnectionMap(this);
        this._linkedItemsMap = new Map();
        this._automations = itemVariant.geometriesInfo?.automations;
    }

    //=========================================================================
    get instanceId() {
        return this._instanceId;
    }

    //=========================================================================
    get extras(): Record<string, unknown> {
        return Object.assign({}, this._instanceExtras);
    }

    //=========================================================================
    get source(): string | undefined {
        return this._source;
    }

    //=========================================================================
    get collisionBox(): ICollisionBox {
        return this._collisionBox;
    }

    //=========================================================================
    setExtra(prop: string, value: unknown) {
        if (value === undefined) {
            delete this._instanceExtras[prop];
        } else {
            this._instanceExtras[prop] = value;
        }
    }

    //=========================================================================
    get position() {
        return vec3.clone(this._position) as Vector3;
    }

    //=========================================================================
    set position(newPos: Vector3) {
        this._position[0] = newPos[0];
        this._position[1] = newPos[1];
        this._position[2] = newPos[2];
    }

    //=========================================================================
    setPosition(newPos: Vector3) {
        this.position = newPos;
        this.updateWorldBBox();
    }

    get positionOffset(): vec3 {
        return vec3.clone(this._positionOffset);
    }

    //=========================================================================
    get fingerprint(): string {
        return this._fingerprint;
    }

    //=========================================================================
    get isCornerItem(): boolean {
        return ObjectUtils.existsIn(this.itemInstance.classification?.characteristics?.cabinetType, "corner");
    }

    // a "bad" corner item is a corner item published with it's Pos Left Back Bottom NOT at a connectable point...
    get isBadCornerItem(): boolean {
        let characteristics: ICharacteristics | undefined = this.itemInstance.classification?.characteristics;
        if (!characteristics) return false;

        return this.isCornerItem &&
            ObjectUtils.existsIn(characteristics.subType, "base") &&
            (
                ObjectUtils.existsIn(characteristics.openingDirection, "left") &&
                ObjectUtils.existsIn(characteristics.shapeType, "90degrees")
            ) ||
            (
                ObjectUtils.existsIn(characteristics.openingDirection, "right") ||
                ObjectUtils.existsIn(characteristics.shapeType, "diagonal")
            );
    }

    //=========================================================================
    get isBlindCornerItem(): boolean {
        return this.isCornerItem &&
            ObjectUtils.existsIn(this.itemInstance.classification?.characteristics?.shapeType, "blind");
    }

    //=========================================================================
    get isBlindLeftCornerItem(): boolean {
        let characteristics: ICharacteristics | undefined = this.itemInstance.classification?.characteristics;
        return this.isBlindCornerItem &&
            ObjectUtils.existsIn((characteristics?.frameDirection || characteristics?.openingDirection), "left");
    }

    //=========================================================================
    get isBlindRightCornerItem(): boolean {
        let characteristics: ICharacteristics | undefined = this.itemInstance.classification?.characteristics;
        return this.isBlindCornerItem &&
            ObjectUtils.existsIn((characteristics?.frameDirection || characteristics?.openingDirection), "right");
    }

    //=========================================================================
    get isStructuralItem(): boolean {
        let isStrutural: boolean = false;
        if (this.itemInstance.classification) {
            let { baseItemType } = this.itemInstance.classification;
            isStrutural = /door|window|recess|wall/i.test(baseItemType);
        }
        return isStrutural;
    }

    //=========================================================================
    get isWallItem(): boolean {
        return isWallItem(this.itemInstance);
    }

    //=========================================================================
    get isTallItem(): boolean {
        return isTallItem(this.itemInstance);
    }

    //=========================================================================
    get posLeftBackBottom() {
        return vec3.clone(this._posLeftBackBottom); // or position
    }

    //=========================================================================
    get posLeftFrontBottom() {
        return vec3.clone(this._posLeftFrontBottom);
    }

    //=========================================================================
    get posLeftBackTop() {
        return vec3.clone(this._posLeftBackTop);
    }

    //=========================================================================
    get posLeftFrontTop() {
        return vec3.clone(this._posLeftFrontTop);
    }

    //=========================================================================
    get posRightBackBottom() {
        return vec3.clone(this._posRightBackBottom);
    }

    //=========================================================================
    get posRightFrontBottom() {
        return vec3.clone(this._posRightFrontBottom);
    }

    //=========================================================================
    get posRightBackTop() {
        return vec3.clone(this._posRightBackTop);
    }

    //=========================================================================
    get posRightFrontTop() {
        return vec3.clone(this._posRightFrontTop);
    }

    //=========================================================================
    get orientation() {
        return vec3.clone(this._orientation) as Vector3; // clone ?
    }

    //=========================================================================
    set orientation(newOri: Vector3) {
        this._orientation[0] = newOri[0];
        this._orientation[1] = newOri[1];
        this._orientation[2] = newOri[2];
    }

    get automations(): Record<string, unknown> | undefined {
        return this._automations;
    }

    set automations(value: Record<string, unknown> | undefined) {
        this._automations = value;
    }

    //=========================================================================
    setOrientation(newOri: Vector3) {
        this.orientation = newOri;
        this.updateWorldBBox();
    }

    //=========================================================================
    setPose(position?: Vector3, orientation?: Vector3) {
        let hasMoved: boolean = false;

        if (position) {
            hasMoved = true;
            this.position = position;
        }

        if (orientation) {
            hasMoved = true;
            this.orientation = orientation;
        }

        if (hasMoved) {
            this.updateWorldBBox();
        }
    }

    //=========================================================================
    get connectionMap() {
        return this._connectionMap;
    }

    //=========================================================================
    get itemInstance(): IConsolidatedItemVariant {
        return this._itemVariant;
    }

    //=========================================================================
    set itemInstance(updatedInstance: IConsolidatedItemVariant) {
        if (updatedInstance) {
            this._itemVariant = updatedInstance;
            this._configurationState = updatedInstance.configurationState ?? [];
            // update fingerprint
            this._fingerprint = this._createFingerprint();
        }
    }

    //=========================================================================
    get configurationState(): IConsolidatedConfigurationState {
        return ObjectUtils.cloneObj(this._configurationState) as IConsolidatedConfigurationState;
    }

    //=========================================================================
    get id(): string {
        return this._itemVariant.id;
    }

    //=========================================================================
    get name(): string {
        return this._itemVariant.names.main;
    }

    //=========================================================================
    get description(): string {
        return this._itemVariant.descriptions?.short || "";
    }

    //=========================================================================
    get imageURL(): string {
        return this._itemVariant.images?.main.uri || "";
    }

    //=========================================================================
    get dimensions(): IDimensions {
        // The design engine is in mm. Make sure dimensions are returned in mm.
        let width = MeasurementUtils.convertUnit(this._itemVariant?.dimensions?.width ?? 0, this._itemVariant?.dimensionSpecs?.width?.measurementUnit ?? "mm", "2032s");
        let height = MeasurementUtils.convertUnit(this._itemVariant?.dimensions?.height ?? 0, this._itemVariant?.dimensionSpecs?.height?.measurementUnit ?? "mm", "2032s");
        let depth = MeasurementUtils.convertUnit(this._itemVariant?.dimensions?.depth ?? 0, this._itemVariant?.dimensionSpecs?.depth?.measurementUnit ?? "mm", "2032s");

        return { width, height, depth };
    }

    //=========================================================================
    get orderCode(): string {
        return this._itemVariant.refCodes?.sku || "";
    }

    //=========================================================================
    get classification(): IClassification | undefined {
        return this._itemVariant.classification;
    }

    //=========================================================================
    set geometries(geometries: EmbeddedGeometries | undefined) {
        this._geometries = geometries;
    }

    //=========================================================================
    get geometries(): EmbeddedGeometries | undefined {
        return this._geometries;
    }

    toClientItemRef(): IClientItemRef {
        return { 
            itemId: this.id, 
            configurationState: this._configurationState,
            subItemScope: this._itemVariant.subItemScope,
            instanceId: this._instanceId
        }
    }

    //=========================================================================
    async getDynamicFeatures(): Promise<IDynamicFeature[] | undefined> {
        return await UIToolkitUtils.getItemDynamicFeatures(this.toClientItemRef(), this.#_apiHandle);
    }

    //=========================================================================
    async getGeometry(): Promise<GLB | undefined> {
        return await UIToolkitUtils.getItemGeometry(this.toClientItemRef(), this.#_apiHandle);
    }

    //=========================================================================
    async applyOptionSelections(optionSelections: IConsolidatedFeatureState[]): Promise<boolean> {
        let response: IAPIResponse<IConsolidatedItemVariant> = await UIToolkitUtils.getItemVariantById(this.id, this._configurationState, this.#_apiHandle, { optionSelections, includeSubItems: true });
        if (response.success && response.result) {
            this.itemInstance = response.result;
        } else {
            window.postMessage({
                source: {
                    component: "test-design-engine",
                    action: "applyOptionSelections"
                },
                type: "app_msg",
                isError: true,
                message: `Unable to set option selections ${JSON.stringify(optionSelections)} on item ${this._itemVariant.id}. Error: ${response.message}`
            }, "*");
        }

        // if item gets resized we need to update it's box
        let collisionBox: ICollisionBox = await CiCUtils.getItemCollisionBox(this.id, this.configurationState, "2032s");
        this._collisionBox = collisionBox;

        this.updateWorldBBox();

        return response.success;
    }

    //=========================================================================
    async applyFeatureOption(featureId: string, optionId: string, value?: number | string, path?: string): Promise<boolean> {
        let featureState: IConsolidatedFeatureState = {
            featureId: featureId,
            optionId: optionId
        };

        if (value !== undefined) {
            featureState.value = value as number;
        }

        if (path) {
            featureState.subItemScope = path;
        }

        let response: IAPIResponse<IConsolidatedItemVariant> = await UIToolkitUtils.getItemVariantById(this.id, this._configurationState, this.#_apiHandle, { optionSelections: [featureState], includeSubItems: true });
        if (response.success && response.result) {
            this.itemInstance = response.result;
            this._collisionBox = await CiCUtils.getItemCollisionBox(this.id, this.configurationState, "2032s"); // if item gets resized we need to update it's box
            this.updateWorldBBox();
        } else {
            window.postMessage({
                source: {
                    component: "test-design-engine",
                    action: "applyFeatureOption"
                },
                type: "app_msg",
                isError: true,
                message: `Unable to set feature ${featureId} and option ${optionId} on item ${this._itemVariant.id}. Error: ${response.message}`
            }, "*");
        }

        return response.success;
    }

    addLinkedItem(item: DesignItem): void {
        this._linkedItemsMap.set(item.instanceId, item);
    }

    removeLinkedItem(item: DesignItem): void {
        this._linkedItemsMap.delete(item.instanceId);
    }

    isItemLinked(item: DesignItem): boolean {
        return this._linkedItemsMap.has(item.instanceId);
    }

    isItemInstanceIdLinked(instanceId: string) {
        this._linkedItemsMap.has(instanceId);
    }

    //=========================================================================
    toClientItem(selected?: boolean): IConsolidatedClientItem {
        return ClientItemFactory.create({
            instanceId: this._instanceId,
            itemId: this.id,
            configurationState: this.configurationState,
            position: Array.from(this.position).map((num: number) => num / 80) as Vector3, // convert to mm
            orientation: this.orientation,
            source: this._source,
            geometries: this._geometries,
            automations: this._automations,
            instanceExtras: Object.assign({}, this._instanceExtras, {
                selected,
                fingerprint: this._fingerprint
            })
        });
    }

    //=========================================================================
    serialize(space: IDesignSpaceData): ICommonDesignItem {
        // Do not serialize legacy classification information. Applications that require it can obtain it by loading the itemVariant at run-time.
        let slimItemClassification: IClassification | undefined = this.classification,
            connections: Array<IConnection> | undefined = this.extras?.connections as Array<IConnection> ?? [];

        delete slimItemClassification?.fullItemType;

        if (this._linkedItemsMap.size > 0) {
            Array.from(this._linkedItemsMap.keys())
                .forEach((linkedItemId: string) => {
                    connections!.push({
                        hostId: linkedItemId
                    });
                });
        }

        if (connections.length === 0) {
            connections = undefined;
        }

        // we make sure dimensions in CDF are in mm
        let { width, height, depth } = this.dimensions as IDimensions;

        width = MeasurementUtils.convertUnit(width ?? 0, "2032s", "mm");
        height = MeasurementUtils.convertUnit(height ?? 0, "2032s", "mm");
        depth = MeasurementUtils.convertUnit(depth ?? 0, "2032s", "mm");

        return {
            id: this.instanceId,
            spaceId: space?.id,
            catalogItemId: this.id,
            configurationState: this._getSlimConfigurationState(),
            position: Array.from(this.position).map((num: number) => num / 80) as TresAmigos,
            orientation: Array.from(this.orientation) as TresAmigos,
            name: this.name,
            dimensions: {
                width,
                height,
                depth
            },
            classification: slimItemClassification,
            connections
        };
    }

    //=========================================================================
    getStartPosition2D(): [number, number] {
        return [this.position[0], this.position[1]];
    }

    //=========================================================================
    getEndPosition2D(): [number, number] {
        const endPosition: vec3 = this.getEndPosition();
        return [endPosition[0], endPosition[1]];
    }

    //=========================================================================
    getEndPosition(): [number, number, number] {
        const
            startVector: vec3 = this.position,
            length: vec3 = vec3.fromValues(this.dimensions.width ?? 0, 0, 0),
            endVector: vec3 = _rotateVector(length, this.orientation),
            endPositionVec: vec3 = vec3.create();

        vec3.add(endPositionVec, startVector, endVector);
        return [endPositionVec[0], endPositionVec[1], endPositionVec[2]];
    }

    // --- UGLY MAPPING CODE TO 'support' WALLS --- 
    //=========================================================================
    mapToPerimeterWalls(code: string): IPerimeterWall {
        let outdoorPerimeter: boolean | undefined,
            outdoorPerimeterOption: string | undefined = this._getConfiguredOptionId("outdoor.perimeter"),
            type: "solidWall" | "virtualWall" = code.includes("solid") ? "solidWall" : "virtualWall",
            thickness: number | undefined;

        if (type === "solidWall") {
            outdoorPerimeter = !outdoorPerimeterOption || outdoorPerimeterOption.endsWith("outdoor.perimeter.true");
            thickness = this.dimensions.depth ?? 0;
        }

        return {
            id: this.instanceId,
            type,
            name: this.name,
            startPosition: this.getStartPosition2D(),
            thickness,
            outdoorPerimeter,
            startHeight: this._getConfiguredOptionValue("start.height") ?? this.dimensions.height ?? 0,
            endHeight: this._getConfiguredOptionValue("end.height") ?? this.dimensions.height ?? 0
        };
    }

    // --- UGLY MAPPING CODE TO 'support' WALLS --- 
    //=========================================================================
    mapToPartitionWalls(code: string): IPartitionWall {
        let type: PartitionWallType = "virtualWall",
            thickness: number | undefined,
            startHeight: number | undefined = this._getConfiguredOptionValue("start.height") ?? this.dimensions.height ?? 0,
            endHeight: number | undefined = this._getConfiguredOptionValue("end.height") ?? this.dimensions.height ?? 0,
            startPosition: Array<number> | undefined = this.getStartPosition2D(),
            endPosition: Array<number> | undefined = this.getEndPosition2D(),
            segments: Array<IWallSegment> | undefined;

        if (code.includes("solid")) {
            if (code.includes("rectangular")) {
                type = "solidRectangularWall";
                thickness = this.dimensions.depth ?? 0;
            } else {
                type = "solidArbitraryWall";
                segments = [{ startPosition, startHeight }];
                startHeight = undefined;
                endHeight = undefined;
                startPosition = undefined;
                endPosition = undefined;
            }
        }

        return {
            id: this.instanceId,
            type,
            name: this.name,
            startPosition,
            endPosition,
            thickness,
            startHeight,
            endHeight,
            offsetFromFloor: this._getConfiguredOptionValue("floor.offset") ?? 0,
            segments
        };
    }

    //=========================================================================
    updateWorldBBox(): IPoints {
        let itemWorldPosition: vec3 = this._position, // start with relative pos within the item's hierarchy
            itemOrientation: vec3 = this._orientation,
            leftX: number,
            rightX: number,
            frontY: number,
            backY: number,
            topZ: number,
            bottomZ: number,
            // left points
            posLBB: vec3,
            posLFB: vec3,
            posLBT: vec3,
            posLFT: vec3,
            // right points
            posRBB: vec3,
            posRFB: vec3,
            posRBT: vec3,
            posRFT: vec3;

        backY = this._collisionBox.max[1];
        leftX = this._collisionBox.min[0];
        frontY = this._collisionBox.min[1];
        rightX = this._collisionBox.max[0];
        topZ = this._collisionBox.max[2];
        bottomZ = this._collisionBox.min[2];

        posLBB = vec3.fromValues(leftX, backY, bottomZ);
        posLFB = vec3.fromValues(leftX, frontY, bottomZ);
        posLBT = vec3.fromValues(leftX, backY, topZ);
        posLFT = vec3.fromValues(leftX, frontY, topZ);

        posRBB = vec3.fromValues(rightX, backY, bottomZ);
        posRFB = vec3.fromValues(rightX, frontY, bottomZ);
        posRBT = vec3.fromValues(rightX, backY, topZ);
        posRFT = vec3.fromValues(rightX, frontY, topZ);

        // sometimes the pos left back bottom is not the world origin (0, 0, 0), so we need to store this translation vector when placing this item
        this._positionOffset = vec3.sub(vec3.create(), vec3.create(), posLBB);

        // add offset from pivot
        if (!vec3.exactEquals(vec3.create(), itemOrientation)) {
            // orient BBox 
            posLBB = _rotateVector(posLBB, itemOrientation);
            posLFB = _rotateVector(posLFB, itemOrientation);
            posLBT = _rotateVector(posLBT, itemOrientation);
            posLFT = _rotateVector(posLFT, itemOrientation);

            // right points
            posRBB = _rotateVector(posRBB, itemOrientation);
            posRFB = _rotateVector(posRFB, itemOrientation);
            posRBT = _rotateVector(posRBT, itemOrientation);
            posRFT = _rotateVector(posRFT, itemOrientation);
        }

        // add world translation
        vec3.add(posLBB, posLBB, itemWorldPosition);
        vec3.add(posLFB, posLFB, itemWorldPosition);
        vec3.add(posLBT, posLBT, itemWorldPosition);
        vec3.add(posLFT, posLFT, itemWorldPosition);

        vec3.add(posRBB, posRBB, itemWorldPosition);
        vec3.add(posRFB, posRFB, itemWorldPosition);
        vec3.add(posRBT, posRBT, itemWorldPosition);
        vec3.add(posRFT, posRFT, itemWorldPosition);


        this._posLeftBackBottom = posLBB;
        this._posLeftFrontBottom = posLFB;
        this._posLeftBackTop = posLBT;
        this._posLeftFrontTop = posLFT;

        this._posRightBackBottom = posRBB;
        this._posRightFrontBottom = posRFB;
        this._posRightBackTop = posRBT;
        this._posRightFrontTop = posRFT;

        return {
            posLBB, posLFB, posLBT, posLFT,
            posRBB, posRFB, posRBT, posRFT
        };
    }

    //=========================================================================
    private _getSlimConfigurationState(): IConsolidatedConfigurationState {
        // Filter out features that are not set by the user. Sources of type "default" and "automation" will be re-applied by the application when loading the design.
        let filteredConfigurationState: Array<IConsolidatedFeatureState> = this._configurationState.filter(featureState => featureState.source === "user"),
            configurationState: IConsolidatedConfigurationState = ObjectUtils.cloneObj(filteredConfigurationState) as IConsolidatedConfigurationState;

        for (let featureState of configurationState) {
            // Feature and Option classification is exclude for now... until classification schema is reviewed.
            delete featureState.featureClassification;
            delete featureState.optionClassification;

            // Since all serialized F&Os are source=="user". No need to serialize the source.
            delete featureState.source;
        }

        return configurationState;
    }

    //=========================================================================
    private _getFeatureState(featureCode: string): IConsolidatedFeatureState | undefined {
        let lcFeatureCode: string = featureCode.toLowerCase();
        return this.configurationState.find((featureState: IConsolidatedFeatureState) => featureState.featureId.toLowerCase().endsWith(lcFeatureCode));
    }

    //=========================================================================
    private _getConfiguredOptionId(featureCode: string): string | undefined {
        const featureState: IConsolidatedFeatureState | undefined = this._getFeatureState(featureCode);
        return featureState?.optionId;
    }

    //=========================================================================
    private _getConfiguredOptionValue(featureCode: string): number | undefined {
        const featureState: IConsolidatedFeatureState | undefined = this._getFeatureState(featureCode);
        return featureState?.value;
    }

    //=========================================================================
    private _createFingerprint(): string {
        let toFingerprint: string = ObjectUtils.generateClientItemKey({
            instanceId: this._instanceId,
            itemId: this._itemVariant.id,
            configurationState: this._configurationState,
            automations: this._automations
        });

        return md5(toFingerprint);
    }
}

//=============================================================================
function _rotateVector(vecToRotate: vec3, orientation: vec3): vec3 {
    let origin: vec3 = vec3.create();

    Array.from(orientation)
        .forEach((deg: number, idx: number) => {
            let rotateFunc: (out: vec3, a: vec3, b: vec3, rad: number) => vec3,
                rads: number = MeasurementUtils.degToRad(deg);

            if (idx === 0) {
                rotateFunc = vec3.rotateX;
            } else if (idx === 1) {
                rotateFunc = vec3.rotateY;
            } else {
                rotateFunc = vec3.rotateZ;
            }

            rotateFunc(vecToRotate, vecToRotate, origin, rads);
        });

    return vecToRotate;
}

export default DesignItem;

export function isWallItem(itemVariant: IConsolidatedItemVariant): boolean {
    let subType: string | undefined = itemVariant.classification?.characteristics?.subType?.toString();
    return subType?.includes("wall") ?? false;
}

export function isTallItem(itemVariant: IConsolidatedItemVariant): boolean {
    let subType: string | undefined = itemVariant.classification?.characteristics?.subType?.toString();
    return subType?.includes("tall") ?? false;
}