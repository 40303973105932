import React from 'react'
import { IIconProps } from 'Interfaces'

export function CabinetsIcon(props: IIconProps) {
    const width: number = props.width || 16;

    return (
        width < 20 
        ?
            <svg  width={props.width ?? 16} height={props.height ?? 16} viewBox="0 0 16 16" fill="none">
                <g clipPath="url(#clip0_1926_1720)">
                <path d="M9.33831 2.26785H6.67164V3.50316H9.33831V2.26785Z" fill="#AF9BF3"/>
                <path d="M5.1648 6.95706V9.62372H6.40011V6.95706H5.1648Z" fill="#AF9BF3"/>
                <path d="M9.70562 6.95706V9.62372H10.9409V6.95706H9.70562Z" fill="#AF9BF3"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M0 0V16L16 16V0H0ZM1.23077 5.7949V14.7693H7.4359V5.7949H1.23077ZM14.7692 1.23077H1.23077V4.56413H14.7692V1.23077ZM8.66667 14.7693L14.7692 14.7692V5.7949H8.66667V14.7693Z" fill="currentColor"/>
                </g>
                <defs>
                <clipPath id="clip0_1926_1720">
                <rect width="16" height="16" fill="white"/>
                </clipPath>
                </defs>
            </svg>
        :
            <svg width={props.width ?? 25} height={props.height ?? 24} viewBox="0 0 25 24" fill="none">
            <path d="M15 7.93927H14V11.9277H15V7.93927Z" fill="#AF9BF3"/>
            <path d="M10.8848 7.93927H9.88477V11.9277H10.8848V7.93927Z" fill="#AF9BF3"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M23 0.93335H2V21.979H23V0.93335ZM22.0455 6.94256H13V21.0218H22.0455V6.94256ZM11.8835 6.94256H2.95455V21.0218H11.8835V6.94256ZM2.95455 5.97874H22.0455V1.89058H2.95455V5.97874Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.8848 7.93927H9.88477V11.9277H10.8848V7.93927Z" fill="#AF9BF3"/>
            <path d="M14.5 4.43553V3.43842H10.5V4.43553H14.5Z" fill="#AF9BF3"/>
            </svg>
    )
}