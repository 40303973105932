import { CatalogBaseInfo, IConsolidatedCatalogBaseInfo } from "./CatalogBaseInfo";
import { Feature, IConsolidatedFeature } from "./Feature";
import { FeatureGroup, IConsolidatedFeatureGroup } from "./FeatureGroup";

export class ItemFeaturesFactory {
    static create(itemFeatures: V2.IItemFeatures | IGetItemFeaturesResult): IConsolidatedItemFeatures {
        return new ItemFeatures(itemFeatures).serialize();
    }
}

export class ItemFeatures {
    protected _features: Array<Feature>;
    protected _featureGroups?: Array<FeatureGroup>;
    protected _catalog?: CatalogBaseInfo;

    constructor(itemFeatures: V2.IItemFeatures | IGetItemFeaturesResult) {
        this._features = itemFeatures.features?.map((feature: V2.IFeature | IFeature) => new Feature(feature)) ?? [];
        this._featureGroups = itemFeatures.featureGroups?.map((featureGroup: IFeatureGroup | V2.IFeatureGroup) => new FeatureGroup(featureGroup));

        let catalogV2: ICatalogBaseInfo = (itemFeatures as V2.IItemFeatures).catalog;
        if (catalogV2) {
            this._catalog = new CatalogBaseInfo(catalogV2);
        }
    }

    serialize(): IConsolidatedItemFeatures {
        const itemFeatures: IConsolidatedItemFeatures = {
            features: this._features.map((feature: Feature) => feature.serialize()),
        };

        if (this._featureGroups !== undefined) {
            itemFeatures.featureGroups = this._featureGroups.map((featureGroup: FeatureGroup) => featureGroup.serialize());
        }

        if (this._catalog !== undefined) {
            itemFeatures.catalog = this._catalog.serialize();
        }

        return itemFeatures;
    }
}

export interface IConsolidatedItemFeatures {
    features: Array<IConsolidatedFeature>;
    featureGroups?: Array<IConsolidatedFeatureGroup>;
    catalog?: IConsolidatedCatalogBaseInfo;
}