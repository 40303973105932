import { Icons } from "@cyncly/design-system";
import { authoringHubState, StateModule } from "./authoringHubState";
import {
    CommandPaletteGroup,
    CommandPaletteStateEvent,
    defaultCommands,
} from "./defaultCommands";

export type State = {
    commands: Command[];
};

export type Command = {
    id: string;
    event: CommandPaletteStateEvent | string;
    label: string;
    icon: Icons;
    group: CommandPaletteGroup | string;
};

export type CommandGroup = {
    group: CommandPaletteGroup | string;
    commands: Command[];
};

class CommandPaletteState implements StateModule<State> {
    public state: State = {
        commands: defaultCommands
    };

    serialize() {
        return this.state;
    }

    addCommands(commands: Command[]) {
        commands.forEach(this.addCommand);
    }

    addCommand(command: Command) {
        if(!this.getCommand(command.id)) {
            this.state.commands.push(command);
        }
    }

    getCommand(id: string) {
        return this.state.commands.find((command) => command.id === id);
    }

    executeCommand(id: string) {
        const command = this.getCommand(id);
        if (!command) {
            return;
        }
        authoringHubState.notifyChange({ type: command.event });
    }

    getGroups() {
        const groups: CommandGroup[] = [];

        this.state.commands.forEach((command) => {
            const group = groups.find((group) => group.group === command.group);
            if (group) {
                group.commands.push(command);
            } else {
                groups.push({
                    group: command.group,
                    commands: [command],
                });
            }
        });

        groups.sort((a, b) => a.group > b.group ? 1 : -1 );

        return groups;
    }

    removeCommand(id: string) {
        this.state.commands = this.state.commands.filter(
            (command) => command.id !== id,
        );
    }
}

export const commandPaletteState = authoringHubState.registerModule(
    "commandPalette",
    new CommandPaletteState(),
) as CommandPaletteState;
