import { Entity } from "./EntityIOService";

export interface IEntitySchema {
    maxLength?: number;
    type?: string;
    required?: string[];
    additionalProperties?: boolean;
    properties?: Record<string, IEntitySchema>;
    maximum?: number;
    minimum?: number;
    items?: IEntitySchema;
}

export class SchemaEntityUtils {
    public static fromEntityInstance(
        entity: any,
        schema: any,
        includeEmptyValues: boolean = false
    ): Entity {
        let newEntity: any;

        if (!schema || (!includeEmptyValues && entity === undefined)) {
            return;
        }

        if (schema.anyOf) {
            const matchedSchema = getAnyOfSchema(schema, entity);
            if (matchedSchema) {
                return this.fromEntityInstance(
                    entity,
                    matchedSchema,
                    includeEmptyValues
                );
            }
        }

        switch (schema.type) {
            case "object":
                newEntity = {};
                Object.entries(schema.properties || {}).map(([key, value]) => {
                    if (includeEmptyValues || entity?.[key] !== undefined) {
                        newEntity[key] = this.fromEntityInstance(
                            entity?.[key],
                            value,
                            includeEmptyValues
                        );
                    }
                });
                if (schema.additionalProperties) {
                    newEntity = this.fromEntityInstance(
                        entity,
                        {
                            type: "object",
                            properties: this._getAdditionalProperties(
                                entity,
                                entity?.type
                            ),
                        },
                        includeEmptyValues
                    );
                }
                break;
            case "array":
                newEntity =
                    (typeof entity !== "string" &&
                        entity &&
                        entity.map((value: unknown) =>
                            this.fromEntityInstance(
                                value,
                                schema.items || "string",
                                includeEmptyValues
                            )
                        )) ||
                    undefined;
                break;
            case "string":
                newEntity = entity;
                break;
            case "boolean":
                newEntity =
                    typeof entity !== "undefined" ? Boolean(entity) : entity;
                break;
            case "number":
            case "integer":
                newEntity =
                    typeof entity !== "undefined" ? Number(entity) : entity;
                break;
            default:
                if (!schema.type && schema.additionalProperties) {
                    newEntity = this.fromEntityInstance(
                        entity,
                        {
                            type: "object",
                            properties: this._getAdditionalProperties(
                                entity,
                                schema.additionalProperties.type
                            ),
                        },
                        includeEmptyValues
                    );
                } else {
                    newEntity = entity;
                }
        }

        return newEntity;
    }

    public static updateProperty(
        entity: any,
        path: (string | number)[],
        value: any,
        schemaType?: string
    ) {
        const parentPath = [...path];
        const lastKey = parentPath.pop();

        let currentObj: any = entity;
        parentPath.forEach((key, index) => {
            const isLast = index === parentPath.length - 1;
            if (!currentObj[key]) {
                if (
                    (isLast && schemaType === "array") ||
                    typeof key !== "string"
                ) {
                    currentObj[key] = [];
                } else {
                    currentObj[key] = {};
                }
            }
            currentObj = currentObj[key];
        });

        currentObj[lastKey ?? ""] = value;

        return entity;
    }

    public static deleteProperty(
        entity: any,
        path: (string | number)[],
        dataType?: string
    ) {
        const parentPath = [...path];
        const lastKey = parentPath.pop();
        let prop = parentPath.reduce(
            (p: any, e: string | number) => p[e],
            entity
        );

        if (dataType == "object") {
            prop = JSON.parse(prop);
        } else if (dataType == "array") {
            prop = prop.split(",");
        }

        if (Array.isArray(prop)) {
            prop.splice(lastKey as number, 1);
            if (
                entity.defaultValue &&
                path[0] == "defaultValue" &&
                dataType == "array"
            ) {
                const cleanPath = [path[0]];
                entity = SchemaEntityUtils.updateProperty(
                    entity,
                    cleanPath,
                    prop.toString()
                );
            } else {
                if (!prop.length) {
                    entity = SchemaEntityUtils.deleteProperty(
                        entity,
                        parentPath
                    );
                }
            }
        } else {
            delete prop[lastKey!];
            if (!Object.keys(prop).length && parentPath.length > 1) {
                entity = SchemaEntityUtils.deleteProperty(entity, parentPath);
            } else if (
                entity.defaultValue &&
                path[0] == "defaultValue" &&
                dataType == "object"
            ) {
                const cleanPath = [path[0]];
                entity = SchemaEntityUtils.updateProperty(
                    entity,
                    cleanPath,
                    JSON.stringify(prop)
                );
            }
        }

        return entity;
    }

    public static getAllFields(
        schema: IEntitySchema,
        options: { type?: string } = {},
        parentPath: string = "",
        fields: string[] = []
    ): string[] {
        const addField = (schema: IEntitySchema, value: string) => {
            if (!options || !options.type || options.type === schema.type) {
                fields.push(value);
            }
        };

        if (schema.properties) {
            for (const [key, value] of Object.entries(schema.properties)) {
                const fieldPath = parentPath ? `${parentPath}.${key}` : key;
                addField(value, fieldPath);
                this.getAllFields(value, options, fieldPath, fields);
            }
        }

        if (Array.isArray(schema.items)) {
            for (const item of schema.items) {
                this.getAllFields(item, options, parentPath, fields);
            }
        } else if (schema.items && schema.items.properties) {
            this.getAllFields(schema.items, options, parentPath, fields);
        }

        return fields;
    }

    private static _getAdditionalProperties(
        entity: any,
        type: string
    ): Record<string, { type: string }> {
        const properties: Record<string, { type: string }> = {};
        if (entity) {
            Object.keys(entity || {}).forEach((key) => {
                properties[key] = {
                    type,
                };
            });
        }
        return properties;
    }
}

const ANYOF_KEYS = ["type", "method"];
function getAnyOfSchema(schema: ISchemaDef, entity: Entity) {
    return schema.anyOf!.find((s: any) => {
        const key =
            s.properties &&
            Object.keys(s.properties).find((k) => ANYOF_KEYS.includes(k));

        if (key && entity) {
            return s.properties[key].enum.includes(entity[key]);
        }
    });
}

export default SchemaEntityUtils;
