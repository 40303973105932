import React from 'react';
import { NavigationItem } from './navigationItems/NavigationItem';
import { CatalogMenuItem } from 'Interfaces';
import { HeaderNavigation } from '../header/HeaderNavigation';
import './sideNavigation.scss';

type SideNavigationProps = {
    catalogMenus: Array<CatalogMenuItem>,
    activeFilter: string,
    theme: string,
    baseUrl: string,
    tabIndex: number,
    contextualHelpEnabled: boolean,
    onFilterChanged(catalogMenu: CatalogMenuItem): void
};

export function SideNavigation(props: SideNavigationProps) {
    const { catalogMenus, activeFilter, theme, baseUrl, tabIndex, contextualHelpEnabled, onFilterChanged } = props;
    const handleClick = (catalogMenu: CatalogMenuItem) => { 
        onFilterChanged(catalogMenu);
    };    
    
    return (
        <div className={`side-navigation__container  `}>  
            <HeaderNavigation  
                theme={theme}
                baseUrl={baseUrl} 
            />

            <div className={`side-navigation__menus nice-scrolling`}>
                {catalogMenus.map((menu: CatalogMenuItem, index) => {                    
                    const isActive = menu.path === activeFilter;

                    return (                
                        <NavigationItem 
                            key={index} 
                            menu={menu}
                            isActive={isActive}
                            tabIndex={tabIndex + index}
                            contextualHelpEnabled={contextualHelpEnabled}
                            onClick={handleClick}                         
                        />
                    )
                })}
            </div>
        </div>        
    );
}