import { useContext, useEffect, useState } from 'react';
import { ContextualHelpContext } from '../contextualHelp/contextualHelpContext';

export function useContextualHelp(initialValue: boolean) {
    const [contextualHelpEnable, setContextualHelpEnable] = useState<boolean>(initialValue);
    const ctx = useContext(ContextualHelpContext);

    useEffect(() => {
        const onMsgReceived = (ev: MessageEvent<any>) => {
            if (ev.data.type === 'contextual-help') {
                setContextualHelpEnable(ev.data.isEnable);
            }
        };

        window.addEventListener('message', onMsgReceived);
        return () => window.removeEventListener('message', onMsgReceived);
    }, []);

    const toggleContextualHelp = (isEnable: boolean) => {
        update(isEnable);
    };

    return {
        contextualHelpEnable,
        toggleContextualHelp,
        ...ctx
    };
}

function update(isEnable: boolean) {
    window.postMessage(
        {
            type: 'contextual-help',
            isEnable: isEnable
        },
        '*'
    );
}