import { vec3 } from "gl-matrix";
import DesignItem from "./DesignItem";

export interface IConnectionNode {
    ref: DesignItem,
    connectionSide: CONNECTION_SIDE
    // connection points ?
}

export enum CONNECTION_SIDE {
    unclear = "unclear",
    left = "left",
    right = "right",
    top = "top",
    bottom = "bottom"
}

export enum REVERSE_CONNECTION_SIDE {
    unclear = "unclear",
    left = "right",
    right = "left",
    top = "bottom",
    bottom = "top"
}

//=========================================================================
export default class ConnectionMap {
    private _ownerItem: DesignItem;
    private _connectionNodesMap: Map<string, IConnectionNode>;

    //=========================================================================
    constructor(ownerItem: DesignItem) {
        this._ownerItem = ownerItem;
        this._connectionNodesMap = new Map();
    }

    //=========================================================================
    get connectionNodes() {
        return Array.from(this._connectionNodesMap.values()); // this._connectionNodes;
    }

    //=========================================================================
    detectConnections(itemList: Array<DesignItem>) {
        let epsilon: number = 800,
            ownPosRBB: vec3 = this._ownerItem.posRightBackBottom; // PosRBB => PosRightBackBottom

        // remove self from connected items
        this.connectionNodes.forEach((connectionNode: IConnectionNode) => {
            connectionNode.ref.connectionMap.disconnectItem(this._ownerItem);
        });

        this._connectionNodesMap.clear();

        itemList.forEach((comparingItem: DesignItem) => {
            if (comparingItem.instanceId !== this._ownerItem.instanceId) {
                let rightDistVector: vec3 = vec3.create(), 
                    leftDistVector: vec3 = vec3.create(),
                    rightDist: number,
                    leftDist: number,
                    isLeftConnected: boolean = false,
                    isRightConnected: boolean = false,
                    connectionSide: CONNECTION_SIDE = CONNECTION_SIDE.unclear;

                vec3.subtract(rightDistVector,
                    comparingItem.position, // head
                    ownPosRBB // tail
                );

                vec3.subtract(leftDistVector,
                    this._ownerItem.position, // head
                    comparingItem.posRightBackBottom // tail
                ); 

                rightDist = vec3.length(rightDistVector);
                leftDist = vec3.length(leftDistVector);

                // if ownPosRBB <-> position of comparing designItem <= 800
                isRightConnected = rightDist <= epsilon;
                if (isRightConnected) {
                    connectionSide = CONNECTION_SIDE.right;
                } else {
                    isLeftConnected = leftDist <= epsilon;
                    if (isLeftConnected) {
                        connectionSide = CONNECTION_SIDE.left;
                    }
                }

                if (isRightConnected || isLeftConnected) {
                    this.connectItem(comparingItem, connectionSide);
                }
            }
        });
    }

    //=========================================================================
    connectItem(designItem: DesignItem, connectionSide: CONNECTION_SIDE = CONNECTION_SIDE.unclear) {
        let reverseConnectionSide: CONNECTION_SIDE;

        if (!this.isItemConnected(designItem)) {
            this._connectionNodesMap.set(designItem.instanceId, {
                ref: designItem,
                connectionSide
            } as IConnectionNode);

            if (!designItem.connectionMap.isItemConnected(this._ownerItem)) {
                reverseConnectionSide = CONNECTION_SIDE[REVERSE_CONNECTION_SIDE[connectionSide]];
                designItem.connectionMap.connectItem(this._ownerItem, reverseConnectionSide);
            }
        }
    }

    //=========================================================================
    disconnectItem(designItem: DesignItem) {
        if (this._connectionNodesMap.has(designItem.instanceId)) {
            this._connectionNodesMap.delete(designItem.instanceId);
        }
    }

    //=========================================================================
    isItemConnected(designItem: DesignItem): boolean {
        return this._connectionNodesMap.has(designItem.instanceId);
    }

    //=========================================================================
    getConnectedItems(): Array<DesignItem> {
        let connectedItemsMap: Map<string, DesignItem> = new Map(),
            connectedItemList: Array<DesignItem>;

        this.crawlConnectedItems(this.connectionNodes, connectedItemsMap);
        connectedItemList = Array.from(connectedItemsMap.values()).filter((designItem: DesignItem) => designItem.instanceId !== this._ownerItem.instanceId);

        return connectedItemList;
    }

    //=========================================================================
    crawlConnectedItems(connectionNodes: Array<IConnectionNode>, connectedItemsMap: Map<string, DesignItem>) {
        connectionNodes.forEach((node: IConnectionNode) => {
            if (!connectedItemsMap.has(node.ref.instanceId)) {
                connectedItemsMap.set(node.ref.instanceId, node.ref);
                node.ref.connectionMap.crawlConnectedItems(node.ref.connectionMap.connectionNodes, connectedItemsMap);
            }
        });
    }
}