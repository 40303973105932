import React from 'react';
import { strings } from 'strings';
import { Step } from 'react-joyride';

export enum ContextualHelpStepsIndex {
    BROWSER_CATALOG = 0,
    BROWSER_SETTINGS = 1,
    BROWSER_CATALOG_SELECTOR = 2,
    BROWSER_FILTER_BUTTON = 3,
    BROWSER_FILTER_SORT = 4,
    BROWSER_FILTER_CATEGORY = 5,
    BROWSER_GROUPS = 6,
    BROWSER_SEARCH = 7,
    BROWSER_ADD_ITEM = 8,
    BROWSER_INFORMATION = 9,
    BROWSER_FAVORITE = 10,

    BROWSER_CATALOG_ALL = 11,
    BROWSER_CATALOG_CABINETRY = 12,
    BROWSER_CATALOG_SINKS = 13,
    BROWSER_CATALOG_FURNITURE = 14,
    BROWSER_CATALOG_APPLIANCE = 15,
    BROWSER_CATALOG_DECORATE = 16,
    BROWSER_CATALOG_STORAGE = 17,
    BROWSER_CATALOG_LIGHTING = 18,
    BROWSER_CATALOG_OFFICE = 19,
    BROWSER_CATALOG_OUTDOOR = 20,
    BROWSER_CATALOG_MATERIALS = 21,
    BROWSER_CATALOG_ARCHITECTURAL = 22,

    BROWSER_PRESENT_RESULTS = 23,
    BROWSER_THUMBNAIL_SIZE = 24,
    BROWSER_FILTERING_CATALOG = 25,
    BROWSER_CATALOG_OPTIONS = 26,
    BROWSER_CONTEXTUAL_HELP = 27,

    CONFIGURATOR_SETTINGS = 28,
    CONFIGURATOR_STYLES = 29,
    CONFIGURATOR_NB_ITEMS = 30,
    CONFIGURATOR_SHOW_COMMON = 31,
    CONFIGURATOR_CROSS_FEATURES = 32,
    CONFIGURATOR_SUB_ITEMS_MENU = 33,
    CONFIGURATOR_SUB_ITEMS_MODIFY = 34,
    CONFIGURATOR_SUB_ITEMS_RESET = 35,
    CONFIGURATOR_DIMENSIONS = 36,
    CONFIGURATOR_OPEN_CLOSE = 37,
    CONFIGURATOR_HANDLE = 38,
    CONFIGURATOR_ALTERNATIVE = 39,    
    
    CATALOG_PADLOCK = 40,
    CATALOG_MINUS = 41,
    CATALOG_PLUS = 42,
    CATALOG_ALL = 43,
    CATALOG_CABINETRY = 44,
    CATALOG_SINKS = 45,
    CATALOG_FURNITURE = 46,
    CATALOG_APPLIANCE = 47,
    CATALOG_DECORATE = 48,
    CATALOG_STORAGE = 49,
    CATALOG_LIGHTING = 50,
    CATALOG_OFFICE = 51,
    CATALOG_OUTDOOR = 52,
    CATALOG_MATERIALS = 53,
    CATALOG_ARCHITECTURAL = 54,
    CATALOG_SEARCH = 55,
    CATALOG_STATUS = 56,
    CATALOG_MANUFACTURER = 57,
    CATALOG_CLOSE = 58,
}

export const ContextualHelpSteps: Array<Step> = [
    // 0
    {
        target: ".step-browser-catalog",
        title: strings['contextualHelp.browser.catalog.title'],
        content: strings['contextualHelp.browser.catalog'],
        disableBeacon: true,
        placement: "bottom-end"
    },
    // 1
    {
        target: ".step-browser-settings",
        title: strings['contextualHelp.browser.settings.title'],
        content:
            <>
                {strings['contextualHelp.browser.settings']}
                <ul>
                <li>{strings['contextualHelp.browser.settings.listview']}</li>
                <li>{strings['contextualHelp.browser.settings.galleryview']}</li>
                <li>{strings['contextualHelp.browser.settings.thumbnailsize']}</li>
                <li>{strings['contextualHelp.browser.settings.sort']}</li>
                <li>{strings['contextualHelp.browser.settings.enableHelp']}</li>
                </ul>
            </>,
        disableBeacon: true,
        placement: "bottom-end"
    },
    // 2
    {
        target: ".step-browser-catalog-selector",
        title: strings['contextualHelp.browser.catalog.selector.title'],
        content: <>
                {strings['contextualHelp.browser.catalog.selector.line1']}<br/><br/>
                {strings['contextualHelp.browser.catalog.selector.line2']}<br/><br/>
                {strings['contextualHelp.browser.catalog.selector.line3']}
            </>,
        disableBeacon: true,
        placement: "bottom"
    },
    // 2
    {
        target: ".step-browser-filter",
        title: strings['contextualHelp.browser.filter.button.title'],
        content: <>
                {strings['contextualHelp.browser.filter.button.line1']}<br/><br/>
                {strings['contextualHelp.browser.filter.button.line2']}                
            </>,
        disableBeacon: true,
        placement: "bottom"
    },
    // 2
    {
        target: ".step-browser-filter-sort",
        title: strings['contextualHelp.browser.filter.sort.title'],
        content: <>
                {strings['contextualHelp.browser.filter.sort.line1']}
                <ul>
                <li>{strings['contextualHelp.browser.filter.sort.line2']}</li>
                <li>{strings['contextualHelp.browser.filter.sort.line3']}</li>
                <li>{strings['contextualHelp.browser.filter.sort.line4']}</li>
                <li>{strings['contextualHelp.browser.filter.sort.line5']}</li>
                <li>{strings['contextualHelp.browser.filter.sort.line6']}</li>
                </ul>
            </>,
        disableBeacon: true,
        placement: "right"
    },
    // 2
    {
        target: ".step-browser-filter-category",
        title: strings['contextualHelp.browser.filter.category.title'],
        content: <>
                {strings['contextualHelp.browser.filter.category.line1']}<br/><br/>
                {strings['contextualHelp.browser.filter.category.line2']}<br/><br/>
            </>,
        disableBeacon: true,
        placement: "right"
    },
    // 2
    {
        target: ".step-browser-groups",
        title: strings['contextualHelp.browser.groups.title'],
        content: <>
                {strings['contextualHelp.browser.groups.line1']}<br/><br/>
                {strings['contextualHelp.browser.groups.line2']}
            </>,
        disableBeacon: true,
        placement: "right"
    },
    // 3
    {
        target: ".step-browser-search",
        title: strings['contextualHelp.browser.search.title'],
        content:
            <>
                {strings['contextualHelp.browser.search.line1']}<br /><br />
                {strings['contextualHelp.browser.search.line2']}<br /><br />
                {strings['contextualHelp.browser.search.line3']}
            </>,
        disableBeacon: true
    },
    // 4
    {
        target: ".step-browser-add-item",
        title: strings['contextualHelp.browser.add.item.title'],
        content:
            <>
                <ul>
                <li>{strings['contextualHelp.browser.add.item.options.doubleClick']}</li>
                <li>{strings['contextualHelp.browser.add.item.options.dragDrop']}</li>
                <li>{strings['contextualHelp.browser.add.item.options.clickAndAdd']}</li>
                </ul>
            </>,
        disableBeacon: true
    },
    // 5
    {
        target: ".step-browser-information",
        title: strings['contextualHelp.browser.information.title'],
        content:
            <>
                {strings['contextualHelp.browser.information.line1']}<br /><br />
                {strings['contextualHelp.browser.information.line2']}
            </>,
        disableBeacon: true,
        placement: "right"
    },
    // 6
    {
        target: ".step-browser-favorite",
        title: strings['contextualHelp.browser.favorite.title'],
        content:
            <>
                {strings['contextualHelp.browser.favorite']}
                <ul>
                <li>{strings['contextualHelp.browser.favorite.options.starIdentification']}</li>
                <li>{strings['contextualHelp.browser.favorite.options.showFavorites']}</li>
                </ul>
            </>,
        disableBeacon: true,
        placement: "left-start"
    },

    {
        target: ".step-browser-catalog-all",
        title: strings['contextualHelp.browser.catalog.my.filter.title'],
        content: strings['contextualHelp.browser.catalog.my.filter'],
        disableBeacon: true,
        placement: "bottom-start"
    },
    //20
    {
        target: ".step-browser-catalog-cabinetry",
        title: strings['contextualHelp.browser.catalog.cabinetry.filter.title'],
        content: strings['contextualHelp.browser.catalog.cabinetry.filter'],
        disableBeacon: true,
        placement: "bottom-start"
    },
    //21
    {
        target: ".step-browser-catalog-sink",
        title: strings['contextualHelp.browser.catalog.sink.filter.title'],
        content: strings['contextualHelp.browser.catalog.sink.filter'],
        disableBeacon: true,
        placement: "bottom-start"
    },
    //22
    {
        target: ".step-browser-catalog-furniture",
        title: strings['contextualHelp.browser.catalog.furniture.filter.title'],
        content: strings['contextualHelp.browser.catalog.furniture.filter'],
        disableBeacon: true,
        placement: "bottom-start"
    },
    //23
    {
        target: ".step-browser-catalog-appliances",
        title: strings['contextualHelp.browser.catalog.appliances.filter.title'],
        content: strings['contextualHelp.browser.catalog.appliances.filter'],
        disableBeacon: true,
        placement: "bottom"
    },
    //24
    {
        target: ".step-browser-catalog-decorate",
        title: strings['contextualHelp.browser.catalog.decorate.filter.title'],
        content: strings['contextualHelp.browser.catalog.decorate.filter'],
        disableBeacon: true,
        placement: "bottom"
    },
    //3
    {
        target: ".step-browser-catalog-storage",
        title: strings['contextualHelp.browser.catalog.storage.filter.title'],
        content: strings['contextualHelp.browser.catalog.storage.filter'],
        disableBeacon: true,
        placement: "bottom"
    },
    //4
    {
        target: ".step-browser-catalog-lighting",
        title: strings['contextualHelp.browser.catalog.lighting.filter.title'],
        content: strings['contextualHelp.browser.catalog.lighting.filter'],
        disableBeacon: true,
        placement: "bottom"
    },
    //5
    {
        target: ".step-browser-catalog-office",
        title: strings['contextualHelp.browser.catalog.office.filter.title'],
        content: strings['contextualHelp.browser.catalog.office.filter'],
        disableBeacon: true,
        placement: "bottom-end"
    },
    //6
    {
        target: ".step-browser-catalog-outdoor",
        title: strings['contextualHelp.browser.catalog.outdoor.filter.title'],
        content: strings['contextualHelp.browser.catalog.outdoor.filter'],
        disableBeacon: true,
        placement: "bottom-end"
    },
    //7
    {
        target: ".step-browser-catalog-materials",
        title: strings['contextualHelp.browser.catalog.material.filter.title'],
        content: strings['contextualHelp.browser.catalog.material.filter'],
        disableBeacon: true,
        placement: "bottom-end"
    },
    //
    {
        target: ".step-browser-catalog-architectural",
        title: strings['contextualHelp.browser.catalog.architectural.filter.title'],
        content: strings['contextualHelp.browser.catalog.architectural.filter'],
        disableBeacon: true,
        placement: "bottom-end"
    },

//
{
    target: ".step-browser-present-results",
    title: strings['contextualHelp.browser.present.result.title'],
    content: strings['contextualHelp.browser.present.result'],
    disableBeacon: true,
    placement: "left-start"
},    
//
{
    target: ".step-browser-thumbnail-size",
    title: strings['contextualHelp.browser.thumbnail.size.title'],
    content: strings['contextualHelp.browser.thumbnail.size'],
    disableBeacon: true,
    placement: "left-start"
},
//
{
    target: ".step-browser-filtering-catalog",
    title: strings['contextualHelp.browser.filtering.catalog.title'],
    content: strings['contextualHelp.browser.filtering.catalog'],
    disableBeacon: true,
    placement: "left"
},
//
{
    target: ".step-browser-catalog-options",
    title: strings['contextualHelp.browser.catalog.options.title'],
    content: strings['contextualHelp.browser.catalog.options'],
    disableBeacon: true,
    placement: "left-end"
},
//
{
    target: ".step-browser-contextual-help",
    title: strings['contextualHelp.browser.contextual.help.title'],
    content: <>
                {strings['contextualHelp.browser.contextual.help.bloc1']}<br /><br />
                {strings['contextualHelp.browser.contextual.help.bloc2']}<br /><br />
                {strings['contextualHelp.browser.contextual.help.bloc3']}                
            </>,

    disableBeacon: true,
    placement: "left-end"
},



    // 
    {
        target: ".step-configurator-settings",
        title: strings['contextualHelp.configurator.settings.title'],
        content:
            <>
                {strings['contextualHelp.configurator.settings']}
                <ul>
                <li>{strings['contextualHelp.configurator.settings.options.listview']}</li>
                <li>{strings['contextualHelp.configurator.settings.options.galleryview']}</li>
                <li>{strings['contextualHelp.configurator.settings.options.thumbnailsize']}</li>                
                </ul>
            </>,
        disableBeacon: true,
        placement: "left-start"
    },
    // 8
    {
        target: ".step-configurator-styles",
        title: strings['contextualHelp.configurator.styles.title'],
        content: <>
            {strings['contextualHelp.configurator.styles.line1']}<br/><br/>
            {strings['contextualHelp.configurator.styles.line2']}            
            </>,
        disableBeacon: true,
        placement: "right-start"
    },  
    // 9
    {
        target: ".step-configurator-nb-items",
        title: strings['contextualHelp.configurator.items.count.title'],
        content: <>
            {strings['contextualHelp.configurator.items.count.line1']}<br/><br/>
            {strings['contextualHelp.configurator.items.count.line2']}<br/><br/>
            {strings['contextualHelp.configurator.items.count.line3']}
        </>,
        disableBeacon: true,
        placement: "right-start"
    },
    // 9
    {
        target: ".step-configurator-show-common",
        title: strings['contextualHelp.configurator.show.common.title'],
        content: <>
            {strings['contextualHelp.configurator.show.common.line1']}<br/><br/>
            {strings['contextualHelp.configurator.show.common.line2']}
        </>,
        disableBeacon: true,
        placement: "bottom-end"
    },
    // 9
    {
        target: ".step-configurator-cross-features",
        title: strings['contextualHelp.configurator.cross.features.title'],
        content: <>
            {strings['contextualHelp.configurator.cross.features']}
        </>,
        disableBeacon: true,
        placement: "bottom"
    },
    // 10
    {
        target: ".step-configurator-subitems-menu",
        title: strings['contextualHelp.configurator.subitems.menu.title'],
        content: strings['contextualHelp.configurator.subitems.menu'],
        disableBeacon: true,
        placement: "bottom-start"
    },
    // 12
    {
        target: ".step-configurator-subitems-modify",
        title: strings['contextualHelp.configurator.subitems.modify.title'],
        content: strings['contextualHelp.configurator.subitems.modify'],
        disableBeacon: true,
        placement: "left-start"
    },
    // 11
    {
        target: ".step-configurator-subitems-reset",
        title: strings['contextualHelp.configurator.subitems.reset.title'],
        content: strings['contextualHelp.configurator.subitems.reset'],
        disableBeacon: true,
        placement: "left"
    },
    
    
    
    // 13
    {
        target: ".step-configurator-dimensions",
        title: strings['contextualHelp.configurator.dimensions.title'],
        content: strings['contextualHelp.configurator.dimensions'],
        disableBeacon: true,
        placement: "bottom-start"
    },
    
    // 14
    {
        target: ".step-configurator-open-close",
        title: strings['contextualHelp.configurator.openclose.title'],
        content: strings['contextualHelp.configurator.openclose'],
        disableBeacon: true,
        placement: "bottom-start"
    },
    // 15
    {
        target: ".step-configurator-handle",
        title: strings['contextualHelp.configurator.handle.title'],
        content: strings['contextualHelp.configurator.handle'],
        disableBeacon: true,
        placement: "bottom-start"
    },
    //16
    {
        target: ".step-configurator-alternative",
        title: strings['contextualHelp.configurator.alternative.title'],
        content: strings['contextualHelp.configurator.alternative'],
        disableBeacon: true,
        placement: "bottom-start"
    },
    
    //17
    {
        target: ".step-catalog-padlock",
        title: strings['contextualHelp.catalog.padlock.title'],
        content: strings['contextualHelp.catalog.padlock'],
        disableBeacon: true,
        placement: "bottom"
    },
    //18
    {
        target: ".step-catalog-minus",
        title: strings['contextualHelp.catalog.minus.title'],
        content: strings['contextualHelp.catalog.minus'],
        disableBeacon: true,
        placement: "bottom"
    },
    //
    {
        target: ".step-catalog-plus",
        title: strings['contextualHelp.catalog.plus.title'],
        content: strings['contextualHelp.catalog.plus'],
        disableBeacon: true,
        placement: "bottom"
    },
    //
    {
        target: ".step-catalog-all",
        title: strings['contextualHelp.catalog.all.filter.title'],
        content: strings['contextualHelp.catalog.all.filter'],
        disableBeacon: true,
        placement: "right"
    },
    //20
    {
        target: ".step-catalog-cabinetry",
        title: strings['contextualHelp.catalog.cabinetry.filter.title'],
        content: strings['contextualHelp.catalog.cabinetry.filter'],
        disableBeacon: true,
        placement: "right"
    },
    //21
    {
        target: ".step-catalog-sink",
        title: strings['contextualHelp.catalog.sink.filter.title'],
        content: strings['contextualHelp.catalog.sink.filter'],
        disableBeacon: true,
        placement: "right"
    },
    //22
    {
        target: ".step-catalog-furniture",
        title: strings['contextualHelp.catalog.furniture.filter.title'],
        content: strings['contextualHelp.catalog.furniture.filter'],
        disableBeacon: true,
        placement: "right"
    },
    //23
    {
        target: ".step-catalog-appliances",
        title: strings['contextualHelp.catalog.appliances.filter.title'],
        content: strings['contextualHelp.catalog.appliances.filter'],
        disableBeacon: true,
        placement: "right"
    },
    //24
    {
        target: ".step-catalog-decorate",
        title: strings['contextualHelp.catalog.decorate.filter.title'],
        content: strings['contextualHelp.catalog.decorate.filter'],
        disableBeacon: true,
        placement: "right"
    },
    //25
    {
        target: ".step-catalog-storage",
        title: strings['contextualHelp.catalog.storage.filter.title'],
        content: strings['contextualHelp.catalog.storage.filter'],
        disableBeacon: true,
        placement: "right"
    },
    //26
    {
        target: ".step-catalog-lighting",
        title: strings['contextualHelp.catalog.lighting.filter.title'],
        content: strings['contextualHelp.catalog.lighting.filter'],
        disableBeacon: true,
        placement: "right"
    },
    //27
    {
        target: ".step-catalog-office",
        title: strings['contextualHelp.catalog.office.filter.title'],
        content: strings['contextualHelp.catalog.office.filter'],
        disableBeacon: true,
        placement: "right"
    },
    //28
    {
        target: ".step-catalog-outdoor",
        title: strings['contextualHelp.catalog.outdoor.filter.title'],
        content: strings['contextualHelp.catalog.outdoor.filter'],
        disableBeacon: true,
        placement: "right"
    },
    //28
    {
        target: ".step-catalog-materials",
        title: strings['contextualHelp.catalog.material.filter.title'],
        content: strings['contextualHelp.catalog.material.filter'],
        disableBeacon: true,
        placement: "right"
    },
    //29
    {
        target: ".step-catalog-architectural",
        title: strings['contextualHelp.catalog.architectural.filter.title'],
        content: strings['contextualHelp.catalog.architectural.filter'],
        disableBeacon: true,
        placement: "right"
    },
    //30
    {
        target: ".step-catalog-search",
        title: strings['contextualHelp.catalog.search.title'],
        content: strings['contextualHelp.catalog.search'],
        disableBeacon: true,
        placement: "bottom-start"
    },
    //31
    {
        target: ".step-catalog-status",
        title: strings['contextualHelp.catalog.status.title'],
        content: <>
            {strings['contextualHelp.catalog.status.line1']}
            <ul>                
                <li>{strings['contextualHelp.catalog.status.line2']}</li>
                <li>{strings['contextualHelp.catalog.status.line3']}</li>
                <li>{strings['contextualHelp.catalog.status.line4']}</li>
                <li>{strings['contextualHelp.catalog.status.line5']}</li>
                <li>{strings['contextualHelp.catalog.status.line6']}</li>
                <li>{strings['contextualHelp.catalog.status.line7']}</li>
            </ul>
        </>
        ,
        disableBeacon: true,
        placement: "bottom-start"
    },
    //32
    {
        target: ".step-catalog-manufacturer",
        title: strings['contextualHelp.catalog.manufacturer.title'],
        content: strings['contextualHelp.catalog.manufacturer'],
        disableBeacon: true,
        placement: "bottom-start"
    },
    //33
    {
        target: ".step-catalog-close",
        title: strings['contextualHelp.catalog.close.title'],
        content: strings['contextualHelp.catalog.close'],
        disableBeacon: true,
        placement: "top-start"
    }
];