export class FeatureOptionCharacteristicsFactory {
    static create(featureOptionCharacteristics: IFeatureOptionCharacteristics): IConsolidatedFeatureOptionCharacteristics {
        return new FeatureOptionCharacteristics(featureOptionCharacteristics).serialize();
    }
}
    
export class FeatureOptionCharacteristics {
    protected _positionVertical?: CharacteristicPositionVertical;
    protected _positionHorizontal?: CharacteristicPositionHorizontal;
    protected _orientation?: CharacteristicOrientation;
    protected _side?: CharacteristicSide;
    protected _toggle?: CharacteristicToggle;

    constructor(featureOptionCharacteristics: IFeatureOptionCharacteristics) {
        this._positionVertical = featureOptionCharacteristics.positionVertical;
        this._positionHorizontal = featureOptionCharacteristics.positionHorizontal;
        this._orientation = featureOptionCharacteristics.orientation;
        this._side = featureOptionCharacteristics.side;
        this._toggle = featureOptionCharacteristics.toggle
    }

    serialize(): IConsolidatedFeatureOptionCharacteristics {
        const featureOptionCharacteristics: IConsolidatedFeatureOptionCharacteristics = {};

        if (this._positionVertical !== undefined) {
            featureOptionCharacteristics.positionVertical = this._positionVertical;
        }

        if (this._positionHorizontal !== undefined) {
            featureOptionCharacteristics.positionHorizontal = this._positionHorizontal;
        }

        if (this._orientation !== undefined) {
            featureOptionCharacteristics.orientation = this._orientation;
        }

        if (this._side !== undefined) {
            featureOptionCharacteristics.side = this._side;
        }

        if (this._toggle !== undefined) {
            featureOptionCharacteristics.toggle = this._toggle;
        }

        return featureOptionCharacteristics;
    }
}

export interface IConsolidatedFeatureOptionCharacteristics {
    positionVertical?: CharacteristicPositionVertical;
    positionHorizontal?: CharacteristicPositionHorizontal;
    orientation?: CharacteristicOrientation;
    side?: CharacteristicSide;
    toggle?: CharacteristicToggle;
}