import { useCallback, useContext, useEffect } from "react";
import { useExplorerState } from "../../state/useExplorerState";
import { CatalogExplorerContext } from "./CatalogExplorerContext";
import { useTabState } from "../../state/useTabState";
import { EntityType } from "..";
import { Entity, authoringStateMgr } from "../..";

export enum ExplorerRoutes {
    NEW_ENTITY = "cah_new_entity",
};
export function useNavigation() {
    const { explorerId } = useContext(CatalogExplorerContext);
    const { setPath, tabs } = useExplorerState();
    const { currentTab, openTab } = useTabState(explorerId);

    const navigate = useCallback(
        (
            path: string[] = [],
            label?: string,
            options?: { newTab?: boolean; partialPath?: boolean }
        ) => {
            const { newTab, partialPath } = options || {};

            let id = currentTab;
            if (newTab || !currentTab) {
                id = openTab({
                    id,
                    label: label || path?.at(-1) || "",
                });
            }

            getFullPath(path, tabs[currentTab]?.path || [], partialPath).then(
                (fullPath) => {
                    setPath(id, fullPath || [], false, label);
                }
            );
        },
        [currentTab, explorerId]
    );

    useEffect(() => {
        const onMsgReceived = (msg: any) => {
            if(msg.data.type === "new-entity") {
                const {
                    type,
                    catalogId,
                    group,
                } = msg.data.data;

                const path = [catalogId, type];
                if(group) {
                    path.push(group.code);
                }

                path.push(ExplorerRoutes.NEW_ENTITY);

                navigate(path, ExplorerRoutes.NEW_ENTITY);
            }
        };

        window.addEventListener("message", onMsgReceived);
        return () => {
            window.removeEventListener("message", onMsgReceived);
        };
    }, [navigate]);

    return {
        navigate,
    };
}

async function getFullPath(
    path: string[] | null,
    currentPath: string[],
    partialPath?: boolean
) {
    if (!partialPath && path?.length === 1 && path[0].includes(".")) {
        const [catalogId, code] = path[0].split(".");
        const types = Object.values(EntityType);

        const pathData: { entity?: Entity; type?: EntityType } = {};
        for (let i = 0; i < types.length; i++) {
            const type = types[i];

            const resp = await authoringStateMgr.entities.fetchEntity(
                type,
                code,
                catalogId
            );
            if (resp.entity) {
                pathData.entity = resp.entity;
                pathData.type = type;
                console.log(pathData);
                break;
            }
        }

        if (pathData.entity) {
            return [catalogId, pathData.type, path[0]] as string[];
        }
    }

    return partialPath && path ? [...currentPath, ...path] : path;
}
