import React from 'react'
import { IIconProps } from 'Interfaces'

export function LightingIcon(props: IIconProps) {
    const width: number = props.width || 16;

    return (
        width < 20 
        ?
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
            <g clipPath="url(#clip0_1926_1690)">
            <path d="M6.03846 1.23077C5.69877 1.23077 5.42308 1.50646 5.42308 1.84615V3.68738C5.27477 3.68738 5.132 3.74031 5.01938 3.83692L0.711692 7.52923C0.58 7.64185 0.506769 7.80492 0.5 7.97785V10.4862C0.513538 10.8166 0.781231 11.0818 1.11538 11.0818H4.80769C4.81077 13.112 6.46923 14.7692 8.5 14.7692C10.5308 14.7692 12.1892 13.112 12.1923 11.0818H15.8846C16.2188 11.0818 16.4865 10.8166 16.5 10.4862V7.97785C16.4932 7.80554 16.42 7.64185 16.2883 7.52923L11.9806 3.83692C11.868 3.74031 11.7252 3.68738 11.5769 3.68738V1.84615C11.5769 1.50646 11.3012 1.23077 10.9615 1.23077H9.11539V0H7.88462V1.23077H6.03846ZM8.5 13.5385C7.14923 13.5385 6.04154 12.432 6.03908 11.0818H10.9609C10.9585 12.432 9.85077 13.5385 8.5 13.5385ZM15.2643 8.28554V9.84123H1.73569V8.28554L5.65262 4.928H11.3474L15.2643 8.28554ZM6.65385 3.68738V2.46154H10.3462V3.68738H6.65385Z" fill="currentCOlor"/>
            <path d="M8.5 13.5385C9.85077 13.5385 10.9585 12.432 10.9609 11.0818H6.03908C6.04154 12.432 7.14923 13.5385 8.5 13.5385Z" fill="#CEF2FF"/>
            </g>
            <defs>
            <clipPath id="clip0_1926_1690">
            <rect width="16" height="16" fill="white" transform="translate(0.5)"/>
            </clipPath>
            </defs>
            </svg>
        :
            <svg width={props.width ?? 24} height={props.height ?? 24} viewBox="0 0 48 48" fill="none">
            <path d="M18.24 5.76C17.7101 5.76 17.28 6.19008 17.28 6.72V13.4323H17.2234C17.0266 13.4323 16.8336 13.4928 16.6723 13.6051L0.40896 24.8851C0.16512 25.0531 0.0192 25.3238 0 25.6166V31.7174C0.02112 32.233 0.43872 32.6467 0.96 32.6467H11.569C12.0682 39.0643 17.4605 44.16 24 44.16C30.5395 44.16 35.9318 39.0643 36.431 32.6477H47.04C47.5613 32.6477 47.9789 32.2339 48 31.7184V25.6176C47.9808 25.3248 47.8349 25.0541 47.591 24.8851L31.3277 13.6051C31.1654 13.4928 30.9734 13.4323 30.7766 13.4323H30.72V6.72C30.72 6.19008 30.2899 5.76 29.76 5.76H24.9792V0H23.0592V5.76H18.24ZM24 42.24C18.5203 42.24 13.9824 38.0045 13.489 32.6477H34.511C34.2032 35.9957 32.3143 38.9057 29.6037 40.6174C27.9773 41.6444 26.0549 42.24 24 42.24ZM46.0723 26.1869V30.7123H1.92768V26.1869L17.5258 15.3677H30.4742L46.0723 26.1869ZM19.2 13.4323V7.68H28.8V13.4323H19.2Z" fill="currentColor"/>
            <path d="M24 42.24C26.0549 42.24 27.9773 41.6444 29.6037 40.6174C32.3143 38.9057 34.2032 35.9957 34.511 32.6477H13.489C13.9824 38.0045 18.5203 42.24 24 42.24Z" fill="#CEF2FF"/>
            </svg>
    )
}
