import { Change, StateDeletedEntity, StateEntity } from "./authoringGroupsState.type";
import { StatesManagementBase } from "./statesManagementBase";

export class StatesManagement extends StatesManagementBase {

    public load = (id: string, groupType: string, entities: any[]) => {
        this._load(id, groupType, entities);
        this._notifyChange();
    }

    public isChild = (code: string, group: StateEntity) => {
        return this._isChild(code, group);
    }

    public getCurrentGroupStructure = (id: string, groupType: string, code: string) => {
        const group = this._findGroup(code, this._states._current[id][groupType].index);
        if (group) {
            return this._getStructure(group);
        }
    }

    public getCurrentDeletedGroup = (id: string, groupType: string, code: string) => {
        return this._states._deleted[id][groupType][code];
    }

    public getCurrentGroup = (id: string, groupType: string, code: string) => {
        if (id in this._states._current && groupType in this._states._current[id]) {
            return this._findGroup(code, this._states._current[id][groupType].index);
        }
    }

    public getCurrentEntity = (id: string, groupType: string, code: string) => {
        const group = this._findGroup(code, this._states._current[id][groupType].index);
        if (group) {
            return this._groupAsEntity(id, group);
        }
    }

    public getOriginalEntity = (id: string, groupType: string, code: string) => {
        const group = this._findGroup(code, this._states._original[id][groupType].index);
        if (group) {
            return this._groupAsEntity(id, group);
        }
    }

    public getCurrentEntities = (id: string, groupType: string) => {
        let groups = [];
        for (const group in this._states._current[id][groupType].groups) {
            groups.push(this._groupAsEntity(id, this._states._current[id][groupType].groups[group]));
        }
        return groups;
    }

    public new = (id: string, groupType: string, entity: any, isNew?: boolean) => {
        const group = this._createGroup(entity, isNew !== undefined ? isNew : true);
        this._states._current[id][groupType].groups[entity.code] = group;
        this._indexAdd(group, this._states._current[id][groupType].index);
        this._notifyChange();
    }

    public newSubgroup = (id: string, groupType: string, entity: any, parent: StateEntity, isNew?: boolean) => {
        if (!parent.subgroups) {
            parent.subgroups = { };
        }
        const group = this._createGroup(entity, isNew !== undefined ? isNew : true, parent);
        parent.subgroups[entity.code] = group;
        this._indexAdd(group, this._states._current[id][groupType].index);
        this._notifyChange();
    }

    public move = (id: string, groupType: string, source: StateEntity, target: StateEntity) => {

        const updateLevel = (group: StateEntity, level: number) => {
            group.level = level;
            if (group.subgroups) {
                for (const sub in group.subgroups) {
                    updateLevel(group.subgroups[sub], level + 1);
                }
            }
        }

        const sourceParent = source.parent;

        if (sourceParent) {
            delete sourceParent.subgroups![source.entity.code];
            source.parent = undefined;
        }
        else {
            delete this._states._current[id][groupType].groups[source.entity.code];
        }

        if (!target.subgroups) {
            target.subgroups = { };
        }

        target.subgroups[source.entity.code] = source;
        source.parent = target;

        updateLevel(source, target.level + 1);

        this._notifyChange();

    }

    public delete = (id: string, groupType: string, source: StateEntity, target?: StateEntity) => {

        const sourceParent = source.parent;

        if (sourceParent) {
            delete sourceParent.subgroups![source.entity.code];
            if (Object.keys(sourceParent.subgroups!).length === 0) {
                sourceParent.subgroups = undefined;
            }
        }
        else {
            delete this._states._current[id][groupType].groups[source.entity.code];
        }

        this._indexDelete(source, this._states._current[id][groupType].index);

        for (const deleted in this._states._deleted[id][groupType]) {
            if (this._isChild(this._states._deleted[id][groupType][deleted].entity.code, source)) {
                this._states._deleted[id][groupType][deleted].target = target;
            }
            else if (this._states._deleted[id][groupType][deleted].target && this._isChild(this._states._deleted[id][groupType][deleted].target!.entity.code, source)) {
                this._states._deleted[id][groupType][deleted].target = target;
            }
        }

        let deleted = source as StateDeletedEntity;
        deleted.target = target;
        this._states._deleted[id][groupType][source.entity.code] = deleted;

        this._notifyChange();

    }

    public getMovedGroups = (id: string, groupType: string) => {

        let movedList: Change[] = [];

        const originalStructure = this._getOriginalStructure(id, groupType);
        originalStructure.forEach((original) => {
            if (!this._isDeletedFromCurrent(id, groupType, original.entity.code)) {
                const current = this._findGroup(original.entity.code, this._states._current[id][groupType].index);
                if (current?.parent) {
                    if (original.parent) {
                        if (original.parent.entity.code !== current.parent.entity.code) {
                            movedList.push({
                                code: current.entity.code,
                                targetCode: current.parent.entity.code
                            });
                        }
                        else {
                            //no changes
                        }
                    }
                    else {
                        movedList.push({
                            code: current.entity.code,
                            targetCode: current.parent.entity.code
                        });
                    }
                }
                else {
                    if (original.parent) {
                        movedList.push({
                            code: current!.entity.code,
                            targetCode: undefined
                        });
                    }
                    else {
                        //no changes
                    }
                }
            }
        });

        return movedList;

    }

    public getDeletedGroups = (id: string, groupType: string) => {

        let deletedList: Change[] = [];

        const originalStructure = this._getOriginalStructure(id, groupType);
        originalStructure.forEach((original) => {

            if (this._isDeletedFromCurrent(id, groupType, original.entity.code)) {

                if (original.parent) {

                    const isParentDeleted = this._isDeletedFromCurrent(id, groupType, original.parent.entity.code);
                    if (!isParentDeleted) {
                        deletedList.push({
                            code: original.entity.code,
                            targetCode: this._states._deleted[id][groupType][original.entity.code].target?.entity.code
                        });
                    }

                }
                else {
                    deletedList.push({
                        code: original.entity.code,
                        targetCode: this._states._deleted[id][groupType][original.entity.code].target?.entity.code
                    });
                }

            }
            
        });

        return deletedList;

    }

    public getChanges = (id: string, groupType: string) => {

        let list: Change[] = [];

        const currentStructure = this._getCurrentStructure(id, groupType);

        currentStructure.forEach((current) => {
            if (current.isNew) {
                if (!current.parent || (current.parent && !current.parent.isNew)) {
                    list.push({
                        code: current.entity.code,
                        targetCode: current.parent ? current.parent.entity.code : undefined
                    });
                }
            };
        });

        return {
            new: list,
            moved: this.getMovedGroups(id, groupType),
            deleted: this.getDeletedGroups(id, groupType)
        }
    }

    public hasDirectChanges = (id: string, groupType: string, group: StateEntity) => {
        const original = this._findGroup(group.entity.code, this._states._original[id][groupType].index);
        return group.isNew || (original?.parent?.entity.code !== group.parent?.entity.code);
    }

    public applyNewChange = (id: string, groupType: string, current: StateEntity) => {

        let group: StateEntity | undefined;
        const entity = this._groupAsEntity(id, current);

        const applyCurrent = (current: StateEntity) => {
            current.isNew = false;
            if (current.subgroups) {
                for (const subgroup in current.subgroups) {
                    applyCurrent(current.subgroups[subgroup]);
                }
            }
        }

        if (current.parent) {
            const parent = this._findGroup(current.parent.entity.code, this._states._original[id][groupType].index);
            if (parent) {
                if (!parent.subgroups) {
                    parent.subgroups = { };
                }
                group = this._createGroup(entity.getGroupStateTree(), false, parent);
                parent.subgroups[entity.code] = group;
                applyCurrent(current);
            }
        }
        else {
            group = this._createGroup(entity.getGroupStateTree(), false);
            this._states._original[id][groupType].groups[entity.code] = group;
            applyCurrent(current);
        }

        if (group) {
            this._indexAdd(group, this._states._original[id][groupType].index);
            this._notifyChange();
        }

    }

    public applyDeleteChange = (id: string, groupType: string, deleted: StateDeletedEntity) => {

        if (deleted.parent) {
            const parent = this._findGroup(deleted.parent.entity.code, this._states._original[id][groupType].index);
            if (parent) {
                if (parent.subgroups) {
                    delete parent.subgroups[deleted.entity.code];
                    if (Object.keys(parent.subgroups).length === 0) {
                        parent.subgroups = undefined;
                    }
                }
            }
        }
        else {
            delete this._states._original[id][groupType].groups[deleted.entity.code];
        }

        for (const deletedCode in this._states._deleted[id][groupType]) {
            if (this._isChild(this._states._deleted[id][groupType][deletedCode].entity.code, deleted)) {
                delete this._states._deleted[id][groupType][deletedCode];
            }
        }

        this._indexDelete(deleted, this._states._original[id][groupType].index);
        this._notifyChange();

    }

    public revert = (id: string, groupType: string, code: string) => {
        if (this._isDeletedFromCurrent(id, groupType, code)) {
            const original = this._findGroup(code, this._states._original[id][groupType].index);
            if (original!.parent) {
                if (!this._isDeletedFromCurrent(id, groupType, original!.parent.entity.code)) {

                    const currentParent = this._findGroup(original!.parent.entity.code, this._states._current[id][groupType].index);
                    const entity = this._groupAsEntity(id, original!).getGroupStateTree(true);
                    const group = this._createGroup(entity, false, currentParent);
    
                    if (!currentParent!.subgroups) {
                        currentParent!.subgroups = { };
                    }
                    currentParent!.subgroups[code] = group;

                    this._indexAdd(group, this._states._current[id][groupType].index);
                    this._notifyChange();
                }
            }
            else {
                const entity = this._groupAsEntity(id, original!).getGroupStateTree(true);
                const group = this._createGroup(entity, false);

                this._states._current[id][groupType].groups[entity.code] = group;
                this._indexAdd(group, this._states._current[id][groupType].index);
                this._notifyChange();
            }

        }
        else {
            const current = this._findGroup(code, this._states._current[id][groupType].index);
            if (current && current.isNew) {
                this.delete(id, groupType, current);
            }
        }
    }
}

