import React, { useContext, MutableRefObject, useRef }  from 'react';
import { ActionButton } from 'actionButton/ActionButton';
import { ContextualHelpContext } from 'contextualHelp/contextualHelpContext';
import { ContextualHelpStepsIndex } from 'contextualHelp/ContextualHelpSteps';
import { strings } from 'strings';
import './catalogSettingsAction.scss';

type CatalogSettingsActionProps = {    
    nbrCatalogs: number,
    isLoading: boolean,    
    disabled: boolean,
    tabIndex: number,
    onApplyChanged(action:string): void  
}

export function CatalogSettingsAction(props: CatalogSettingsActionProps) {
    const { showContextualHelp } = useContext(ContextualHelpContext),

        handleClick = () => {
            props.onApplyChanged('update');
        },
        handleMouseOverClose = (elementRef: React.RefObject<HTMLButtonElement>) => {
            showContextualHelp(ContextualHelpStepsIndex.CATALOG_CLOSE, elementRef);
        };
        
    return (
        <div className={`catalog-action__container  `}> 
            {!navigator.onLine && 
                <span className='offline-alert__box'>{strings['offline.warning']}</span>
            }
            {!props.isLoading && 
                <div className={`catalog-action__catalogs-available  `}>
                    {buildCatalogNumberLabel(props.nbrCatalogs)}
                </div>
            }
            
            <ActionButton                 
                label={strings['catalog.action.ok']}   
                size='small'  
                variant='primary'  
                disabled={props.disabled} 
                tabIndex={props.tabIndex}            
                targetClassName='step-catalog-close'                   
                onClick={handleClick} 
                onMouseEnter={handleMouseOverClose}                                    
            />             
        </div>
    );
}

//=========================================================================================================================
function buildCatalogNumberLabel(nbrCatalogs: number): string {
    let label: string = "";    

   if (nbrCatalogs === 0) {
        label = strings['catalogs.settings.results.zero']

    } else if (nbrCatalogs === 1) {
        label = strings['catalogs.settings.results.one'];
    } else {
        label = `${nbrCatalogs} ${strings['catalogs.settings.results.many']} `;
    }

    return label;
}


