import React, { useEffect, useState, useContext, useRef } from 'react';
import { Helpers } from 'Helpers';
import { ICatalogPlus } from 'Interfaces';
import { PlusIcon, MinusIcon, LockIcon } from 'icons/';
import { ActionButton } from 'actionButton/ActionButton';
import { ContextualHelpContext } from 'contextualHelp/contextualHelpContext';
import { ContextualHelpStepsIndex } from 'contextualHelp/ContextualHelpSteps';
import parse from 'html-react-parser';
import { strings } from 'strings';
import './catalogCard.scss';

type CatalogCardProps = {
    catalog: ICatalogPlus,
    hiddenCatalogCodes: Array<string>,
    isHidden: boolean,    
    tabIndex: number,
    isOnline: boolean,
    borderRight: boolean,
    borderTop: boolean,
    baseUrl: string,
    onCatalogAction?(action: string): void,
    onCatalogVisibilityChanged(catalog: ICatalogPlus): void
    onRequestCatalogAccess(catalog: ICatalogPlus): void
}

export function CatalogCard(props: CatalogCardProps) {
    const catalog: ICatalogPlus = props.catalog,
        { showContextualHelp } = useContext(ContextualHelpContext),
        targetElementRef = useRef<HTMLButtonElement | null>(null),
        [isHovered, setHovered] = useState<boolean>(false),
        [isHidden, setIsHidden] = useState<boolean>(props.isHidden),
        [isRestricted, setIsRestricted] = useState<boolean>(catalog.accessStatus === "RequestRequired"),
        [hasBeenRequested, setHasBeebnRequested] = useState<boolean>(catalog.accessStatus === "Requested"),
        [hasReturnError, setHasReturnError] = useState<boolean>(catalog.accessStatus === "Error"),
        [isPending, setIsPending] = useState<boolean>(catalog.accessStatus === 'Pending'),
        [isDenied, setIsDenied] = useState<boolean>(catalog.accessStatus === 'Denied'),
        [isInfoPanelOpen, setInfoPanelOpen] = useState<boolean>(false),
        imageContent: JSX.Element = Helpers.buildImageContent(catalog.image?.uri || '', null, 'item-card__no-image', props.baseUrl, undefined, 'no-catalog-thumbnail.png'),
        //logoContent: JSX.Element = Helpers.buildImageContent(catalog.altImages?.find((image: any) => image.tags.includes('logo'))?.uri || '', null, 'item-card__no-image', props.baseUrl, undefined, catalog.companyDisplayName),
        bigBoxClassName: string = catalog.accessStatus === "RequestRequired" ? 'big-box' : '',
        tooltipText: string = displayToolTip(isRestricted, isPending, isDenied, isHidden, hasBeenRequested, hasReturnError, strings),
        borderRight: string = props.borderRight ? 'borderRight' : '',
        borderTop: string = props.borderTop ? 'borderTop' : '', 
        

        handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            event.preventDefault();
            openInfoPanel();
            //showContextualHelp(undefined);

            if (!isRestricted && catalog.accessStatus !== 'Pending' && catalog.accessStatus !== 'Denied' && catalog.accessStatus !== 'Error' && catalog.accessStatus !== 'Requested') {
                props.onCatalogVisibilityChanged(catalog);
                setIsHidden(!isHidden);
            }
        },
        openInfoPanel = () => {
            if (isRestricted) {
                setInfoPanelOpen(!isInfoPanelOpen);
            }
            if (!isRestricted) {
                setInfoPanelOpen(true);
                setTimeout(() => {
                    setInfoPanelOpen(false);
                }, 3000);
            }
        },
        handleActionConfirm = () => {
            if (isRestricted) {
                //click for Request access
                props.onRequestCatalogAccess(catalog);

            };
            setInfoPanelOpen(false);
        },
        handleMouseOver = () => {
            setTimeout(() => {
                setHovered(true);
            }, 350);
        },
        handleMouseOut = () => {
            setTimeout(() => {
                setHovered(false);
            }, 350);
        },
        toggleHandlers = {
            onMouseOver: handleMouseOver,
            onMouseOut: handleMouseOut
        },
        handleMouseButtonOver = () => {
            showContextualHelp((isRestricted || isDenied || isPending || hasBeenRequested || hasReturnError) ? 
                ContextualHelpStepsIndex.CATALOG_PADLOCK : isHidden ? 
                ContextualHelpStepsIndex.CATALOG_PLUS : ContextualHelpStepsIndex.CATALOG_MINUS, 
                targetElementRef, props.tabIndex);
        };

    useEffect(() => {
        if (props.catalog.accessStatus === 'RequestRequired') {
            setIsRestricted(true);
            setHasBeebnRequested(false);
            setHasReturnError(false);
            setIsPending(false);
            setIsDenied(false);
        } else if (props.catalog.accessStatus === 'Requested') {
            setIsRestricted(false);
            setHasBeebnRequested(true);
            setHasReturnError(false);
            setIsPending(false);
            setIsDenied(false);
        } else if (props.catalog.accessStatus === 'Error') {
            setIsRestricted(false);
            setHasBeebnRequested(false);
            setHasReturnError(true);
            setIsPending(false);
            setIsDenied(false);
        } else if (props.catalog.accessStatus === 'Pending') {
            setIsRestricted(false);
            setHasBeebnRequested(false);
            setHasReturnError(false);
            setIsPending(true);
            setIsDenied(false);
        } else if (props.catalog.accessStatus === 'Denied') {
            setIsRestricted(false);
            setHasBeebnRequested(false);
            setHasReturnError(false);
            setIsPending(false);
            setIsDenied(true);
        }
    }, [props.catalog.accessStatus]);

    useEffect(() => {
        setIsHidden(props.isHidden);
    }, [props.isHidden]);


    return (
        <div id={catalog.code}
            className={`catalog-card__container  ${borderRight} ${borderTop}  `}
            {...toggleHandlers}
        >

            <div className='catalog-card__image-container'>
                {imageContent}
            </div>
            <div className={`catalog-card__name-and-logo  `}>
                <div className={`catalog-card__name  `}>
                    {catalog.name}
                </div>
                {/*<div className='catalog-card__logo'>
                    {logoContent}
                </div>*/}
            </div>
            <div className={`catalog-card__description  `}>
                {!isInfoPanelOpen &&
                    <p>{catalog.description}</p>
                }
            </div>

            {isInfoPanelOpen &&
                <div className={`catalog-card__confirm ${bigBoxClassName}  `}>
                    <div className='catalog-card__confirm-text'>{parse(tooltipText)}</div>
                    {isRestricted &&
                        <div className='catalog-card__confirm-btn'>
                            <ActionButton
                                label={'Confirm'}
                                tabIndex={props.tabIndex}
                                onClick={handleActionConfirm}
                            />
                        </div>
                    }
                    <div id="catalog-card__confirm-pointer"></div>
                </div>
            }
            <button className={`catalog-card__add-button ${isRestricted || isHidden ? 'is-active' : hasReturnError ? 'is-error' : ''} ${(isRestricted || isDenied || isPending || hasBeenRequested || hasReturnError) ? 'step-catalog-padlock_'+ props.tabIndex : isHidden ? 'step-catalog-plus_'+ props.tabIndex : 'step-catalog-minus_'+ props.tabIndex}`}
                ref={targetElementRef}
                tabIndex={props.tabIndex || 0}
                data-tooltip-id='catalogs'
                data-tooltip-content={isRestricted ? strings['catalog.action.requestCatalog'] : (isDenied || isPending || hasBeenRequested || hasReturnError) ? strings['catalog.action.view.status'] : isHidden ? strings['catalog.action.addCatalog'] : strings['catalog.action.removeCatalog']}
                data-tooltip-delay-show={1500}                
                data-tooltip-place='bottom'                
                disabled={false}
                onClick={handleClick}
                onMouseEnter={handleMouseButtonOver}
            >
                {isRestricted || isPending || isDenied || hasBeenRequested || hasReturnError
                    ? <LockIcon width={16} height={16} />
                    : isHidden
                        ? <PlusIcon width={16} height={16} />
                        : <MinusIcon width={16} height={16} />
                }
            </button>
        </div>
    );
}

//========================================================================
function displayToolTip(isRestricted: boolean, isPending: boolean, isDenied: boolean, isHidden: boolean, hasBeenRequested: boolean, hasReturnError: boolean, strings: { [key: string]: string }): string {
    if (isRestricted) {
        return strings['catalog.action.request'];
    } else if (isPending) {
        return strings['catalog.action.pending'];
    } else if (isDenied) {
        return strings['catalog.action.denied'];
    } else if (isHidden) {
        return strings['catalog.action.hide'];
    } else if (hasBeenRequested) {
        return strings['catalog.action.pending'];
    } else if (hasReturnError) {
        return strings['catalog.action.error'];
    } else {
        return strings['catalog.action.add'];
    }
}