import { TabDef } from "../components/tabs/Tab";
import { authoringHubState, StateModule } from "./authoringHubState";

export type State = Record<
    string,
    {
        tabs: Record<string, TabDef>;
        currentTab: string;
    }
>;

export enum TabStateEvent {
    CurrentTabChanged = "tabstate-current-tab-changed",
    TabsChanged = "tabstate-tabs-changed",
}

class TabState implements StateModule<State> {
    public state: State = {};
    serialize() {
        return this.state;
    }

    init(key: string, welcomeStr: string) {
        if(!this.state[key]) {
            this.state[key] = {
                tabs: {home: {label: welcomeStr, id: "home"}},
                currentTab: "home",
            };
        }
    }

    openTab(key: string, tab: TabDef, select = false) {
        const id = "tab_" + Date.now();
        this.state[key].tabs[id] = tab;
        this.state[key].tabs[id].id = id;

        authoringHubState.notifyChange({
            type: TabStateEvent.TabsChanged,
            state: this.state,
        });

        if (!this.state[key].currentTab || select) {
            this.state[key].currentTab = id;
            authoringHubState.notifyChange({
                type: TabStateEvent.CurrentTabChanged,
                state: this.state,
            });
        }

        return id;
    }

    closeTab(key: string, id: string) {
        delete this.state[key].tabs[id];
        authoringHubState.notifyChange({
            type: TabStateEvent.TabsChanged,
            state: this.state,
        });

        if(this.state[key].currentTab === id) {
            this.selectTab(key, Object.keys(this.state[key].tabs)[0]);
        }
    }

    selectTab(key: string, id: string) {
        this.state[key].currentTab = id;
        authoringHubState.notifyChange({
            type: TabStateEvent.CurrentTabChanged,
            state: this.state,
        });
    }

    updateTab(key: string, id: string, tab: Partial<TabDef>) {
        this.state[key].tabs[id] = Object.assign(this.state[key].tabs[id], tab);
        authoringHubState.notifyChange({
            type: TabStateEvent.TabsChanged,
            state: this.state,
        });
    }
}

export const tabState = authoringHubState.registerModule(
    "tabs",
    new TabState(),
) as TabState;
