import { CatalogBaseInfo, IConsolidatedCatalogBaseInfo } from "./CatalogBaseInfo";
import { CatalogClassification, IConsolidatedCatalogClassification } from "./CatalogClassification";
import { CatalogProperties, IConsolidatedCatalogProperties } from "./CatalogProperties";
import { CatalogVersion, IConsolidatedCatalogVersion } from "./CatalogVersion";
import { ImageRef, IConsolidatedImageRef } from "./ImageRef";
import { PricingTaskList, IConsolidatedPricingTaskList } from "./PricingTaskList";

export class CatalogFactory {
    static create(catalog: ICatalog | V2.ICatalog, catalogVersions?: Array<ICatalogVersion>): IConsolidatedCatalog {
        return new Catalog(catalog, catalogVersions).serialize();
    }
}

export class Catalog extends CatalogBaseInfo {
    protected _status: CATALOG_STATUS;
    protected _companyRefCode: string;
    protected _companyRefCodes?: Array<string>;
    protected _measurementSystem: MeasurementSystem;
    protected _localization: ILocalization;
    protected _accessLevel?: string;
    protected _createdDate: string;
    protected _updatedDate: string;
    protected _altImages?: Array<ImageRef>;
    protected _classification?: CatalogClassification;
    protected _externalCodes?: IExternalCodes;
    protected _accessStatus?: string;
    // V2 Only
    protected _pricing?: PricingTaskList;
    protected _properties?: CatalogProperties;
    protected _catalogVersions?: Array<CatalogVersion>;

    constructor(catalog: ICatalog | V2.ICatalog, catalogVersions?: Array<ICatalogVersion>) {
        super(catalog);
        this._status = catalog.status;
        this._companyRefCode = catalog.companyRefCode;
        this._companyRefCodes = catalog.companyRefCodes;
        this._measurementSystem = catalog.measurementSystem;
        this._localization = catalog.localization;
        this._accessLevel = catalog.accessLevel;
        this._createdDate = catalog.createdDate;
        this._updatedDate = catalog.updatedDate;
        this._altImages = catalog.altImages?.map((imageRef: IImageRef) => new ImageRef(imageRef));
        this._classification = catalog.classification ? new CatalogClassification(catalog.classification) : undefined;
        this._externalCodes = catalog.externalCodes;
        this._accessStatus = catalog.accessStatus;
        this._catalogVersions = catalogVersions?.map((catalogVersionRef: ICatalogVersion) => new CatalogVersion(catalogVersionRef));

        // V2 Only
        let v2Catalog: V2.ICatalog = catalog as V2.ICatalog;
        if (v2Catalog.pricing) {
            this._pricing = new PricingTaskList(v2Catalog.pricing);
        }

        if (v2Catalog.properties) {
            this._properties = new CatalogProperties(v2Catalog.properties);
        }
    }

    serialize(): IConsolidatedCatalog {
        const catalog: IConsolidatedCatalog = {
            ...super.serialize(),
            status: this._status,
            companyRefCode: this._companyRefCode,
            companyRefCodes: this._companyRefCodes,
            measurementSystem: this._measurementSystem,
            localization: this._localization,
            createdDate: this._createdDate,
            updatedDate: this._updatedDate
        }

        if (this._accessLevel !== undefined) {
            catalog.accessLevel = this._accessLevel;
        }

        if (this._altImages !== undefined) {
            catalog.altImages = this._altImages.map((imageRef: ImageRef) => imageRef.serialize());
        }

        if (this._classification !== undefined) {
            catalog.classification = this._classification.serialize();
        }

        if (this._externalCodes !== undefined) {
            catalog.externalCodes = this._externalCodes;
        }

        if (this._accessStatus !== undefined) {
            catalog.accessStatus = this._accessStatus;
        }

        if (this._catalogVersions !== undefined) {
            catalog.catalogVersions = this._catalogVersions.map((catalogVersion: CatalogVersion) => catalogVersion.serialize());
        }

        if (this._pricing !== undefined) {
            catalog.pricing = this._pricing.serialize();
        }

        if (this._properties !== undefined) {
            catalog.properties = this._properties.serialize();
        }

        return catalog;
    }
}

export interface IConsolidatedCatalog extends IConsolidatedCatalogBaseInfo {
    status: CATALOG_STATUS;
    companyRefCode: string;
    companyRefCodes?: Array<string>;
    measurementSystem: MeasurementSystem;
    localization: ILocalization;
    accessLevel?: string;
    createdDate: string;
    updatedDate: string;
    altImages?: Array<IConsolidatedImageRef>;
    classification?: IConsolidatedCatalogClassification;
    externalCodes?: IExternalCodes;
    accessStatus?: string;
    catalogVersions?: Array<IConsolidatedCatalogVersion>;
    pricing?: IConsolidatedPricingTaskList;
    properties?: IConsolidatedCatalogProperties;
}