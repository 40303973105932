import { IConsolidatedNumericalRange, NumericalRange } from "./NumericalRange";

export class NumericalSpecFactory {
    static create(numericalSpec: INumericalSpec): IConsolidatedNumericalSpec {
        return new NumericalSpec(numericalSpec).serialize();
    }
}

export class NumericalSpec {
    protected _range?: NumericalRange;
    protected _defaultValue?: string | number;
    protected _proposedValues?: Array<string | number>;
    protected _measurementUnit?: MeasurementUnit;
    protected _measurementType?: MeasurementType;

    constructor(numericalSpec: INumericalSpec) {
        this._defaultValue = numericalSpec.defaultValue;
        this._proposedValues = numericalSpec.proposedValues;
        this._measurementUnit = numericalSpec.measurementUnit;
        this._measurementType = numericalSpec.measurementType;

        if (numericalSpec.range) {
            this._range = new NumericalRange(numericalSpec.range);
        }
    }

    get range(): NumericalRange | undefined {
        return this._range;
    }

    get defaultValue(): string | number | undefined {
        return this._defaultValue;
    }

    get proposedValues(): Array<string | number> | undefined {
        return this._proposedValues;
    }

    get measurementUnit(): MeasurementUnit | undefined {
        return this._measurementUnit;
    }

    get measurementType(): MeasurementType | undefined {
        return this._measurementType;
    }

    serialize(): IConsolidatedNumericalSpec {
        const numericalSpec: IConsolidatedNumericalSpec = {};

        if (this._range !== undefined) {
            numericalSpec.range = this._range.serialize();
        }

        if (this._defaultValue !== undefined) {
            numericalSpec.defaultValue = this._defaultValue;
        }

        if (this._proposedValues !== undefined) {
            numericalSpec.proposedValues = this._proposedValues;
        }

        if (this._measurementUnit !== undefined) {
            numericalSpec.measurementUnit = this._measurementUnit;
        }

        if (this._measurementType !== undefined) {
            numericalSpec.measurementType = this._measurementType;
        }

        return numericalSpec;
    }
}

export interface IConsolidatedNumericalSpec {
    range?: IConsolidatedNumericalRange;
    defaultValue?: string | number;
    proposedValues?: Array<string | number>;
    measurementUnit?: MeasurementUnit;
    measurementType?: MeasurementType;
}