import { ExplorerRoutes } from "../components/catalog-explorer/useNavigation";
import { authoringHubState, StateModule } from "./authoringHubState";
import DesignEngine, { DesignItem } from "@cic/test-design-engine";

export type State = {
    items: Record<string, IClientItem>;
};

export enum TestHubStateEvent {
    ItemContextListUpdate = "item-context-list-update",
}

class TestHubState implements StateModule<State> {
    private designEngine: DesignEngine | null = null;
    private notificationHandler: (() => void) | null = null;
    public state: State = {
        items: {},
    };

    async getItemContext(id: string) {
        if (!this.state.items[id]) {
            const clientItem = await this._toClientItem(id);

            if (clientItem) {
                this.state.items[id] = clientItem;
            }
        }
        return this.state.items[id];
    }

    refreshItem(id: string) {
        if (this.state.items[id]) {
            delete this.state.items[id];
            this.selectItems([id]);
        }
    }

    selectItems(ids: string[]) {
        Object.values(this.state.items).forEach((item) => {
            item.instanceExtras ??= {};
            item.instanceExtras.selected = false;
        });

        const promises = ids.filter((id) => id !== ExplorerRoutes.NEW_ENTITY).map((id) => this.getItemContext(id));

        Promise.all(promises).then((items) => {
            const selectedItems = items.filter((item) => item);
            selectedItems.forEach((itemCtx) => {
                itemCtx.instanceExtras!.selected = true;
            });
            this._notifyItemContextUpdate(selectedItems);
        });
    }

    async applyFeatureOption(featureOption: IFeatureOption, feature: V2.IFeature, itemCtxList: IClientItem[]) {
        const items = itemCtxList || Object.values(this.state.items);

        let promises = items
            .filter((item) => item.instanceExtras?.selected === true)
            .map(async (item) => {
                const id = item.itemId;
                if (id) {
                    const featureState: IFeatureState = {
                        featureId: feature.id,
                        optionId: featureOption.id,
                    };

                    if (featureOption.value !== undefined) {
                        featureState.value = featureOption.value as number;
                    }

                    if (feature.path) {
                        featureState.subItemScope = feature.path;
                    }

                    const resp = await CiCAPI.content.getItemVariant(id, item.configurationState, {
                        optionSelections: [featureState],
                    });

                    if (resp?.result) {
                        this.state.items[id] = this._toItemCtx(resp.result as IItemVariant, item.instanceId, {
                            selected: true,
                        });
                    }
                }
            });

        await Promise.all(promises);

        let selectedItems = Object.values(this.state.items).filter((item) => item.instanceExtras?.selected === true);

        if (selectedItems.length) {
            this._notifyItemContextUpdate(selectedItems);
        }
    }

    // For the live edit
    handleItemReplace(item: IItem | IItemVariant | IItemVariantProposal) {
        let configState: IConfigurationState | undefined =
            (item as IItemVariantProposal).proposedConfigurationState ?? (item as IItemVariant).configurationState;
        return this._getDesignEngine().replaceItem(item.id, {
            configurationState: configState,
        });
    }

    // For the live edit
    handleSelectionChange(selectedItems: Array<IClientItem>, isSilent?: boolean) {
        let designItemsToSelect: Array<any> = selectedItems
            .map((clientItem: IClientItem) => {
                return this._getDesignEngine().getItemByInstanceId(clientItem.instanceId!);
            })
            .filter((designItem: any | void) => designItem) as Array<any>;

        this._getDesignEngine().selectionMgr.setSelectedItems(designItemsToSelect, isSilent);
    }

    private _notifyItemContextUpdate(items?: IClientItem[]) {
        authoringHubState.notifyChange({
            type: TestHubStateEvent.ItemContextListUpdate,
            itemContextList: items ?? Object.values(this.state.items),
        });
    }

    private async _toClientItem(itemId: string): Promise<IClientItem | null> {
        const addedItem = await this._getDesignEngine().addItem(itemId, {
            select: true,
        });

        if (addedItem) {
            const clientItem = addedItem.toClientItem();
            return clientItem;
        }

        return null;
    }

    private _toItemCtx(
        itemVariant: IItemVariant,
        instanceId?: string,
        instanceExtras: IClientItem["instanceExtras"] = {
            selected: !!itemVariant.selected,
        }
    ): IClientItem {
        return {
            ...itemVariant,
            itemId: itemVariant.id,
            instanceId: instanceId || itemVariant.code + Date.now(),
            instanceExtras,
        };
    }

    // For the Design Engine
    private _initializeDesignEngine() {
        try {
            this.designEngine = new DesignEngine();
            this._registerNotificationHandler();
        } catch (error) {}
    }

    private _registerNotificationHandler() {
        if (this.notificationHandler) {
            this.designEngine!.unregisterFromNotifications(this.notificationHandler);
        }
        this.notificationHandler = this._handleDesignNotification.bind(this);
        this.designEngine!.registerToNotifications(this.notificationHandler);
    }

    private _getDesignEngine(): DesignEngine {
        if (!this.designEngine) {
            this._initializeDesignEngine();
        }
        return this.designEngine!;
    }

    private _convertDesignItemToClientItem(selectedItems: Array<DesignItem>, designItem: DesignItem): IClientItem {
        return designItem.toClientItem(selectedItems.includes(designItem));
    }

    private _handleDesignNotification() {
        const selectedItems: Array<DesignItem> = this._getDesignEngine().selectionMgr.getSelectedItems();
        const itemContextList = this._getDesignEngine()
            .getItemList()
            .map((designItem) => this._convertDesignItemToClientItem(selectedItems, designItem));

        window.postMessage({ type: TestHubStateEvent.ItemContextListUpdate, itemContextList }, "*");
    }

    serialize() {
        return this.state;
    }
}

export const testHubState = authoringHubState.registerModule("testhub", new TestHubState()) as TestHubState;
