import { IEntitySchema } from "../SchemaEntityUtils";
export type SchemaPath = (string | number)[];

export type ValidationExtraData = {
    catalogVersionId?: string;
    parentEntity?: unknown;
    refList?: any[];
};

export abstract class ValidationRule {
    abstract exec(
        value: unknown,
        schema: IEntitySchema,
        path: SchemaPath,
        extraData?: ValidationExtraData
    ): Promise<ValidationResponse>;
}

export type ValidationError = Error & {
    //could add details
};

export type ValidationResponse = {
    success: boolean;
    error?: ValidationError;
};
