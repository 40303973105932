export class ItemNamesFactory {
    static create(itemNames: IItemNames): IConsolidatedItemNames {
        return new ItemNames(itemNames).serialize();
    }
}

export class ItemNames {
    protected _main: string;
    protected _planView?: string;
    protected _others?: IOtherNames;

    constructor(itemNames: IItemNames) {
        this._main = itemNames.main;
        this._planView = itemNames.planView;
        this._others = itemNames.others;
    }

    serialize(): IConsolidatedItemNames {
        const itemNames: IConsolidatedItemNames = {
            main: this._main,
        };

        if (this._planView !== undefined) {
            itemNames.planView = this._planView;
        }

        if (this._others !== undefined) {
            itemNames.others = this._others;
        }

        return itemNames;
    }
}

export interface IConsolidatedItemNames {
    main: string;
    planView?: string;
    others?: IOtherNames;
}