import { EntityType } from "../..";
import { authoringHubState, StateModule } from "../authoringHubState";
import { AttributesMgr } from "./AttributesMgr";
import { DiffReport, IVersionDependencies } from "./AuthoringStateMgr.types";
import { CatalogMgr } from "./CatalogMgr";
import { DependencyMgr } from "./DependencyMgr";
import { EntityMgr } from "./EntityMgr";
import { FeaturesMgr } from "./FeaturesMgr";
import { ItemsMgr } from "./ItemsMgr";
import { MaterialsMgr } from "./MaterialsMgr";
import { PendingChangesMgr } from "./PendingChangesMgr";
import { RestrictionsMgr } from "./RestrictionsMgr";
import { SchemaMgr } from "./SchemaMgr";

export type EntitiesState<T> = {
    current: Record<string, T>;
    original: Record<string, T>;
    diffReport: DiffReport;
    errors: Record<string, Error>;
};
export type State = {
    currentCatalog: (ICatalog & ICatalogVersion) | null;
    currentCatalogVersion: IVersionDef | null;
    schemas: Record<string, ISchemaDef>;
    entities: Record<
        string,
        {
            items: EntitiesState<ICatalogItemDef>;
            features: EntitiesState<ICatalogFeatureDef>;
            restrictions: EntitiesState<ICatalogRestrictionDef>;
            options: EntitiesState<ICatalogOptionDef>;
            attributes: EntitiesState<ICatalogAttributeDef>;
            materials: EntitiesState<ICatalogMaterialDef>;
            itemgroups: EntitiesState<IGroupDef>;
            dependencies: EntitiesState<IVersionDependencies>;
            liveEditUpdate: {
                name: EntityType | "";
                code: string;
            };
        }
    >;
};

export enum AuthoringStateMgrEvent {
    StateChanged = "authoring-state-changed",
    CurrentCatalogChanged = "authoring-state-current-catalog-changed",
    ItemAdded = "authoring-action-adding-item",
    GroupChanged = "authoring-state-group-changed",
}

export class AuthoringStateMgr implements StateModule<State> {
    public state: State = {
        currentCatalog: null,
        currentCatalogVersion: null,
        entities: {},
        schemas: {},
    };
    public entities = new EntityMgr(this);
    public schemas = new SchemaMgr(this);
    public catalogs = new CatalogMgr(this);
    public dependencies = new DependencyMgr(this);
    public pendingChanges = new PendingChangesMgr(this);
    public items = new ItemsMgr(this);
    public attributes = new AttributesMgr(this);
    public features = new FeaturesMgr(this);
    public materials = new MaterialsMgr(this);
    public restrictions = new RestrictionsMgr(this);

    public serialize() {
        return this.state;
    }

    public notifyChange(
        eventName: AuthoringStateMgrEvent = AuthoringStateMgrEvent.StateChanged,
        extraData?: Record<string, any>
    ) {
        window.postMessage(
            {
                type: eventName,
                state: this.state,
                ...extraData,
            },
            "*"
        );
    }
}

export const authoringStateMgr = authoringHubState.registerModule(
    "authoring",
    new AuthoringStateMgr()
) as AuthoringStateMgr;
