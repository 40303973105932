export class MCodeUtils {
    static buildMCodeObject(schema: any, currentObject: any = {}): any {
        if (schema.anyOf) {
            schema.anyOf.forEach((element: any) => {
                if (element.enum) {
                    element.enum.forEach((enumElement: string) => {
                        const path = enumElement.split('.');
                        this.updateMCodeObject(currentObject, path, enumElement);
                    });
                } else if (element.anyOf) {
                    this.buildMCodeObject(element, currentObject);
                }
            });
        }

        return currentObject;
    }

    private static updateMCodeObject(currentObject: any, path: string[], mcodeValue: string): void {
        let obj = currentObject;

        for (const part of path) {
            if (!obj[part]) {
                obj[part] = {};
            }
            obj = obj[part] as any;
        }

        const lastPart = path[path.length - 1];
        obj[lastPart] = mcodeValue;
    }

    static extractOrDefault(data: { [x: string]: any; }, key: string): any {
        return data[key] || data[Object.keys(data)[0]] || []
    }

    static isValidOrDefault(array: string[], value: string): any {
        return array.includes(value) ? value : array[0]
    }

    static getInitialValue(entity: any): any {
        if (entity) {
            const [group, type, finish, coating] = entity.split('.');
            return { group, type, finish, coating };
        }

        const group = '';
        const type ='' ;
        const finish = '';
        const coating = '';

        return { group, type, finish, coating };
    }
}