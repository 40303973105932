import React, { Fragment, PropsWithChildren, useRef} from 'react';
import { Helpers } from '../Helpers';
import ClickAwayListener from 'react-click-away-listener';
import { ArrowRightIcon, CloseIcon } from 'icons/';
import { ThreeDots } from 'react-loader-spinner';
import './dropDown.scss';
import config from '../config';

type DropDownProps = {
    id: string,
    //ref?: React.RefObject<HTMLButtonElement>,
    containerClassName?: string,
    label?: string | JSX.Element,
    thumbnail?: string | undefined,
    labelTooltip?: string,
    headerLabel?: string,    
    disabled?: boolean,
    tooltip?: string,
    isOpened: boolean,
    flexGrow?: boolean,
    sideMargin?: string,
    topMargin?: string,
    width?: string,
    height?: string,
    dataFor?: string,
    optionStyle?: boolean,
    isProcessing?: boolean,
    canBeRemove?: boolean,
    canBeRemoveLabel?: string,
    squareRight?: boolean,
    noBorder?: boolean,
    keepOpen?: boolean,
    hasSelection?: boolean,
    maxWidth?: string,
    tabIndex?: number,  
    targetClassName?: string,
    onOpenedClick(isOpen: boolean, id: string): void,
    onRemoveClick?(id: string): void,
    onMouseEnter?(refElement: React.RefObject<HTMLButtonElement>): void;
}

export function DropDown(props: PropsWithChildren<DropDownProps>) {
    const containerClassName: string = props.containerClassName || 'drop-down__container',
        activeSelectorClassName: string = props.isOpened ? 'is-active' : '',
        disabledClassName: string = props.disabled ? 'is-disabled' : '',
        flexGrowClassName: string = props.flexGrow ? 'full-width' : '',
        optionStyleClassName: string = props.optionStyle ? 'option-style' : '',
        upDownMargin: string = props.topMargin ? props.topMargin + ' ' : '8px ',
        squareRightCLassName: string = props.squareRight ? 'square-right' : '',
        noBorderClassName: string = props.noBorder ? 'no-border': '', 
        targetClassName: string = props.targetClassName || '',
        marginStyle = props.sideMargin || props.topMargin ? { margin: upDownMargin + (props.sideMargin || '8px ') } : {},
        widthStyle = props.width ? { width: props.width } : {},
        maxWidthStyle= props.maxWidth ? { maxWidth: props.maxWidth } : {},
        heightStyle = props.height ? { height: props.height } : {},
        elementRef = useRef<HTMLButtonElement | null>(null),
        isProcessing: boolean = props.isProcessing === true || false,
        loaderColor: string = config.loaderColor,
        loading: JSX.Element = (
            <div className='drop-down__loader'><ThreeDots color={loaderColor} height={20} width={20} /></div>
        ),

        handleOpenedClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            event.preventDefault();
            props.onOpenedClick(!props.isOpened, props.id);
        },
        handleClickAway = (event: Event) => {
            if (props.keepOpen !== true) {
                props.onOpenedClick(false, props.id);
                //console.log('click away');
            }
        },
        handleResetClick = () => {
            if (props.onRemoveClick) {
                props.onRemoveClick(props.id);
            }
        },        
        handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
            if (event.key === 'Enter') {
                props.onOpenedClick(!props.isOpened, props.id);
                //console.log('Enter');
            }
        },
        handleMouseEnter = () => {
            if (props.onMouseEnter) {
                props.onMouseEnter(elementRef);
            }
        };

    return (
        <Fragment>
            <ClickAwayListener onClickAway={handleClickAway}>

                <div className={`${containerClassName} ${flexGrowClassName} ${optionStyleClassName} ${targetClassName}`}
                    style={{ ...marginStyle, ...widthStyle, ...maxWidthStyle }} 
                    id={props.id}                    
                >

                    <button
                        className={`drop-down__toggle ${activeSelectorClassName} ${optionStyleClassName} ${disabledClassName} ${noBorderClassName} ${squareRightCLassName}`}
                        ref={elementRef}
                        style={{ ...heightStyle }}
                        onClick={handleOpenedClick}
                        onKeyDown={handleKeyDown}
                        onMouseEnter={handleMouseEnter}
                        disabled={props.disabled}
                        tabIndex={props.tabIndex}
                        data-tooltip-id={props.dataFor || undefined}
                        data-tooltip-content={props.tooltip}
                        data-tooltip-delay-show={1500}
                        data-tooltip-hide={100}                         
                        data-tooltip-place='bottom'  
                    >
                        {props.headerLabel &&
                            <div className={`drop-down__header   ${disabledClassName}`}>{props.headerLabel}</div>
                        }
                        <div className='drop-down__toggle-label' title={props.labelTooltip ? props.labelTooltip : ''}>
                            {props.label}
                            
                            {props.hasSelection === true && 
                                <span className='drop-down__dot'
                                    data-tooltip-id='configurator'
                                    data-tooltip-content={'Modified'}                        
                                    data-tooltip-delay-show={1500}                                    
                                    data-tooltip-place='bottom'
                                ></span>
                            }

                        </div>
                        {props.thumbnail && 
                            <div className='drop-down__thumbnail'>                                            
                                <img src={props.thumbnail} width='32' height='32'/>                                            
                            </div>
                        }
                        <div className={`drop-down__toggle-btn ${activeSelectorClassName}`}>                           
                            <ArrowRightIcon width={16} height={16} />                            
                        </div>
                        {isProcessing && loading}
                    </button>
                    {props.canBeRemove &&
                        <button className={`drop-down__reset-btn  `}
                            onClick={handleResetClick}
                            data-tooltip-content={props.canBeRemoveLabel}
                            data-tooltip-id={props.dataFor || undefined}
                            data-tooltip-delay-show={1500}                            
                            data-tooltip-place='bottom'
                            data-tooltip-delay-hide={100}  
                            tabIndex={props.tabIndex}
                        >
                            <CloseIcon width={10} height={10} />
                        </button>
                    }
                    {props.children}

                </div>
            </ClickAwayListener>
        </Fragment>
    );
}