import { FeatureOptionCharacteristics, IConsolidatedFeatureOptionCharacteristics } from "./FeatureOptionCharacteristics";

export class FeatureOptionClassificationFactory {
    static create(featureOptionClassification: IFeatureOptionClassification): IConsolidatedFeatureOptionClassification {
        return new FeatureOptionClassification(featureOptionClassification).serialize();
    }
}

export class FeatureOptionClassification {
    protected _characteristics?: FeatureOptionCharacteristics;
    protected _tags?: Array<string>;

    constructor(featureOptionClassification: IFeatureOptionClassification) {
        this._characteristics = featureOptionClassification.characteristics ? new FeatureOptionCharacteristics(featureOptionClassification.characteristics) : undefined;
        this._tags = featureOptionClassification?.tags;
    }

    serialize(): IFeatureOptionClassification {
        const featureOptionClassification: IFeatureOptionClassification = {};

        if (this._characteristics !== undefined) {
            featureOptionClassification.characteristics = this._characteristics.serialize();
        }

        if (this._tags !== undefined) {
            featureOptionClassification.tags = this._tags;
        }

        return featureOptionClassification;
    }
}

export interface IConsolidatedFeatureOptionClassification {
    characteristics?: IConsolidatedFeatureOptionCharacteristics;
    tags?: Array<string>;
}