import classNames from "classnames";
import { Icon } from "../../components/icon/icon.component";
import { IconButton } from "../../components/icon-button/icon-button.component";
import { ControlProps } from "./control.type";
import React from "react";
import './control.scss'

export const Control = React.forwardRef(
    
    (props: ControlProps, controlRef: React.ForwardedRef<HTMLDivElement>) => {

        const classes = classNames("cy-control", props.className);
        
        return <div ref={controlRef} className={classes}>
            <div className="cy-control__input">
                {props.children}
                {props.label && (
                    <label className="cy-control__input-label">
                        {props.label}
                        {props.required && (
                            <span className="cy-control__input-label--required">*</span>
                        )}
                    </label>
                )}
                {props.icon && !props.onClickIcon && (
                    <Icon
                        tabIndex={-1}
                        className="cy-control__input--icon"
                        name={props.icon}
                        height={16} width={16}
                    />
                )}
                {props.icon && props.onClickIcon && (
                    <IconButton
                        tabIndex={-1}
                        className="cy-control__input--icon"
                        icon={props.icon}
                        height={16} width={16}
                        onClick={props.onClickIcon}
                        disabled={props.disabled}
                        title={props.tooltipIcon}
                    />
                )}
                {(props.iconAction && props.onClickAction) && (
                    <IconButton
                        tabIndex={-1}
                        className="cy-control__input--icon cy-control__input-action"
                        icon={props.iconAction}
                        height={16} width={16}
                        onClick={props.onClickAction}
                        disabled={props.disabled || props.disabledAction}
                        title={props.tooltipIconAction}
                    />
                )}
    
            </div>
            <label className="cy-control__error-message">
                {props.errorMessage}
            </label>
        </div>
    }

)