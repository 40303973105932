import JSONStringifier from "./JSONStringifier";

export default class DesignStateSource extends JSONStringifier<IDesignSource> implements IDesignSource {
    private _applicationId?: string;
    private _applicationVersion?: string;
    private _contentProvider?: ContentProviderType;

    get applicationId(): string | undefined {
        return this._applicationId;
    }

    get applicationVersion(): string | undefined {
        return this._applicationVersion;
    }

    get contentProvider(): ContentProviderType | undefined {
        return this._contentProvider;
    }

    load(designSource: IDesignSource | undefined): void {
        this._applicationId = designSource?.applicationId;
        this._applicationVersion = designSource?.applicationVersion;
        this._contentProvider = designSource?.contentProvider ?? "CiC-Prod";        
    }
}