import { IEntitySchema } from '../../SchemaEntityUtils';
import {
    SchemaPath,
    ValidationExtraData,
    ValidationResponse,
    ValidationRule,
} from '../SchemaValidationTypes';

export class PosOriScaleValidationRule extends ValidationRule {
    async exec(
        value: unknown,
        schema: IEntitySchema,
        path: SchemaPath,
        extraData?: ValidationExtraData | undefined
    ): Promise<ValidationResponse> {
        const response: ValidationResponse = { success: true };

        return response;
    }
}