import { ImageRef, IConsolidatedImageRef } from "./ImageRef";

export class ImagesFactory {
    static create(images: IImages): IConsolidatedImages {
        return new Images(images).serialize();
    }
}

export class Images {
    protected _main: ImageRef;
    protected _others?: Record<string, ImageRef>;

    constructor(images: IImages) {
        this._main = new ImageRef(images.main);
        
        if (images.others) {
            this._others = { };
            Object.keys(images.others).forEach((key) => {
                this._others![key] = new ImageRef(images.others![key]);
            });
        }
    }

    serialize(): IConsolidatedImages {
        const images: IConsolidatedImages = {
            main: this._main.serialize(),
        };

        if (this._others !== undefined) {
            images.others = {};
            Object.keys(this._others).forEach((key) => {
                images.others![key] = this._others![key].serialize();
            });
        }

        return images;
    }

}

export interface IConsolidatedImages {
    main: IConsolidatedImageRef;
    others?: Record<string, IConsolidatedImageRef>;
}