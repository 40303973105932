import React from 'react'
import { IIconProps } from 'Interfaces'

export function WasherIcon(props: IIconProps) {
    const width: number = props.width || 16;

    return (
        width < 20 
        ? 
            <svg width={props.width ?? 16} height={props.height ?? 16} viewBox="0 0 17 16" fill="none">
            <g clipPath="url(#clip0_1926_1732)">
            <path d="M3.83333 3.33333H2.5V2H3.83333V3.33333Z" fill="#388EE8"/>
            <path d="M2.5 8V6H3.83333V8H2.5Z" fill="#388EE8"/>
            <path d="M11.7664 9.7334C12.7958 9.7334 13.6302 10.5591 13.6302 11.5777C13.6302 12.5963 12.7958 13.422 11.7664 13.422C10.7371 13.422 9.90259 12.5963 9.90259 11.5777C9.90259 10.5591 10.7371 9.7334 11.7664 9.7334Z" fill="#6AAAEE"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M0.5 1.6337C0.5 0.724829 1.24265 0 2.14394 0H9.96212C10.8634 0 11.6061 0.724829 11.6061 1.6337V4.5348V6.44689H14.8485C15.5699 6.44689 16.1667 7.0274 16.1667 7.75824V14.6886C16.1667 15.4195 15.5699 16 14.8485 16H8.49621C7.78208 16 7.19003 15.4311 7.17821 14.7106H4.16178V16H2.15406V14.6694C1.74941 14.614 1.40351 14.4999 1.12768 14.3081C0.660996 13.9836 0.5 13.5147 0.5 13.0769V1.6337ZM7.17803 13.3773V7.75824C7.17803 7.0274 7.77484 6.44689 8.49621 6.44689H10.2727V5.20147H1.83333V13.0769C1.83333 13.1732 1.8521 13.1878 1.88882 13.2134C1.97507 13.2733 2.22472 13.3773 2.82073 13.3773H7.17803ZM1.83333 3.86813H10.2727V1.6337C10.2727 1.47442 10.1403 1.33333 9.96212 1.33333H2.14394C1.96576 1.33333 1.83333 1.47441 1.83333 1.6337V3.86813ZM14.8333 7.78022H8.51136V14.6667H14.8333V7.78022Z" fill="currentColor"/>
            <path d="M14.5022 8.27371V8.94038H13.1688V8.27371H14.5022Z" fill="#388EE8"/>
            <path d="M12.5017 8.27371V8.94038H11.1683V8.27371H12.5017Z" fill="#388EE8"/>
            </g>
            <defs>
            <clipPath id="clip0_1926_1732">
            <rect width="16" height="16" fill="white" transform="translate(0.5)"/>
            </clipPath>
            </defs>
            </svg>
        :
            <svg width={props.width ?? 25} height={props.height ?? 24} viewBox="0 0 25 24" fill="none">
            <path d="M3.93182 2.93407V5.10989H4.93182V2.93407H3.93182Z" fill="#388EE8"/>
            <path d="M3.93182 9.21978V11.8791H4.93182V9.21978H3.93182Z" fill="#388EE8"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M17.2585 14.0385C15.4356 14.0385 13.9489 15.5021 13.9489 17.3187C13.9489 19.1352 15.4356 20.5989 17.2585 20.5989C19.0814 20.5989 20.5682 19.1352 20.5682 17.3187C20.5682 15.5021 19.0814 14.0385 17.2585 14.0385ZM17.2585 15.0385C15.978 15.0385 14.9489 16.0643 14.9489 17.3187C14.9489 18.5731 15.978 19.5989 17.2585 19.5989C18.5391 19.5989 19.5682 18.5731 19.5682 17.3187C19.5682 16.0643 18.5391 15.0385 17.2585 15.0385Z" fill="#6AAAEE"/>
            <path d="M18.5 12.8626H19.5V11.8626H18.5V12.8626Z" fill="#388EE8"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M2.96591 0.5C1.88515 0.5 1 1.36834 1 2.45055V19.6154C1 20.5248 1.62501 21.2831 2.46591 21.5022V23.5H6.15341V21.5659H11.017V22.033C11.017 22.8481 11.6834 23.5 12.4943 23.5H22.0227C22.8336 23.5 23.5 22.8481 23.5 22.033V11.6374C23.5 10.8222 22.8336 10.1703 22.0227 10.1703H16.6591V6.80216V2.45055C16.6591 1.36834 15.7739 0.5 14.6932 0.5H2.96591ZM3.46591 22.5V21.5659H5.15341V22.5H3.46591ZM11.017 11.6374V20.5659H2.96591C2.42747 20.5659 2 20.1354 2 19.6154V7.3022H15.6591V10.1703H12.4943C11.6834 10.1703 11.017 10.8222 11.017 11.6374ZM15.6591 6.3022H2V2.45055C2 1.93053 2.42747 1.5 2.96591 1.5H14.6932C15.2316 1.5 15.6591 1.93053 15.6591 2.45055V6.3022ZM12.017 11.6374C12.017 11.3844 12.2257 11.1703 12.4943 11.1703H22.0227C22.2913 11.1703 22.5 11.3844 22.5 11.6374V22.033C22.5 22.2859 22.2913 22.5 22.0227 22.5H12.4943C12.2257 22.5 12.017 22.2859 12.017 22.033V11.6374Z" fill="currentColor"/>
            <path d="M20.5 12.8626H21.4976V11.8626H20.5V12.8626Z" fill="#388EE8"/>
            </svg>
    )
}