import { IEntitySchema } from '../../SchemaEntityUtils';
import {
    SchemaPath,
    ValidationExtraData,
    ValidationResponse,
    ValidationRule,
} from '../SchemaValidationTypes';
import { TypeValidationRule } from './TypeValidationRule';

export class AttributeRefValidationRule extends ValidationRule {
    async exec(
        value: unknown,
        schema: IEntitySchema,
        path: SchemaPath,
        extraData?: ValidationExtraData
    ): Promise<ValidationResponse> {
        const response: ValidationResponse = { success: true };
        const isRef = path.at(-1) === 'attributeRef';
        //@ts-ignore
        const ref = isRef ? value : extraData?.parentEntity?.attributeRef;

        if (!ref && isRef) {
            response.success = false;
            response.error = new Error('Please enter an attribute ref.');

            return response;
        }

        const attribute = await this.getAttribute(ref as string, extraData!);
        if (isRef && attribute) {
            if (!this.isUnique(ref as string, extraData!)) {
                response.success = false;
                response.error = new Error('This attribute is already in use.');
            }
        } else if (!attribute) {
            response.success = false;
            response.error = new Error('This attribute does not exist.');
        }

        if (response.success && !isRef) {
            return new TypeValidationRule().exec(value, {
                type: schema!.type,
            });
        }

        return response;
    }

    isUnique(value: string, extraData: ValidationExtraData) {
        const { refList } = extraData;
        return !refList?.find(
            (ref) => ref.attributeRef.toLowerCase() === value.toLowerCase()
        );
    }

    async getAttribute(ref: string, extraData: ValidationExtraData) {
        const { catalogVersionId } = extraData;

        const refSplit = ref?.split(':');
        let cicResponse;
        let attributeCode = ref;
        if (refSplit && refSplit.length > 1) {
            const [externalCatalog, code] = refSplit;
            attributeCode = code;
            cicResponse = await CiCAPI.authoring.getCatalogAttributeDefs(
                externalCatalog + ":",
                catalogVersionId
            );
        } else {
            cicResponse = await CiCAPI.authoring.getCatalogAttributeDefs(
                catalogVersionId!
            );
        }

        if (cicResponse.success) {
            return cicResponse.result?.find(
                (attr: ICatalogAttributeDef) =>
                    attr.code.toLowerCase() === attributeCode.toLowerCase()
            );
        }

        return null;
    }
}
