export class ReferenceCodesFactory {
    static create(referenceCodes: IReferenceCodes): IConsolidatedReferenceCodes {
        return new ReferenceCodes(referenceCodes).serialize();
    }
}

export class ReferenceCodes {
    protected _sku?: string;
    protected _ean?: string;
    protected _manufCode?: string;
    protected _others?: IOtherReferenceCodes;

    constructor(referenceCodes: IReferenceCodes) {
        this._sku = referenceCodes.sku;
        this._ean = referenceCodes.ean;
        this._manufCode = referenceCodes.manufCode;
        this._others = referenceCodes.others;
    }

    serialize(): IConsolidatedReferenceCodes {
        const referenceCodes: IConsolidatedReferenceCodes = {};

        if (this._sku !== undefined) {
            referenceCodes.sku = this._sku;
        }

        if (this._ean !== undefined) {
            referenceCodes.ean = this._ean;
        }

        if (this._manufCode !== undefined) {
            referenceCodes.manufCode = this._manufCode;
        }

        if (this._others !== undefined) {
            referenceCodes.others = this._others;
        }

        return referenceCodes;
    }
}

export interface IConsolidatedReferenceCodes {
    sku?: string;
    ean?: string;
    manufCode?: string;
    others?: IOtherReferenceCodes;
}