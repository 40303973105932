import { EntityType, EntityUtils } from "../..";
import { AuthoringCatalogEntity } from "../../utils/EntityUtils";
import { AuthoringStateMgr, State } from "./AuthoringStateMgr";

export class AttributesMgr {
    private authoringMgr: AuthoringStateMgr;
    private state: State;

    constructor(authoringMgr: AuthoringStateMgr) {
        this.authoringMgr = authoringMgr;
        this.state = this.authoringMgr.state;
    }

    //withDependencies will fetch all the attributes from the current catalog and its dependencies
    async getAttributes(
        withDependencies = false
    ): Promise<{ catalog: string; attributes: AuthoringCatalogEntity[] }[]> {
        const attributes = [];

        const currentCatalogAttributes = await EntityUtils.getCatalogEntities(
            EntityType.ATTRIBUTES,
            String(this.state.currentCatalogVersion!.id)
        );
        attributes.push({
            catalog: this.state.currentCatalog?.name || "",
            attributes: currentCatalogAttributes || [],
        });

        if (withDependencies) {
            const dependencies =
                this.state.currentCatalogVersion!.resolvedDependencies;

            const promises = Object.entries(dependencies!).map(
                async ([ref, dependency]) => {
                    const attributes =
                        (await EntityUtils.getCatalogEntities(
                            EntityType.ATTRIBUTES,
                            String(dependency.id)
                        )) || [];

                    return {
                        catalog: ref,
                        attributes,
                    };
                }
            );

            await Promise.all(promises).then((results) => {
                attributes.push(...results);
            });
        }

        return attributes;
    }

    async copyAttribute(sourceCatalogId: string, code: string) {
        if (
            !(await this.authoringMgr.entities.exists(
                EntityType.ATTRIBUTES,
                code
            ))
        ) {
            const { entity } = await this.authoringMgr.entities.fetchEntity(
                EntityType.ATTRIBUTES,
                code,
                sourceCatalogId,
                undefined,
                true
            );

            if (entity) {
                this.authoringMgr.entities.createEntity(
                    EntityType.ATTRIBUTES,
                    code,
                    String(this.state.currentCatalogVersion!.id),
                    {
                        ...entity,
                        code,
                    },
                    true
                );
            } else {
                console.warn("Tried to import an attribute that doesn't exist");
            }
        }
    }
}
