import { PendingChange } from "../authoringStateMgr/PendingChangesMgr";

export type States = {
    _original: State;
    _current: State;
    _deleted: StateDeleted;
};

export type State = Record<string, StateTypes>;
export type StateTypes = Record<
    string,
    {
        index: Record<string, StateEntity>;
        groups: StateGroups;
    }
>;

export type StateGroups = Record<string, StateEntity>;
export type StateSubgroups = Record<string, StateEntity>;

export type StateEntity = {
    level: number;
    isNew: boolean;
    entity: any;
    parent?: StateEntity;
    subgroups?: StateSubgroups;
};

export type StateDeleted = Record<string, StateDeletedTypes>;
export type StateDeletedTypes = Record<string, StateDeletedGroups>;
export type StateDeletedGroups = Record<string, StateDeletedEntity>;

export type StateDeletedEntity = {
    target?: StateEntity;
} & StateEntity;

export class GroupStateManagementException extends Error {
    constructor(message: string) {
        super(message);
    }
}

export class NotLoadedException extends GroupStateManagementException {
    constructor(message: string) {
        super(message);
    }
}

export class EntityNotFoundException extends GroupStateManagementException {
    constructor(message: string) {
        super(message);
    }
}

export class InvalidException extends GroupStateManagementException {
    constructor(message: string) {
        super(message);
    }
}

export enum AuthoringGroupsStateEvent {
    StateChanged = "authoring-groups-state-changed",
}

export type Change = {
    code: string;
    targetCode?: string;
};

export const GroupTypes = [
    "ItemGroups"
]

export type GroupPendingChange = {
    rootEntity?: any
} & PendingChange