import { IConsolidatedItemVariant, IConsolidatedFeatureState, IConsolidatedConfigurationState } from "@cic/ui-toolkit/src/models";
import { vec2 } from "gl-matrix";
import DesignItem from "./DesignItem";
import DesignStateInfo from "./DesignStateInfo";
import DesignStateSpaceData from "./DesignStateSpaceData";
import JSONStringifier from "./JSONStringifier";
import { CommonUtils } from "@cic/utils";
import UIToolkitUtils from "@cic/ui-toolkit/src/UIToolkitUtils";

type SplitItemsAndWalls = [Array<ICommonDesignItem>, Array<IPerimeterWall>, Array<IPartitionWall> | undefined];

export default class DesignState extends JSONStringifier<DesignState> implements ICommonDesignFormat {
    private _info: DesignStateInfo;
    private _spaces?: Array<DesignStateSpaceData>;
    private _items?: Array<ICommonDesignItem>;
    private readonly _designName: string;
    private readonly _applicationVersion?: string;

    constructor(applicationVersion?: string, name?: string) {
        super();

        let id: string = CommonUtils.genGUID();

        this._designName = name ?? "design_" + id;
        this._applicationVersion = applicationVersion;

        this._info = new DesignStateInfo();
        this._info.load(this._getDefaultDesignMetaData());

        const spaceData: DesignStateSpaceData = new DesignStateSpaceData();
        spaceData.load(this._getDefaultSpaceData(id));

        this._spaces = [spaceData];
        this._items = [];
    }

    //=========================================================================
    get info(): IDesignMetaData {
        return this._info;
    }

    //=========================================================================
    get spaces(): Array<DesignStateSpaceData> | undefined {
        return this._spaces;
    }

    //=========================================================================
    get items(): Array<ICommonDesignItem> | undefined {
        return this._items;
    }

    //=========================================================================
    load(serializedDesign: ICommonDesignFormat): void {
        if (serializedDesign.info) {
            this._info = new DesignStateInfo();
            this._info.load(serializedDesign.info);
        }

        if (Array.isArray(serializedDesign.spaces)) {
            this._spaces = [];
            serializedDesign.spaces.forEach((space: IDesignSpaceData) => {
                const spaceData: DesignStateSpaceData = new DesignStateSpaceData();
                spaceData.load(space);
                this._spaces?.push(spaceData);
            });
        }

        if (Array.isArray(serializedDesign.items)) {
            this._items = [];
            serializedDesign.items.forEach((item: ICommonDesignItem) => {
                this._items?.push(Object.assign({}, item));
            });
        }
    }

    //=========================================================================
    async setItems(allItems: Array<DesignItem>): Promise<void> {
        if (!Array.isArray(this._spaces) || this._spaces.length < 1) {
            const defaultSpaceData: DesignStateSpaceData = new DesignStateSpaceData();
            defaultSpaceData.load(this._getDefaultSpaceData(CommonUtils.genGUID()));
            this._spaces = [defaultSpaceData];
        }

        let spaceData: DesignStateSpaceData = this._spaces[0]; // for now we always serialize in first space
        let [items, perimeterWalls, partitionWalls]: SplitItemsAndWalls = await this._splitItemsAndWalls(allItems, spaceData);

        this._items = items;

        spaceData.setPerimeterWalls(perimeterWalls);
        spaceData.setPartitionWalls(partitionWalls);
    }

    //=========================================================================
    setGlobalConfigurationState(configurationState: IConsolidatedConfigurationState): void {
        if (this._info === undefined) {
            this._info = new DesignStateInfo();
            this._info.load(this._getDefaultDesignMetaData());
        }

        this._info.setGlobalConfigurationState(configurationState);
    }

    //=========================================================================
    addToGlobalConfigurationState(featureId: string, optionId: string, value?: number): void {
        if (this._info === undefined) {
            this._info = new DesignStateInfo();
            this._info.load(this._getDefaultDesignMetaData());
        }

        this._info.addToGlobalConfigurationState(featureId, optionId, value);
    }

    //=========================================================================
    removeFromGlobalConfigurationState(featureId: string): void {
        this._info?.removeFromGlobalConfigurationState(featureId);
    }

    // --- UGLY MAPPING CODE because walls are currently items with special code...  
    //=========================================================================
    private async _splitItemsAndWalls(allItems: Array<DesignItem>, designStateSpaceData: DesignStateSpaceData): Promise<SplitItemsAndWalls> {
        let items: Array<ICommonDesignItem> = [],
            perimeterWalls: Array<IPerimeterWall> = [],
            perimeterWallDesignItems: Array<DesignItem> = [],
            partitionWalls: Array<IPartitionWall> | undefined;

        await Promise.all(allItems.map(async (item: DesignItem) => {
            if (item && item.itemInstance.classification && item.itemInstance.classification.fullItemType === "Common.Structural.Wall" && item.itemInstance.code) {
                if (item.itemInstance.code.includes("wall.perimeter")) {
                    perimeterWallDesignItems.push(item);
                    await this._addWallDynamicCoveringsToItems(items, item, designStateSpaceData.id,);
                } else if (item.itemInstance.code.includes("wall.partition")) {
                    partitionWalls = partitionWalls ?? [];
                    partitionWalls.push(item.mapToPartitionWalls(item.itemInstance.code));
                    await this._addWallDynamicCoveringsToItems(items, item, designStateSpaceData.id,);
                }
            }

            // also include the wall item if you want to see a cut using the getItemAutomation v2
            items.push(item.serialize(designStateSpaceData));
        }));

        if (perimeterWallDesignItems.length > 1) {
            // Since we receive the items in no particular order, we need to put perimeter walls back properly one after the other, 
            // since they only have a start position and relies on the order to calculate direction and length.....
            let endPosition: vec2 = vec2.create();
            do {
                let perimeterWallDesignItem: DesignItem = this._getClosestWallNextTo(perimeterWallDesignItems, endPosition),
                    wallIndex: number = perimeterWallDesignItems.findIndex(wall => wall.instanceId === perimeterWallDesignItem.instanceId);

                // remove from list
                perimeterWallDesignItems.splice(wallIndex, 1);
                endPosition = perimeterWallDesignItem.getEndPosition2D();

                // add to ordered list
                perimeterWalls.push(perimeterWallDesignItem.mapToPerimeterWalls(perimeterWallDesignItem.itemInstance.code!));

            } while (perimeterWallDesignItems.length > 1);

            // add last wall to ordered list
            perimeterWalls.push(perimeterWallDesignItems[0].mapToPerimeterWalls(perimeterWallDesignItems[0].itemInstance.code!));
        }

        return [items, perimeterWalls, partitionWalls];
    }

    //=========================================================================
    private async _addWallDynamicCoveringsToItems(items: Array<ICommonDesignItem>, wallDesignItem: DesignItem, spaceId: string): Promise<void> {
        // Since walls are not catalog items offered by CiC3 (outside TestHub) and covering items (like paints, tiles, wallpaper) are CiC3 items,
        // we need to check if the wall has a dynamic covering and serialize it as a seperate item with a connection back to the wall/
        let dynamicFeatures: IDynamicFeature[] | undefined = await wallDesignItem.getDynamicFeatures();
        if (dynamicFeatures && dynamicFeatures.length > 0 && dynamicFeatures[0].target.type === "material") {
            // For now, we only support one dynamic covering per wall
            let featureId: string = dynamicFeatures[0].featureId;
            let featureState: IConsolidatedFeatureState | undefined = wallDesignItem.configurationState.find((featureState: IConsolidatedFeatureState) => featureState.featureId === featureId);
            // If the feature has an option set then the optionId is the itemId of the covering item 
            if (featureState) {
                let existingItem: ICommonDesignItem | undefined = items.find((item: ICommonDesignItem) => item.catalogItemId === featureState?.optionId);
                if (existingItem && existingItem.connections) {
                    existingItem.connections.push({ hostId: wallDesignItem.instanceId });
                } else {
                    let coveringItemId: string = featureState.optionId;
                    let coveringItem: IConsolidatedItemVariant | undefined = await UIToolkitUtils.getItemVariant({ itemId: coveringItemId }, CiCAPI, { includeSubItems: true });
                    if (coveringItem) {
                        items.push({
                            id: coveringItem.uuid ?? CommonUtils.genGUID(),
                            spaceId,
                            catalogItemId: coveringItem.id,
                            name: coveringItem.names.main as string,
                            classification: coveringItem.classification,
                            connections: [{ hostId: wallDesignItem.instanceId }]
                        });
                    } else {
                        console.warn(`[!] Unable to find wall covering (id=${coveringItemId}). Wall covering material will be ignored.`);
                    }
                }
            }
        }
    }

    //=========================================================================
    private _getClosestWallNextTo(perimeterWallDesignItems: Array<DesignItem>, referencePoint: vec2): DesignItem {
        perimeterWallDesignItems.sort((designB: DesignItem, designA: DesignItem) => this._sortByDistance(designB, designA, referencePoint));
        return perimeterWallDesignItems[0];
    }

    //=========================================================================
    private _sortByDistance(designB: DesignItem, designA: DesignItem, referencePoint: vec2): number {
        let distanceA: number = vec2.distance(designA.getStartPosition2D(), referencePoint),
            distanceB: number = vec2.distance(designB.getStartPosition2D(), referencePoint);

        return distanceA > distanceB ? -1 : 1;
    }

    //=========================================================================
    private _getDefaultSpaceData(id: string): IDesignSpaceData {
        return {
            id,
            name: "Default Room",
            functions: ["kitchen"],
        };
    }

    //=========================================================================
    private _getDefaultDesignMetaData(): IDesignMetaData {
        return {
            name: this._designName,
            source: {
                applicationId: "test-hub",
                applicationVersion: this._applicationVersion,
                contentProvider: "CiC-Dev"
            },
            coordinateConventions: {
                baseMeasurementUnit: "mm",
                axisOrientation: "rightHanded",
                axisElevation: "zAxisUp"
            }
        };
    }
}