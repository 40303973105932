import DesignItem from "./DesignItem";
import EventBus, { EVENT } from "./EventBus";

enum UPDATE_ACTION {
    ADD = "add",
    REMOVE = "remove",
    SET = "set",
    CLEAR = "clear"
}

//=============================================================================
class SelectionMgr extends EventBus {
    #_selection: Set<DesignItem>;

    //=========================================================================
    constructor() {
        super();

        this.#_selection = new Set();
    }

    //=========================================================================
    hasSelectedItems(): boolean {
        return this.#_selection.size > 0;
    }

    //=========================================================================
    isItemSelected(designItem: DesignItem): boolean {
        return this.#_selection.has(designItem);
    }

    //=========================================================================
    isItemInstanceIdSelected(instanceId: string): boolean {
        return Array.from(this.#_selection).findIndex((designItem: DesignItem) => designItem.id === instanceId) > -1;
    }

    //=========================================================================
    getSelectedItems(): Array<DesignItem> {
        return Array.from(this.#_selection);
    }

    //=========================================================================
    setSelectedItems(itemsToSelect: Array<DesignItem>, isSilent?: boolean) {
        this._updateSelection(itemsToSelect, UPDATE_ACTION.SET, isSilent);
    }

    //=========================================================================
    clearSelection(isSilent?: boolean) {
        this._updateSelection([], UPDATE_ACTION.CLEAR, isSilent);
    }

    //=========================================================================
    selectItem(item: DesignItem, isSilent?: boolean) {
        this._updateSelection([item], UPDATE_ACTION.SET, isSilent);
    }

    //=========================================================================
    unselectItem(item: DesignItem, isSilent?: boolean) {
        this._updateSelection([item], UPDATE_ACTION.REMOVE, isSilent);
    }

    //=========================================================================
    addItemToSelection(item: DesignItem, isSilent?: boolean) {
        this._updateSelection([item], UPDATE_ACTION.ADD, isSilent);
    }

    //=========================================================================
    private _updateSelection(items: Array<DesignItem>, action: UPDATE_ACTION, isSilent: boolean = false) {
        let notificationEvent: EVENT = EVENT.SELECTION_CHANGED,
            isNotifyingActiveSelection: boolean = true,
            notificationData: any;

        switch (action) {
            case UPDATE_ACTION.ADD:
                items.forEach((item: DesignItem) => {
                    this.#_selection.add(item);
                });
                break;
            case UPDATE_ACTION.REMOVE:
                items.forEach((item: DesignItem) => {
                    this.#_selection.delete(item);
                });
                break;
            case UPDATE_ACTION.SET:
                this.#_selection.clear();
                items.forEach((item: DesignItem) => {
                    this.#_selection.add(item);
                });
                break;
            case UPDATE_ACTION.CLEAR:
                notificationEvent = EVENT.SELECTION_CLEARED;
                isNotifyingActiveSelection = false;
                this.#_selection.clear();
        }

        if (!isSilent) {
            if (isNotifyingActiveSelection) {
                notificationData = Array.from(this.#_selection);
            }

            this._notify(notificationEvent, notificationData);
        }

    }
}

export default SelectionMgr;
