export class CatalogPropertiesFactory {
    static create(properties: V2.ICatalogProperties): IConsolidatedCatalogProperties {
        return new CatalogProperties(properties).serialize();
    }
}
    
export class CatalogProperties {
    protected _allowMaterialOverrides?: boolean;
    protected _others?: Record<string, string>;

    constructor(properties: V2.ICatalogProperties) {
        this._allowMaterialOverrides = properties.allowMaterialOverrides;
        this._others = properties.others;
    }

    serialize(): IConsolidatedCatalogProperties {
        const properties: IConsolidatedCatalogProperties = {};

        if (this._allowMaterialOverrides !== undefined) {
            properties.allowMaterialOverrides = this._allowMaterialOverrides;
        }

        if (this._others !== undefined) {
            properties.others = this._others;
        }

        return properties;
    }
}

export interface IConsolidatedCatalogProperties {
    allowMaterialOverrides?: boolean;
    others?: Record<string, string>;
}