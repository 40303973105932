export class PricingReportMessageFactory {
    static create(pricingReportMessage: IPricingReportMessage): IConsolidatedPricingReportMessage {
        return new PricingReportMessage(pricingReportMessage).serialize();
    }
}
    
export class PricingReportMessage {
    protected _clientItemInstanceIds?: Array<string>;
    protected _code: string;
    protected _details: string;
    protected _extraDetails?: unknown;
    protected _catalogId?: string;

    constructor(pricingReportMessage: IPricingReportMessage) {
        this._clientItemInstanceIds = pricingReportMessage.clientItemInstanceIds;
        this._code = pricingReportMessage.code;
        this._details = pricingReportMessage.details;
        this._extraDetails = pricingReportMessage.extraDetails;
        this._catalogId = pricingReportMessage.catalogId;
    }

    serialize(): IConsolidatedPricingReportMessage {
        const pricingReportMessage: IConsolidatedPricingReportMessage = {
            code: this._code,
            details: this._details
        };

        if (this._clientItemInstanceIds !== undefined) {
            pricingReportMessage.clientItemInstanceIds = this._clientItemInstanceIds;
        }

        if (this._extraDetails !== undefined) {
            pricingReportMessage.extraDetails = this._extraDetails;
        }

        if (this._catalogId !== undefined) {
            pricingReportMessage.catalogId = this._catalogId;
        }

        return pricingReportMessage;
    }
}

export interface IConsolidatedPricingReportMessage {
    clientItemInstanceIds?: Array<string>;
    code: string;
    details: string;
    extraDetails?: unknown;
    catalogId?: string;
}