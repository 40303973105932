import JSONStringifier from "./JSONStringifier";
import { CommonUtils } from "@cic/utils";

export default class DesignStatePerimeterWall extends JSONStringifier<IPerimeterWall> implements IPerimeterWall {
    private _id: string;
    private _name?: string;
    private _startPosition?: Array<number>;
    private _startHeight?: number;
    private _endHeight?: number;
    private _thickness?: number;

    private _type: PerimeterWallType;
    private _outdoorPerimeter?: boolean;

    constructor() {
        super();
        
        this._id = "wall_" + CommonUtils.genGUID();
        this._type = "solidWall";
    }

    get id(): string {
        return this._id;
    }

    get type(): PerimeterWallType {
        return this._type;
    }    
    
    get name(): string | undefined {
        return this._name;
    }

    get startPosition(): Array<number> | undefined {
        return this._startPosition;
    }

    get thickness(): number | undefined {
        return this._thickness;
    }    
    
    get startHeight(): number | undefined {
        return this._startHeight;
    }

    get endHeight(): number | undefined {
        return this._endHeight;
    }

    get outdoorPerimeter(): boolean | undefined {
        return this._outdoorPerimeter;
    }

    load(perimeterWall: IPerimeterWall) {
        this._id = perimeterWall.id;
        this._name = perimeterWall.name;
        this._startPosition = perimeterWall.startPosition;
        this._startHeight = perimeterWall.startHeight;
        this._endHeight = perimeterWall.endHeight;
        this._thickness = perimeterWall.thickness;
        this._type = perimeterWall.type;
        this._outdoorPerimeter = perimeterWall.outdoorPerimeter;
    }
}


