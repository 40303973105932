import { NumericalSpec, IConsolidatedNumericalSpec } from "./NumericalSpec";

export class DimensionSpecsFactory {
    static create(dimensionSpecs: IDimensionSpecs): IConsolidatedDimensionSpecs {
        return new DimensionSpecs(dimensionSpecs).serialize();
    }
}
    
export class DimensionSpecs {
    protected _width?: NumericalSpec;
    protected _height?: NumericalSpec;
    protected _depth?: NumericalSpec;
    protected _others?: Record<string, NumericalSpec>;

    constructor(dimensionSpecs: IDimensionSpecs) {
        if (dimensionSpecs.width) {
            this._width = new NumericalSpec(dimensionSpecs.width);
        }

        if (dimensionSpecs.height) {
            this._height = new NumericalSpec(dimensionSpecs.height);
        }

        if (dimensionSpecs.depth) {
            this._depth = new NumericalSpec(dimensionSpecs.depth);
        }

        if (dimensionSpecs.others) {
            this._others = {};
            for (const key in dimensionSpecs.others) {
                this._others[key] = new NumericalSpec(dimensionSpecs.others[key]);
            }
        }
    }

    serialize(): IConsolidatedDimensionSpecs {
        const dimensionSpecs: IConsolidatedDimensionSpecs = {};

        if (this._width !== undefined) {
            dimensionSpecs.width = this._width.serialize();
        }

        if (this._height !== undefined) {
            dimensionSpecs.height = this._height.serialize();
        }

        if (this._depth !== undefined) {
            dimensionSpecs.depth = this._depth.serialize();
        }

        if (this._others !== undefined) {
            dimensionSpecs.others = {};
            for (const key in this._others) {
                dimensionSpecs.others[key] = this._others[key].serialize();
            }
        }

        return dimensionSpecs;
    }
}

export interface IConsolidatedDimensionSpecs {
    width?: IConsolidatedNumericalSpec;
    height?: IConsolidatedNumericalSpec;
    depth?: IConsolidatedNumericalSpec;
    others?: Record<string, IConsolidatedNumericalSpec>;
}