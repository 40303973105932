export class ClientItemContextFactory {
    static create(clientItemContext: IConsolidatedClientItemContext): IConsolidatedClientItemContext {
        return new ClientItemContext(clientItemContext).serialize();
    }
}

export class ClientItemContext {
    protected _instanceId: string;
    protected _itemId: string;
    protected _subItemScope?: string;

    constructor(clientItemContext: IConsolidatedClientItemContext) {
        this._instanceId = clientItemContext.instanceId;
        this._itemId = clientItemContext.itemId;
        this._subItemScope = clientItemContext.subItemScope;
    }

    serialize(): IConsolidatedClientItemContext {
        const clientItemContext: IConsolidatedClientItemContext = {
            instanceId: this._instanceId,
            itemId: this._itemId
        };

        if (this._subItemScope !== undefined) {
            clientItemContext.subItemScope = this._subItemScope;
        }

        return clientItemContext;
    }
}

export interface IConsolidatedClientItemContext {
    instanceId: string;
    itemId: string;
    subItemScope?: string;
}