import { CommonUtils } from "@cic/utils";
import { Entity, EntityType, EntityUtils } from "../..";
import { AuthoringCatalogEntity } from "../../utils/EntityUtils";
import { AuthoringStateMgr, State } from "./AuthoringStateMgr";
import { ItemTemplatesMgr } from "./ItemTemplatesMgr";

type ItemRefsCreationOptions = {
    groupCode?: string | string[];
    catalogVersionId?: string;
};

export class ItemsMgr {
    private authoringMgr: AuthoringStateMgr;
    private state: State;
    public templatesMgr: ItemTemplatesMgr;

    private _restrictionsPromises: { [key: string]: Promise<any> } = {};

    constructor(authoringMgr: AuthoringStateMgr) {
        this.authoringMgr = authoringMgr;
        this.state = this.authoringMgr.state;
        this.templatesMgr = new ItemTemplatesMgr(authoringMgr);
    }

    public getCurrentItem(catalogVersionId: string, code: string) {
        return this.state.entities[catalogVersionId]?.items.current[code];
    }

    public async isOptionExcluded(
        catalogVersionId: string,
        feature: AuthoringCatalogEntity,
        option: AuthoringCatalogEntity,
        item?: ICatalogItemDef
    ) {
        const globalRestriction = await this._getOptionsExclusionRestriction(
            catalogVersionId,
            feature
        );
        const restriction = await this._getOptionsExclusionRestriction(
            catalogVersionId,
            feature,
            item
        );

        return (
            this._isOptionExcludedFromRestriction(
                feature,
                option,
                globalRestriction
            ) ||
            this._isOptionExcludedFromRestriction(feature, option, restriction)
        );
    }

    public async includeOption(
        catalogVersionId: string,
        feature: AuthoringCatalogEntity,
        option: AuthoringCatalogEntity,
        item: ICatalogItemDef
    ) {
        const restriction = await this._getOptionsExclusionRestriction(
            catalogVersionId,
            feature,
            item
        );
        if (
            restriction &&
            this._isOptionExcludedFromRestriction(feature, option, restriction)
        ) {
            this._includeOptionFromRestriction(
                catalogVersionId,
                restriction,
                option, 
                feature
            );
        } else if (item) {
            const globalRestriction =
                await this._getOptionsExclusionRestriction(
                    catalogVersionId,
                    feature
                );

            if (!globalRestriction) {
                return;
            }

            const mergedRestriction: ICatalogRestrictionDef =
                restriction ||
                this._createRestriction(catalogVersionId, feature, item);
            mergedRestriction.excludedCombinations[0].combination[0].optionRefs!.push(
                ...globalRestriction.excludedCombinations[0].combination[0]
                    .optionRefs!
            );

            this._includeOptionFromRestriction(
                catalogVersionId,
                mergedRestriction,
                option,
                feature
            );

            item.restrictionRefs!.splice(
                item.restrictionRefs!.indexOf(globalRestriction.code),
                1
            );
            this.authoringMgr.entities.updateEntityProperty(
                catalogVersionId,
                EntityType.ITEMS,
                ["restrictionRefs"],
                item.code,
                item.restrictionRefs,
                undefined,
                true
            );
        }
        //@ts-ignore
        this.authoringMgr.notifyChange("refresh-options");
    }

    public async excludeOption(
        catalogVersionId: string,
        feature: AuthoringCatalogEntity,
        option: AuthoringCatalogEntity,
        item?: ICatalogItemDef
    ) {
        const [ref, featureCode] = this._splitFeatureCode(feature);
        const featureCatalogId =
            this.authoringMgr.catalogs.getCatalogVersionFromRef(ref);

        let restriction = await this._getOptionsExclusionRestriction(
            catalogVersionId,
            feature,
            item
        );
        if (!restriction) {
            restriction = this._createRestriction(
                catalogVersionId,
                feature,
                item
            );
        }

        const combination = {
            featureRef: `${featureCatalogId}.${featureCode}`,
            optionRefs: [`${featureCatalogId}.${option.code}`],
        };
        if (restriction) {
            let combinations: IRestrictionCombinationDef | undefined =
                restriction.excludedCombinations[0].combination.find(
                    (c: any) => c.featureRef === combination.featureRef
                );

            let entityFeature = feature?.entity as ICatalogFeatureDef;

            if (!combinations || combinations?.optionRefs?.length === entityFeature?.options?.length) {
                restriction.excludedCombinations[0].combination.push(
                    combination
                );
            } else {
                let excludedOptions: string[] = [];
                let includedOptions: string[] = [];
                
                if (restriction.excludedCombinations[0].combination[0].allExceptTheseOptions === true) {
                    restriction.excludedCombinations[0].combination[0].optionRefs!.map((optionRef: any) => {includedOptions.push(optionRef.split(".")[1])});

                    includedOptions = includedOptions.filter((opt: any) => {
                        return opt !== option.code;
                    });

                    entityFeature.options?.map((option: any) => {
                        if (!includedOptions.includes(option.code)) {
                            excludedOptions.push(option.code);
                        }
                    });
                }
                else {
                    if (restriction.excludedCombinations[0].combination[0].optionRefs) {
                        restriction.excludedCombinations[0].combination[0].optionRefs.map((optionRef: any) => {excludedOptions.push(optionRef.split(".")[1])});
                    }
                    excludedOptions.push(option.code);

                    entityFeature?.options?.map((option: any) => {
                        if(!excludedOptions.includes(option.code)) {
                            includedOptions.push(option.code);
                        }
                    });
                }

                if (entityFeature?.options?.length == excludedOptions.length) {
                    delete combinations.allExceptTheseOptions;
                    delete combinations.optionRefs;
                }
                else {
                    if (excludedOptions.length > includedOptions.length) {
                        combinations.optionRefs = [];
    
                        includedOptions.map((option: any) => {
                            combinations?.optionRefs?.push(`${featureCatalogId}.${option}`);
                        });
    
                        if (includedOptions.length == 0 && entityFeature?.options?.length == excludedOptions.length){
                            entityFeature?.options?.map((option: any) => {
                                combinations?.optionRefs?.push(`${featureCatalogId}.${option.code}`);
                            });
                            delete combinations.allExceptTheseOptions;
                        }
                        else {
                            combinations.allExceptTheseOptions = true;
                        }
                    }
                    else {
                        combinations.optionRefs?.push(combination.optionRefs[0]);
                        delete combinations.allExceptTheseOptions;
                    }
                }
            }

            this.authoringMgr.entities.updateEntityProperty(
                catalogVersionId,
                EntityType.RESTRICTIONS,
                ["excludedCombinations", 0, "combination"],
                restriction.code,
                restriction.excludedCombinations[0].combination,
                undefined,
                true
            );
            //@ts-ignore
            this.authoringMgr.notifyChange("refresh-options");
        }
    }

    public async createItemFromTemplate(
        templateId: string,
        code: string,
        name?: string,
        groupRefs?: string[]
    ) {
        this.templatesMgr.createItem(templateId, code, name, groupRefs);
    }

    public async getTemplateAttributes(item: ICatalogItemDef) {
        const catalogVersionId = String(
            this.authoringMgr.state.currentCatalogVersion!.id
        );

        const authoringAttributes: AuthoringCatalogEntity[] = [];

        for (let i = 0; i < (item.attributes?.length || 0); i++) {
            const attr = item.attributes![i];

            authoringAttributes.push({
                id: `${attr.attributeRef}`,
                code: attr.attributeRef,
                entity: attr as unknown as Entity,
                source: await EntityUtils.getCatalogEntity(
                    EntityType.ATTRIBUTES,
                    attr.attributeRef,
                    catalogVersionId
                ),
                type: EntityType.ATTRIBUTES,
                catalogVersionId,
            });
        }

        return authoringAttributes;
    }

    public async createItemRefs(
        itemIds: string[],
        options?: ItemRefsCreationOptions
    ) {
        if (itemIds?.length) {
            itemIds.forEach((itemId, index: number) => this.createItemRef(itemId, options, index !== itemIds.length - 1));
        }
    }

    public async createItemRef(
        itemId: string,
        options?: ItemRefsCreationOptions,
        silent?: boolean
    ) {
        const [srcCatalogId, srcItemCode] = CommonUtils.splitEntityId(itemId);
        const destCatalogId =
            options?.catalogVersionId ||
            String(this.authoringMgr.state.currentCatalogVersion!.id);

        this.authoringMgr.catalogs.openCatalog(destCatalogId);

        const srcCatalogRef = this.authoringMgr.catalogs.getCatalogRef(
            srcCatalogId!
        );

        if (!srcCatalogId || !srcItemCode) {
            console.warn(
                "Could not create item ref: Invalid item id",
                destCatalogId,
                itemId,
                options
            );
            return;
        }

        const code = await this._getAvailableCode(destCatalogId, srcItemCode);
        const itemRef: Partial<ICatalogItemDef> = {
            code,
            itemRef: `${srcCatalogRef}:${srcItemCode}`,
        };
        if (options?.groupCode) {
            if (Array.isArray(options.groupCode)) {
                itemRef.groupRefs = options.groupCode;
            } else {
                itemRef.groupRefs = [options.groupCode];
            }
        }

        const features = await this._getItemFeatureRefs(
            srcCatalogId,
            srcItemCode
        );

        const restrictions: string[] = [];
        for (const feature of features) {
            const restriction = await this._getOptionsExclusionRestriction(
                destCatalogId,
                {
                    //@ts-ignore
                    entity: {
                        featureRef: `${srcCatalogRef}:${feature.featureRef}`,
                    },
                    code: "",
                    type: EntityType.FEATURES,
                    catalogVersionId: srcCatalogId,
                }
            );
            if (restriction) {
                restrictions.push(restriction.code);
            }
        }

        if (restrictions.length) {
            itemRef.restrictionRefs = restrictions;
        }

        this.authoringMgr.entities.createEntity(
            EntityType.ITEMS,
            code,
            destCatalogId,
            itemRef,
            silent
        );
    }

    private async _getItemFeatureRefs(
        catalogVersionId: string,
        itemCode: string
    ) {
        const featureRefs = [];
        const item = (await EntityUtils.getCatalogEntity(
            EntityType.ITEMS,
            itemCode,
            catalogVersionId
        )) as ICatalogItemDef;
        if (item && item.featureRefs) {
            featureRefs.push(...item.featureRefs);
        }

        return featureRefs;
    }

    private async _getAvailableCode(
        catalogVersionId: string,
        itemCode: string
    ) {
        const items = await EntityUtils.getCatalogEntities(
            EntityType.ITEMS,
            catalogVersionId
        );
        let code = itemCode;
        let count = 1;

        while (
            items.find((i: ICatalogItemDef) => i.code === code) ||
            this.state.entities[catalogVersionId]?.items.current[code]
        ) {
            code = `${itemCode}_copy_${count}`;
            count++;
        }

        return code;
    }

    private _getOptionExclusionRestrictionCode(
        feature: AuthoringCatalogEntity,
        item?: ICatalogItemDef
    ) {
        const [ref, featureCode] = this._splitFeatureCode(feature);
        let code = "";
        if (item) {
            code = `auto_${ref}_${item.code}_${featureCode}`;
        } else {
            code = `auto_${ref}_${featureCode}`;
        }

        return code.replace(":", "_");
    }

    private async _getOptionsExclusionRestriction(
        catalogVersionId: string,
        feature: AuthoringCatalogEntity,
        item?: ICatalogItemDef
    ): Promise<ICatalogRestrictionDef | undefined> {
        const restrictionCode = this._getOptionExclusionRestrictionCode(
            feature,
            item
        );

        const promiseKey = `${catalogVersionId}_${restrictionCode}`;
        if (!this._restrictionsPromises[promiseKey]) {
            this._restrictionsPromises[promiseKey] =
                this.authoringMgr.entities.fetchEntity(
                    EntityType.RESTRICTIONS,
                    restrictionCode,
                    catalogVersionId
                );
        }
        await this._restrictionsPromises[promiseKey];

        return this.state.entities[catalogVersionId].restrictions.current[
            restrictionCode
        ];
    }

    private _createRestriction(
        catalogVersionId: string,
        feature: AuthoringCatalogEntity,
        item?: ICatalogItemDef
    ) {
        const [ref, code] = this._splitFeatureCode(feature);
        const featureCatalogId =
            this.authoringMgr.catalogs.getCatalogVersionFromRef(ref);
        const restrictionCode = this._getOptionExclusionRestrictionCode(
            feature,
            item
        );
        this.authoringMgr.entities.createEntity(
            EntityType.RESTRICTIONS,
            restrictionCode,
            catalogVersionId,
            {
                excludedCombinations: [
                    {
                        combination: [
                            {
                                featureRef: `${featureCatalogId}.${code}`,
                                optionRefs: [],
                            },
                        ],
                    },
                ],
            }
        );
        this._updateItemsRestrictionRefs(
            catalogVersionId,
            feature,
            restrictionCode,
            item
        );

        return this.state.entities[catalogVersionId].restrictions.current[
            restrictionCode
        ]!;
    }

    private _updateItemsRestrictionRefs(
        catalogVersionId: string,
        feature: AuthoringCatalogEntity,
        restrictionCode: string,
        item?: ICatalogItemDef
    ) {
        const items: ICatalogItemDef[] = [];
        if (item) {
            items.push(item);
        } else if (feature.affected) {
            items.push(
                ...(feature.affected.map((e) => e.entity) as ICatalogItemDef[])
            );
        }

        items.forEach((i) =>
            this._updateItemRestrictionRefs(
                catalogVersionId,
                restrictionCode,
                i
            )
        );
    }

    private async _updateItemRestrictionRefs(
        catalogVersionId: string,
        restrictionCode: string,
        item: ICatalogItemDef
    ) {
        if (item) {
            const { entity: itemDef } =
                (await this.authoringMgr.entities.fetchEntity(
                    EntityType.ITEMS,
                    item.code,
                    catalogVersionId
                )) as { entity: ICatalogItemDef };
            if (!itemDef.restrictionRefs) {
                itemDef.restrictionRefs = [];
            }

            if (!itemDef.restrictionRefs.includes(restrictionCode)) {
                itemDef.restrictionRefs.push(restrictionCode);
                this.authoringMgr.entities.updateEntityProperty(
                    catalogVersionId,
                    EntityType.ITEMS,
                    ["restrictionRefs"],
                    itemDef.code,
                    itemDef.restrictionRefs,
                    undefined,
                    true
                );
            }
        }
    }

    private _isOptionExcludedFromRestriction(
        feature: AuthoringCatalogEntity,
        option: AuthoringCatalogEntity,
        restriction?: ICatalogRestrictionDef
    ) {
        const [ref, featureCode] = this._splitFeatureCode(feature);
        const catalogId =
            this.authoringMgr.catalogs.getCatalogVersionFromRef(ref);

        if (restriction?.excludedCombinations[0].combination[0].allExceptTheseOptions === true) {
            return !restriction.excludedCombinations[0].combination.some((c: any) => 
                c.featureRef === `${catalogId}.${featureCode}` &&
                c.optionRefs.includes(`${catalogId}.${option.code}`)
            );
        }
            
        return restriction?.excludedCombinations[0].combination.some((c: any) => 
            c.featureRef === `${catalogId}.${featureCode}` &&
            c.optionRefs.includes(`${catalogId}.${option.code}`)
        );
    }

    public _includeOptionFromRestriction(
        catalogVersionId: string,
        restriction: ICatalogRestrictionDef,
        option: AuthoringCatalogEntity,
        feature: AuthoringCatalogEntity
    ) {
        
        let includedOptions: string[] = [];
        let excludedOptions: string[] = [];
        let catalogRestriction = JSON.parse(JSON.stringify(restriction));
        catalogRestriction.excludedCombinations[0].combination[0].optionRefs = [];
        let entityFeature = feature.entity as ICatalogFeatureDef;

        const [ref] = this._splitFeatureCode(feature);
        const featureCatalogId =
            this.authoringMgr.catalogs.getCatalogVersionFromRef(ref);

        if (restriction.excludedCombinations[0].combination[0].allExceptTheseOptions === true) {
            restriction.excludedCombinations[0].combination[0].optionRefs!.map((optionRef: any) => {includedOptions.push(optionRef.split(".")[1])});
            includedOptions.push(option.code);

            entityFeature?.options?.map((option: any) => {
                if (!includedOptions.includes(option.code)) {
                    excludedOptions.push(option.code);
                }
            });

            if (excludedOptions.length > includedOptions.length){
                includedOptions.map((option: any) => {
                    catalogRestriction.excludedCombinations[0].combination[0].optionRefs?.push(`${featureCatalogId}.${option}`);
                });
                catalogRestriction.excludedCombinations[0].combination[0].allExceptTheseOptions = true;
            }

            else {
                excludedOptions.map((option: any) => {
                    catalogRestriction.excludedCombinations[0].combination[0].optionRefs?.push(`${featureCatalogId}.${option}`);
                });
                delete catalogRestriction.excludedCombinations[0].combination[0].allExceptTheseOptions;
            }
        }
        else {
            if (restriction.excludedCombinations[0].combination[0].optionRefs?.length ?? 0 > 0) {
                restriction.excludedCombinations[0].combination[0].optionRefs?.map((optionRef: any) => {excludedOptions.push(optionRef.split(".")[1])});
            }
            else {
                entityFeature?.options?.map((option: any) => {
                    excludedOptions.push(option.code);
                });
            }

            excludedOptions = excludedOptions.filter((opt: any) => {
                return opt !== option.code;
            });

            entityFeature?.options?.map((option: any) => {
                if (!excludedOptions.includes(option.code)) {
                    includedOptions.push(option.code);
                }
            });

            if (includedOptions.length > excludedOptions.length) {
                excludedOptions.map((option: any) => {
                    if (!catalogRestriction.excludedCombinations[0].combination[0].optionRefs?.includes(`${featureCatalogId}.${option}`)){
                        catalogRestriction.excludedCombinations[0].combination[0].optionRefs?.push(`${featureCatalogId}.${option}`);
                    } 
                });
                delete catalogRestriction.excludedCombinations[0].combination[0].allExceptTheseOptions;
            }

            else {
                includedOptions.map((option: any) => {
                    catalogRestriction.excludedCombinations[0].combination[0].optionRefs?.push(`${featureCatalogId}.${option}`);
                });
                catalogRestriction.excludedCombinations[0].combination[0].allExceptTheseOptions = true;
            }

        }
        this.authoringMgr.entities.updateEntityProperty(
            catalogVersionId,
            EntityType.RESTRICTIONS,
            ["excludedCombinations", 0, "combination"],
            restriction.code,
            catalogRestriction.excludedCombinations[0].combination,
            undefined,
            true
        );
    }

    private _splitFeatureCode(
        feature: AuthoringCatalogEntity
    ): [ref: string, code: string] {
        const featureRef =
            //@ts-ignore
            feature.entity?.featureRef ||
            //@ts-ignore
            feature.source?.featureRef ||
            feature.code;

        const chunks = featureRef.split(":");

        if (chunks.length === 2) {
            return chunks as [string, string];
        } else {
            return ["", featureRef];
        }
    }
}
