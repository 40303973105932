import JSONStringifier from "./JSONStringifier";
import { CommonUtils } from "@cic/utils";

export default class DesignStatePartitionWall extends JSONStringifier<IPartitionWall> implements IPartitionWall {
    private _id: string;
    private _name?: string;
    private _startPosition?: Array<number>;
    private _startHeight?: number;
    private _endHeight?: number;
    private _thickness?: number;

    private _type: PartitionWallType;
    private _offsetFromFloor?: number;
    private _endPosition?: Array<number>;

    constructor() {
        super();
        
        this._id = "wall_" + CommonUtils.genGUID();
        this._type = "solidRectangularWall";
    }

    get id(): string {
        return this._id;
    }

    get type(): PartitionWallType {
        return this._type;
    }    
    
    get name(): string | undefined {
        return this._name;
    }
    
    get startPosition(): Array<number> | undefined {
        return this._startPosition;
    }

    get endPosition(): Array<number> | undefined {
        return this._endPosition;
    }

    get thickness(): number | undefined {
        return this._thickness;
    }

    get startHeight(): number | undefined {
        return this._startHeight;
    }
    
    get endHeight(): number | undefined {
        return this._endHeight;
    }
    
    get offsetFromFloor(): number | undefined {
        return this._offsetFromFloor;
    }
    
    load(partitionWall: IPartitionWall): void {
        this._id = partitionWall.id;
        this._name = partitionWall.name;
        this._startPosition = partitionWall.startPosition;
        this._startHeight = partitionWall.startHeight;
        this._endHeight = partitionWall.endHeight;
        this._thickness = partitionWall.thickness;
        this._type = partitionWall.type;
        this._offsetFromFloor = partitionWall.offsetFromFloor;
        this._endPosition = partitionWall.endPosition;
    }
}