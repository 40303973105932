export class ItemPriceReportItemCalculationStepsFactory {
    static create(itemPriceReportItemCalculationStepsDef: IItemPriceReportItemCalculationStepsDef): IConsolidatedItemPriceReportItemCalculationSteps {
        return new ItemPriceReportItemCalculationSteps(itemPriceReportItemCalculationStepsDef).serialize();
    }
}

export class ItemPriceReportItemCalculationSteps {
    protected _expression: string;
    protected _result: number;
    protected _parameters: Record<string, string>;

    constructor(itemPriceReportItemCalculationStepsDef: IItemPriceReportItemCalculationStepsDef) {
        this._expression = itemPriceReportItemCalculationStepsDef.expression;
        this._result = itemPriceReportItemCalculationStepsDef.result;
        this._parameters = itemPriceReportItemCalculationStepsDef.parameters;
    }

    serialize(): IConsolidatedItemPriceReportItemCalculationSteps {
        return {
            expression: this._expression,
            result: this._result,
            parameters: this._parameters
        };
    }
}

export interface IConsolidatedItemPriceReportItemCalculationSteps {
    expression: string;
    result: number;
    parameters: Record<string, string>;
}