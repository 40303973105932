import { IConsolidatedCompleteCatalogVersion } from "./CompleteCatalogVersion";
import { FeatureOption, IConsolidatedFeatureOption } from "./FeatureOption";
import { GroupBase, IConsolidatedGroupBase } from "./GroupBase";

export class FeatureOptionGroupFactory {
    static create(featureOptionGroup: IFeatureOptionGroup | IConsolidatedFeatureOptionGroup): IConsolidatedFeatureOptionGroup {
        return new FeatureOptionGroup(featureOptionGroup).serialize();
    }
}

export class FeatureOptionGroup extends GroupBase {
    protected _options?: Array<FeatureOption>;
    protected _optionGroups?: Array<FeatureOptionGroup>;

    constructor(featureOptionGroup: IFeatureOptionGroup | IConsolidatedFeatureOptionGroup) {
        super(featureOptionGroup);

        this._options = featureOptionGroup.options?.map((option: IFeatureOption) => new FeatureOption(option));
        this._optionGroups = featureOptionGroup.optionGroups?.map((optionGroup: IFeatureOptionGroup) => new FeatureOptionGroup(optionGroup));
    }

    get options(): Array<FeatureOption> | undefined {
        return this._options;
    }

    get optionGroups(): Array<FeatureOptionGroup> | undefined {
        return this._optionGroups;
    }
    
    serialize(): IConsolidatedFeatureOptionGroup {
        const featureOptionGroup: IConsolidatedFeatureOptionGroup = {
            ...super.serialize(),
        }

        if (this._options !== undefined) {
            featureOptionGroup.options = this._options.map((option: FeatureOption) => option.serialize());
        }

        if (this._optionGroups !== undefined) {
            featureOptionGroup.optionGroups = this._optionGroups.map((optionGroup: FeatureOptionGroup) => optionGroup.serialize());
        }

        return featureOptionGroup;
    }
}

export interface IConsolidatedFeatureOptionGroup extends IConsolidatedGroupBase {
    options?: Array<IConsolidatedFeatureOption>;
    optionGroups?: Array<IConsolidatedFeatureOptionGroup>;
    catalogInfo?: IConsolidatedCompleteCatalogVersion;
}