import { IConsolidatedCatalog, IConsolidatedSearchItem, IConsolidatedClientItem, IConsolidatedFeature, IConsolidatedFeatureOption, IConsolidatedFeatureOptionGroup, IConsolidatedItemVariantProposal, IConsolidatedPricingReportItemAvailability, IConsolidatedSearchResult, IConsolidatedFeatureGroup } from "@cic/ui-toolkit/src/models";
import React from "react";
import { strings } from 'strings';
import { WasherIcon, DecorationIcon, ArmchairIcon, TapIcon, CabinetsIcon, StorageIcon, LightingIcon, OfficeIcon, OutdoorIcon, MaterialsIcon, AllCatalogIcon, ArchitecturalIcon } from 'icons/';

export interface IIconProps {
    height?: number,
    width?: number,
    rotate?: number
}

export type Config = 'displayCompany' | 'thumbnailSize' | 'itemView' | 'favorite' | 'hiddenCatalogs'  | 'theme' |
    'selectedTab' | 'selectedCatalogs' | 'selectedGroup' | 'openedGroup' | 'conf_thumbnailSize' |
    'conf_optionView' | 'previousSearch' | 'locale' | 'enable_contextualHelp' | 'selectedCatalogType' | 'conf_unionOption' | 'displayCatalogFiltering' | 'displayPricing';

export interface ICatalogPlus extends IConsolidatedCatalog {
    rootId: string,
    version: string,

    isSelected: boolean,
    isVisible: boolean,
    isHidden: boolean,

}
export interface xCatFacetInterface {
    facets: Array<IFacet>,
    updatedFacets: Array<IFacet> | null
    filterFacets: IOptionsGetItemFeatureOptions,
    totalResults: number
}

export interface ICatalogWithFeatures extends ICatalogBaseInfo {
    versionId?: string,
    features?: IFeaturePlus[],
    featureGroups?: IFeatureGroupPlus[]
}
export type CATALOG_STATUSES = 'activated' | 'inProgress' | 'inReview';

export type DisplayViewType = 'gallery' | 'list';

export type DisplayCompanyType = 'sort' | 'side' | 'hide';

export type DisplayViewerType = '2d' | '3d';

export const DEFAULT_NB_PER_PAGE = 10;

export type CatalogMenuItem = {
    title: string,
    name: string,
    path: string,
    visible: boolean,
    mainCategories: string | Array<string>,
    icon: JSX.Element
};

export const IconSize = {
    height: 16,
    width: 16
};
export const SideNavigationIconsize = {
    height: 24,
    width: 24
};
export const CatalogFilterIconsize = {
    height: 16,
    width: 16
};

export interface IItemPlus extends IConsolidatedSearchItem {
    selectedProposal: IConsolidatedItemVariantProposal | undefined,    
    isFavourite: boolean,
    tabIndex: number | undefined,
    price: number | undefined,
    currencyCode: string | undefined,
    availableInfo: IConsolidatedPricingReportItemAvailability | undefined,
}
export interface IPricingItem {
    itemId: string,
    instanceId: string,
} 
export interface IItemGroupPlus extends IItemGroup {
    parentGroup?: Array<string>
}
export interface IFetchItemsOptions {
    searchQuery: string,
    nbPerPage: number,
    selectedCatalogs: Array<ICatalogPlus>,
    selectedGroup: IItemGroupPlus | undefined,
    itemList?: Array<IItemPlus>,
    totalResults?: number,
    selectedFacets: ISearchClassifications,
    selectedDimensions: IDimensionFilters,
    selectedSorting: sorting,
}

export interface featureOptionResult {
    optionName: string,
    numberOfItem: number,
    actualItems: Array<IConsolidatedClientItem>,
    facets: Array<IFacet>,
    updatedFacets: Array<IFacet> | null,
    catalogsFacet: Array<IFacetValue> | null,
    companiesFacet: Array<IFacetValue> | null,
    updatedCatalogFacet: Array<IFacetValue> | null,
    updatedCompaniesFacet: Array<IFacetValue> | null,
}

export interface IInputValidationResult {
    originalInput: string,
    outputValue: number | undefined,
    errorOutput: string | undefined
}

export interface IFetchItemResults {
    totalResults: number,
    itemList: Array<IItemPlus>,
    facets: Array<IFacet>,
    updatedFacets: Array<IFacet> | null,
    dimensionFacets: Array<IFacet>,
    updatedDimensionFacets: Array<IFacet> | null,
    baseItemTypes: Array<IFacetValue>,
    updatedBaseItemTypes: Array<IFacetValue> | null,
    catalogsFacet: Array<IFacetValue> | null,
    companiesFacet: Array<IFacetValue> | null,
    updatedCatalogFacet: Array<IFacetValue> | null,
    updatedCompaniesFacet: Array<IFacetValue> | null,
    hasError: boolean,
    errorContent: string,
    deactivatedCatalogVersions: Array<ICatalogVersionErrorDetail> | undefined,
    unavailableCatalogVersions: Array<ICatalogVersionErrorDetail> | undefined;
}

export interface IFetchCatalogsResults {
    catalogs: Array<ICatalogPlus>,
    hasError: boolean,
    errorContent: string
}

export interface IFetchGroupOptions {
    selectedCatalogs: Array<ICatalogPlus>
}

export interface IFetchGroupResults {
    groupList: Array<IItemGroup>
}

export type Favorite = {
    code: string,
    groupCodes: Array<string>
}

export interface IFeaturePlus extends IConsolidatedFeature {
    //itemUUID?: string | null,
    options?: Array<IConsolidatedFeatureOption | IConsolidatedFeatureOptionGroup> | undefined,
    searchResult?: ISearchResultPlus
    //updatedFacets?: Array<IFacet> | null
}
export interface ISearchResultPlus extends IConsolidatedSearchResult {
    updatedFacets?: Array<IFacet> | null | undefined,
    updatedCatalogFacet?: Array<IFacetValue> | null | undefined,
    updatedCompanieFacet?: Array<IFacetValue> | null | undefined,
}
export interface IFeatureGroupPlus extends IConsolidatedFeatureGroup {
    itemUUID?: string | null,
    versionId?: string
}
export type optionJobQueue = {
    featureOption: IConsolidatedFeatureOption,
    feature: IFeaturePlus,
    filteredItemContextList?: Array<IConsolidatedClientItem>,
}

export type sorting = {
    name: string;
    type: 'text' | 'dimension';
    field: undefined | 'name' | 'width' | 'height' | 'depth';
    order: undefined | 'asc' | 'desc';
}

export type NullableFeature = null | IFeaturePlus | IFeatureGroupPlus;
export type NullableFacetValuesMap = Map<string, Array<string>> | undefined; // facetValues by facetCodes
export type PersistSubItems = Map<string, boolean> //persited subItems choice -open close...

export const catalogMenus: CatalogMenuItem[] = [        
    { title: strings['catalog.type.all.catalogs'], name: strings['catalog.type.all.catalogs'], path:'all', mainCategories: ['all'], visible: true, icon: <AllCatalogIcon {...CatalogFilterIconsize}/> },
    { title: strings['catalog.type.kitchen'], name: strings['catalog.type.kitchen'], path:'kitchen', mainCategories: ['cabinetry'], visible: true, icon: <CabinetsIcon {...CatalogFilterIconsize}/> },
    { title: strings['catalog.type.bathroom'], name: strings['catalog.type.bathroom'], path:'bathroom', mainCategories: ['sinks', 'faucets'], visible: true, icon: <TapIcon {...CatalogFilterIconsize}/> },
    { title: strings['catalog.type.bedroom'], name: strings['catalog.type.bedroom'], path:'bedroom', mainCategories: ['architectural items'], visible: true, icon: <ArmchairIcon {...CatalogFilterIconsize}/> },
    { title: strings['catalog.type.appliances'], name: strings['catalog.type.appliances'], path:'appliances', mainCategories: ['appliances'], visible: true, icon: <WasherIcon {...CatalogFilterIconsize}/> },   
    { title: strings['catalog.type.decorate'], name: strings['catalog.type.decorate'], path:'decorate', mainCategories: ['deco/accessories'], visible: true, icon: <DecorationIcon {...CatalogFilterIconsize}/> },
    { title: strings['catalog.type.storage'], name: strings['catalog.type.storage'], path:'storage', mainCategories: ['storage'], visible: true, icon: <StorageIcon {...CatalogFilterIconsize}/> },
    { title: strings['catalog.type.lighting'], name: strings['catalog.type.lighting'], path:'lighting', mainCategories: ['lighting'], visible: true, icon: <LightingIcon {...CatalogFilterIconsize}/> },
    { title: strings['catalog.type.office'], name: strings['catalog.type.office'], path:'office', mainCategories: ['office'], visible: true, icon: <OfficeIcon {...CatalogFilterIconsize}/> },
    { title: strings['catalog.type.outdoor'], name: strings['catalog.type.outdoor'], path:'outdoor', mainCategories: ['outdoor products'], visible: true, icon: <OutdoorIcon {...CatalogFilterIconsize}/> },
    { title: strings['catalog.type.architectural'], name: strings['catalog.type.architectural'], path:'architectural', mainCategories: ['architectural items'], visible: true, icon: <ArchitecturalIcon {...CatalogFilterIconsize}/> },
    { title: strings['catalog.type.material'], name: strings['catalog.type.material'], path:'materials', mainCategories: ['materials'], visible: true, icon: <MaterialsIcon {...CatalogFilterIconsize}/> }
];

export const catalogStatus = [
    ['all', strings['catalog.status.all']],
    ['visible', strings['catalog.status.visible']],
    ['hidden', strings['catalog.status.hidden']],
    ['lock', strings['catalog.status.lock']],
    ['pending', strings['catalog.status.pending']],
    ['denied', strings['catalog.status.denied']],
];

export const catalogType = [
    ['all', strings['catalog.type.all']],
    ['kitchen', strings['catalog.type.kitchen']],
    ['bathroom', strings['catalog.type.bathroom']],
    ['bedroom', strings['catalog.type.bedroom']],
    ['appliancees', strings['catalog.type.appliances']],
    ['decorate', strings['catalog.type.decorate']]
];

export type handlePosition = 'topLeft' | 'topCenter' | 'topRight' | 'topVertLeft' | 'topVertCenter' | 'topVertRight' |
    'topLeftDiagoLeft' | 'topLeftDiagoRight' | 'topRightDiagoLeft' | 'topRightDiagoRight' |
    'centerLeft' | 'centerCenter' | 'centerRight' | 'centerVertLeft' | 'centerVertCenter' | 'centerVertRight' |
    'centerLeftDiagoLeft' | 'centerLeftDiagoRight' | 'centerCenterDiagoLeft' | 'centerCenterDiagoRight' | 'centerRightDiagoLeft' | 'centerRightDiagoRight' |
    'bottomLeft' | 'bottomCenter' | 'bottomRight' | 'bottomVertLeft' | 'bottomVertCenter' | 'bottomVertRight' |
    'bottomLeftDiagoLeft' | 'bottomLeftDiagoRight' | 'bottomRightDiagoLeft' | 'bottomRightDiagoRight' |
    'none' | 'custom';

export const openingTypes = [
    ['Door', 'door', 'customNumeric'], 
    ['Drawer', 'drawer', 'customNumeric'], 
    ['General', 'general', 'customNumeric'], 
    ['Sliding', 'door.sliding', 'customNumeric'],
    ['Bifold', 'door.bifold', 'customNumeric'],
    ['Accordion', 'door.accordion', 'customNumeric'], 
    ['RollUp', 'door.rollUp', 'customNumeric'], 
    ['LiftUp', 'door.liftUp', 'customNumeric'], 
    ['TiltOut', 'door.tiltOut', 'customNumeric'],
    ['Toggle', undefined, 'toggle'],
    ['SingleSelect', undefined, 'singleSelect'],
    ['SingleSelect', 'general', 'singleSelect'],
    //['unknown', undefined, undefined]
];

