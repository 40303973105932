import DesignStatePartitionWall from "./DesignStatePartitionWall";
import DesignStatePerimeterWall from "./DesignStatePerimeterWall";
import DesignStateZone from "./DesignStateZone";
import JSONStringifier from "./JSONStringifier";
import { CommonUtils } from "@cic/utils";

export default class DesignStateSpaceData extends JSONStringifier<IDesignSpaceData> implements IDesignSpaceData {
    private _id: string;
    private _name?: string;
    private _functions?: Array<DesignSpaceFunction>;
    private _zones?: Array<DesignStateZone>;

    private readonly _walls: IWalls;
    private _floor: IFloor | undefined;
    private _ceiling: ICeiling | undefined;

    constructor() {
        super();
        
        this._id = "space_" + CommonUtils.genGUID();
        this._walls = {
            perimeterWalls: []
        };
    }

    get id(): string {
        return this._id;
    }

    get name(): string | undefined {
        return this._name;
    }

    get functions(): Array<DesignSpaceFunction> | undefined {
        return this._functions;
    }
    
    get floorHeight(): number | undefined {
        return this._floor?.height;
    }

    get ceilingHeight(): number | undefined {
        return this._ceiling?.height;
    }

    get walls(): IWalls | undefined {
        if (this._walls.perimeterWalls.length || this._walls.partitionWalls?.length) {
            return this._walls;
        }
    }

    get zones(): Array<IZone> | undefined {
        return this._zones;
    }

    load(spaceData?: IDesignSpaceData): void {
        this._id = spaceData?.id || CommonUtils.genGUID();

        this._name = spaceData?.name;
        this._functions = spaceData?.functions;

        if (Array.isArray(spaceData?.walls?.perimeterWalls)) {
            this._walls.perimeterWalls = [];
            spaceData!.walls!.perimeterWalls.forEach((wall: IPerimeterWall) => {
                const perimeterWall: DesignStatePerimeterWall = new DesignStatePerimeterWall();
                perimeterWall.load(wall);
                this._walls.perimeterWalls.push(perimeterWall);
            });
        }

        if (Array.isArray(spaceData?.walls?.partitionWalls)) {
            this._walls.partitionWalls = [];
            spaceData!.walls!.partitionWalls.forEach((wall: IPartitionWall) => {
                const partitionWall: DesignStatePartitionWall = new DesignStatePartitionWall();
                partitionWall.load(wall);
                this._walls.partitionWalls!.push(partitionWall);
            });
        }

        if (spaceData?.floor) {
            this._floor = Object.assign({}, spaceData?.floor);
        }
        
        if (spaceData?.ceiling) {
            this._ceiling = Object.assign({}, spaceData?.ceiling);
        }

        // TODO: Zones are not serialized within the spaceData. See DesignFormat spec.
        if (Array.isArray(spaceData?.zones)) {
            this._zones = [];
            spaceData!.zones.forEach((zone: IZone) => {
                const designZone: DesignStateZone = new DesignStateZone();
                designZone.load(zone);
                this._zones!.push(designZone);
            });            
        }
    }

    setPerimeterWalls(perimeterWalls: Array<IPerimeterWall> | undefined): void {
        if (Array.isArray(perimeterWalls) && perimeterWalls.length) {
            this._walls.perimeterWalls = perimeterWalls;
        }
        else {
            this._walls.perimeterWalls = [];
        }
    }

    setPartitionWalls(partitionWalls: Array<IPartitionWall> | undefined): void {
        if (Array.isArray(partitionWalls) && partitionWalls.length) {
            this._walls.partitionWalls = partitionWalls;
        }
        else {
            this._walls.partitionWalls = [];
        }
    }
}