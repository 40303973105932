import { IEntitySchema } from "../../SchemaEntityUtils";
import {
    SchemaPath,
    ValidationExtraData,
    ValidationResponse,
    ValidationRule,
} from "../SchemaValidationTypes";

export class TypeValidationRule extends ValidationRule {
    private isFormulaRegex = /^#.*#$/;

    async exec(
        value: unknown,
        schema: IEntitySchema,
        path?: SchemaPath,
        extraData?: ValidationExtraData | undefined,
    ): Promise<ValidationResponse> {
        const response: ValidationResponse = {
            success: true,
        };

        if (this.isFormulaRegex.test(value as string)) {
            console.warn(
                "TypeValidationRule: formula validation not implemented",
            );
            return response;
        }

        switch (schema.type) {
            case "number":
            case "decimal":
                if (isNaN(value as number)) {
                    response.success = false;
                    response.error = new Error("Enter a valid number");
                }
                break;
            case "integer":
                if (
                    isNaN(value as number) ||
                    !Number.isInteger(Number(value))
                ) {
                    response.success = false;
                    response.error = new Error("Enter a valid integer");
                }
                break;
            case "string":
                if (typeof value === "string") {
                    if (schema.maxLength && value.length > schema.maxLength) {
                        response.success = false;
                        response.error = new Error("This value is too large");
                    }
                }
                break;
            case "object":
                break;
            case "array":
                if (value) {
                    if (
                        schema.items?.type === "string" &&
                        !this.isUnique(value as string, extraData!)
                    ) {
                        response.success = false;
                        response.error = new Error("This value already exist");
                    } else if (schema.items?.type === "number") {
                        if (isNaN(value as number)) {
                            response.success = false;
                            response.error = new Error("Enter a valid number");
                        } else if (
                            schema.items?.minimum !== undefined &&
                            (value as number) < schema.items.minimum
                        ) {
                            response.success = false;
                            response.error = new Error("Value is too small");
                        } else if (
                            schema.items?.maximum !== undefined &&
                            (value as number) > schema.items.maximum
                        ) {
                            response.success = false;
                            response.error = new Error("Value is too large");
                        }
                    }
                }
                break;
            default:
                console.log(
                    "TypeValidationRule: type validation not implemented",
                    schema,
                    value,
                );
        }
        return response;
    }

    isUnique(value: string, extraData: ValidationExtraData) {
        const { refList } = extraData;

        // If the value is a number, we allow it to be duplicated
        if (!isNaN(Number(value))) {
            return true;
        }

        return !refList?.find(
            (ref) =>
                ref.toString().toLowerCase() === value.toString().toLowerCase(),
        );
    }
}
