import { ItemReportTax, IConsolidatedItemReportTax } from "./ItemReportTax";
import { PricingReportItem, IConsolidatedPricingReportItem } from "./PricingReportItem";
import { PricingReportMessage, IConsolidatedPricingReportMessage } from "./PricingReportMessage";

export class ItemPricingReportFactory {
    static create(itemPricingReport: IItemPricingReport): IConsolidatedItemPricingReport {
        return new ItemPricingReport(itemPricingReport).serialize();
    }
}

export class ItemPricingReport {
    protected _reportItems?: Array<PricingReportItem>;
    protected _totalPrice?: number;
    protected _currencyCode?: string;
    protected _currencyConvention: CurrencyConvention;
    protected _taxes?: Array<ItemReportTax>;
    protected _integrationData?: Array<Record<string, unknown>>;
    protected _warnings: Array<PricingReportMessage>;
    protected _notes: Array<PricingReportMessage>;
    protected _errors?: Array<PricingReportMessage>;

    constructor(itemPricingReport: IItemPricingReport) {
        this._reportItems = itemPricingReport.reportItems?.map((reportItem: IPricingReportItem) => new PricingReportItem(reportItem));
        this._totalPrice = itemPricingReport.totalPrice;
        this._currencyCode = itemPricingReport.currencyCode;
        this._currencyConvention = itemPricingReport.currencyConvention;
        this._taxes = itemPricingReport.taxes?.map((tax: IItemReportTax) => new ItemReportTax(tax));
        this._integrationData = itemPricingReport.integrationData;
        this._warnings = itemPricingReport.warnings.map((warning: IPricingReportMessage) => new PricingReportMessage(warning));
        this._notes = itemPricingReport.notes.map((note: IPricingReportMessage) => new PricingReportMessage(note));
        this._errors = itemPricingReport.errors?.map((error: IPricingReportMessage) => new PricingReportMessage(error));
    }

    serialize(): IConsolidatedItemPricingReport {
        const itemPricingReport: IConsolidatedItemPricingReport = {
            currencyConvention: this._currencyConvention,
            warnings: this._warnings.map((warning: PricingReportMessage) => warning.serialize()),
            notes: this._notes.map((note: PricingReportMessage) => note.serialize())
        };

        if (this._reportItems !== undefined) {
            itemPricingReport.reportItems = this._reportItems.map((reportItem: PricingReportItem) => reportItem.serialize());
        }

        if (this._totalPrice !== undefined) {
            itemPricingReport.totalPrice = this._totalPrice;
        }

        if (this._currencyCode !== undefined) {
            itemPricingReport.currencyCode = this._currencyCode;
        }

        if (this._taxes !== undefined) {
            itemPricingReport.taxes = this._taxes.map((tax: ItemReportTax) => tax.serialize());
        }

        if (this._integrationData !== undefined) {
            itemPricingReport.integrationData = this._integrationData;
        }

        if (this._errors !== undefined) {
            itemPricingReport.errors = this._errors.map((error: PricingReportMessage) => error.serialize());
        }

        return itemPricingReport;
    }
}

export interface IConsolidatedItemPricingReport {
    reportItems?: Array<IConsolidatedPricingReportItem>;
    totalPrice?: number;
    currencyCode?: string;
    currencyConvention: CurrencyConvention;
    taxes?: Array<IConsolidatedItemReportTax>;
    integrationData?: Array<Record<string, unknown>>;
    warnings: Array<IConsolidatedPricingReportMessage>;
    notes: Array<IConsolidatedPricingReportMessage>;
    errors?: Array<IConsolidatedPricingReportMessage>;
}