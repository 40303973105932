import { ImageRef, IConsolidatedImageRef } from "./ImageRef";

export abstract class GroupBase {
    protected _id: string;
    protected _name: string;
    protected _image?: ImageRef;
    protected _sequenceOrder?: number;

    protected constructor(baseGroup: IGroup) {
        this._id = baseGroup.id;
        this._name = baseGroup.name;
        this._image = baseGroup.image ? new ImageRef(baseGroup.image) : undefined;
        this._sequenceOrder = baseGroup.sequenceOrder;
    }

    get id(): string {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    get image(): ImageRef | undefined {
        return this._image;
    }

    get sequenceOrder(): number | undefined {
        return this._sequenceOrder;
    }

    serialize(): IConsolidatedGroupBase {
        const group: IConsolidatedGroupBase = {
            id: this._id,
            name: this._name
        };

        if (this._image !== undefined) {
            group.image = this._image.serialize();
        }

        if (this._sequenceOrder !== undefined) {
            group.sequenceOrder = this._sequenceOrder;
        }

        return group;
    }
}

export interface IConsolidatedGroupBase {
    id: string;
    name: string;
    image?: IConsolidatedImageRef;
    sequenceOrder?: number;
}