import React from "react";
import { Control } from "../../components/control/control.component";
import { ControlProps } from "../../components/control/control.type";
import './input-field.scss'

type InputFieldProps = ControlProps & React.InputHTMLAttributes<HTMLInputElement>

export const InputField = React.forwardRef<HTMLDivElement, InputFieldProps>(

    (props: InputFieldProps, controlRef) => {

        const rest = {...props};

        delete rest.label;
        delete rest.required;
        delete rest.icon;
        delete rest.onClickIcon;
        delete rest.iconAction;
        delete rest.onClickAction;
        delete rest.errorMessage;
        delete rest.className;
        delete rest.style;
        delete rest.value;
        delete rest.disabledAction;
        delete rest.tooltipIcon;
        delete rest.tooltipIconAction;

        return <Control
            ref={controlRef}
            label={props.label}
            required={props.required}
            disabled={props.disabled}
            icon={props.icon}
            onClickIcon={props.onClickIcon}
            iconAction={props.iconAction}
            onClickAction={props.onClickAction}
            errorMessage={props.errorMessage}
            className={props.className}
            disabledAction={props.disabledAction}
            tooltipIcon={props.tooltipIcon}
            tooltipIconAction={props.tooltipIconAction}
        >
            <input className="cy-input-field" type={props.type ?? "text"} value={props.value ?? ""} {...rest} />
        </Control>
    }

)