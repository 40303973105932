import React, { createContext, PropsWithChildren, useEffect, useRef, useState } from 'react';

export const ContextualHelpContext = createContext<any>(null);

export function ContextualHelpProvider({ children }: PropsWithChildren<{}>) {
    const [showCtxlHelp, setShowCtxlHelp] = useState<boolean>(false);
    const [ctxlHelpStepIndex, setCtxlHelpStepIndex] = useState<number | undefined>(undefined);
    const [ctxlHelpIndex, setCtxlHelpIndex] = useState<number | undefined>(undefined);
    const [targetElementRef, setTargetElementRef] = useState<React.MutableRefObject<HTMLButtonElement | HTMLDivElement | null>>();
    const hoverTimerRef = useRef<NodeJS.Timeout | null>(null);
    const leaveTimerRef = useRef<NodeJS.Timeout | null>(null);

    const showContextualHelp = (index: number, newTargetElementRef: React.MutableRefObject<HTMLButtonElement | HTMLDivElement | null>, ctxlHelpIndex?: number) => {
        if (hoverTimerRef.current) {
            clearTimeout(hoverTimerRef.current);
        }

        hoverTimerRef.current = setTimeout(() => {
            if (leaveTimerRef.current) {
                clearTimeout(leaveTimerRef.current);
            }

            setShowCtxlHelp(true);
            setCtxlHelpStepIndex(index);
            setCtxlHelpIndex(ctxlHelpIndex ?? undefined);
            setTargetElementRef({ ...newTargetElementRef });
        }, 1500);

        if (newTargetElementRef?.current) {
            const currentElement = newTargetElementRef.current,
                handleMouseLeave = () => {
                    if (hoverTimerRef.current) {
                        clearTimeout(hoverTimerRef.current);
                    }
                    currentElement.removeEventListener("mouseleave", handleMouseLeave);
                    currentElement.removeEventListener("click", handleMouseLeave);
                };

            currentElement.addEventListener("mouseleave", handleMouseLeave);
            currentElement.addEventListener("click", handleMouseLeave);
        }
    };

    const hideContextualHelp = () => {
        setShowCtxlHelp(false);
        setCtxlHelpStepIndex(undefined);
    };

    useEffect(() => {
        if (targetElementRef?.current) {
            const currentElement = targetElementRef.current,
                handleMouseLeave = () => {
                    leaveTimerRef.current = setTimeout(() => {
                        setShowCtxlHelp(false);
                        setCtxlHelpStepIndex(undefined);
                        setCtxlHelpIndex(undefined);
                        setTargetElementRef(undefined);
                    }, 2500);
                    currentElement.removeEventListener("mouseleave", handleMouseLeave);
                };

            currentElement.addEventListener("mouseleave", handleMouseLeave);
        }
    }, [targetElementRef]);

    return (
        <ContextualHelpContext.Provider
            value={{
                showCtxlHelp,
                ctxlHelpStepIndex,
                ctxlHelpIndex,
                showContextualHelp,
                hideContextualHelp
            }}
        >
            {children}
        </ContextualHelpContext.Provider>
    );
}