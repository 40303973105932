import React, { MutableRefObject } from 'react';
import { createPortal } from "react-dom";
import './thumbnailViewer.scss';

type ThumbnailViewerProps = {
    nodeRef?: MutableRefObject<HTMLDivElement | null>,
    uri: string,    
    el: HTMLDivElement;
    onClose?(): void
}

export function ThumbnailViewer(props: ThumbnailViewerProps) {
    const parentPos = document?.getElementById('item-configurator')?.getBoundingClientRect();
    const childPos = props.el.getBoundingClientRect();
    
    let leftPosition: React.CSSProperties = {left: childPos.left - (parentPos?.left || 0) - 54 };
    let topPosition: React.CSSProperties = {top: childPos.top - (parentPos?.top || 0) + 32 };  
    
    if (topPosition.top as number + 88 > (parentPos?.height || 0)) { 
        //thumbnail is going to be out of the screen. show it on top of the element
        topPosition.top = childPos.top - (parentPos?.top || 0) - 100;  
    }
        
    return createPortal(                    
        <div className={`thumbnail-viewer__container  `} style={{...topPosition, ...leftPosition}} ref={props.nodeRef}>
                <div className={`thumbnail-viewer__thumbnail`}>
                    <img src={props.uri} width='88' height='88'/>
                </div>
        </div>, document.getElementById("modal-configurator-container") || document.body
    );
};